.connect_list{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
}
.connect_items_list{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 20px 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: 1px solid #0000000D;
}
.connect_list > h5{
    font-family: "Poppins-Regular";
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
}
.post-item-bottom a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 10px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: blue;
    cursor: pointer;
}
.post-item-bottom a > svg{
    margin-right: 5px;
}
.connect_items_list > h5{
    font-family: "Poppins-Medium";
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
}
.connected-platform-item{
    display: flex;
    align-items: center;
}
.connect_items_list > svg{
    margin-right: 8px;
    font-size: 22px;
}
.connect_list > svg{
    margin-right: 8px;
    font-size: 18px;
}
.twitter-svg{
    color: #1DA1F2;
}
.facebook-svg{
    color: #4267B2;
}
.instagram-svg{
    color: #D5387F;
}
.connect_list:last-child{
    margin-bottom: 0;
}
.connect_list_wrapper{
    background: #ffff;
    padding: 10px 0;
    box-shadow: 0px 0px 0px 1px #0000000D;
    border-radius: 9px;
}
.connect_list:hover{
    background: #d3d3d340;
    border-radius: 4px;
}
.connected_items_wrapper{
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 16px;
    flex-wrap: wrap;
}
.connected_items_wrapper > div{
    flex :0 0 calc(33.3333% - 16px);
}
.custom-checkbox-blue{
    display: flex;
    align-items: center;
}
.check-all-wrapper > svg{
    cursor: pointer;
}
.check-all-wrapper > svg:hover{
    color: #fc4848;
}
.custom-checkbox-blue > input[type="checkbox"]{
    display: none;
}
.custom-checkbox-blue{
    cursor: pointer;
}
.custom-checkbox-blue > span{
    font-family: "Poppins-Regular";
    font-size: 14px;
}
.check-all-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 8px 20px;
    border-bottom: 1px solid #0000000D;
    margin-bottom: 10px;
}
.custom-checkbox-blue > span.checkbox{
    width: 12px;
    height: 12px;
    border: 1px solid #b0b7c3;
    display: block;
    border-radius: 3px;
    margin-right: 10px;
    flex-shrink: 0;
    align-self: center;
}
.post-list-wrapper{
    max-height: 650px;
}
.custom-checkbox-blue > input:checked + span.checkbox{
    background: #00B4E5;
    border-color: #00B4E5;
    background-image: url("../../public/admin-icons/checkmark.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.custom-checkbox-blue > input:disabled + span.checkbox{
    background: #b0b7c3;
}
.connected-platform-item-name{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins-Medium';
    background-color: rgba(255, 0, 0, 0.115);
    color: rgb(61, 1, 1);
    margin-right: 10px;
}
.connected-platform-item-title > p{
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    color: #232323;
    line-height: 11px;
    white-space: nowrap;
}
.connected-platform-item-title > span{
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 10px;
    margin-top: 0;
    color: red;
}
.my-custom-th{
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 500 !important;
    color: #232323
}  
.my-custom-day-cell{
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-weight: 400 !important;
    color: #232323;
    cursor: pointer;
    padding: 0 0 10px 0 !important;
}
.my-custom-day-cell  .fc-daygrid-day-top{
    flex-direction: row!important;
}
.post-list-loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ced4da5e;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    min-height: 100px;
    border-radius: 4px;
}
.post-list-loader span{
    width: 30px;
    height: 30px;
    color: #808080;
}
.my-custom-event{
    background-color: #00B4E5;
    border:  #00B4E5;
    margin-left: 5px!important;
    margin-right:  5px!important;
    padding: 0 5px;
}
.calendar-custom-style button{
    font-family: "Poppins-Regular"!important;
    font-size: 14px!important;
    font-weight: 400 !important;
}
.calendar-custom-style .fc-next-button , .calendar-custom-style .fc-prev-button{
    background-color: #00B4E5!important;
    border-color: #00B4E5!important;
}
.calendar-custom-style .fc-next-button:focus, .calendar-custom-style .fc-prev-button:focus{
    outline: 0!important;
    box-shadow: none!important;
}
.calendar-custom-style .fc-toolbar-chunk{
    display: flex;
    align-items: center;
}
.calendar-custom-style .fc-toolbar-chunk .fc-toolbar-title{
    font-family: "Poppins-Regular"!important;
    font-size: 14px!important;
}
.calendar-custom-style .fc .fc-button-primary:disabled{
    display: none;
}
.schedules-tabs-wrapper > div{
    display: flex;
    align-items: center;
}
.schedules-tabs-wrapper > div > img{
    width: 20px;
    margin-right: 5px;
}
.schedules-tabs-wrapper > div > h6{
    font-size: 16px;
    font-family: "Poppins-Regular";
    margin-top: 0;
    margin-bottom: 0px;
    color: #0c2640;
}
.schedules-tabs-wrapper > div > svg{
    font-size: 20px;
    margin-right: 5px;
    color: #0c2640;
}
.schedules-tabs-wrapper {
    margin-top: 20px;
}
.schedules-tabs-wrapper > div{
    padding: 15px 12px;
    cursor: pointer;
}
.schedules-tabs-wrapper > div.active{
    background: rgba(24, 174, 227, 0.08);
    color: #00B4E5;
}
.schedules-tabs-wrapper > div:hover{
    background-color: #f3f3f3;
    border-radius: 5px;
}
.schedule-item-filter, .schedule-item-filter > div{
    display: flex;
    align-items: center;
}
.schedule-item-filter{
    justify-content: space-between;
}
.schedule-item-filter{
    cursor: pointer;
    margin-bottom: 10px;
}
.schedule-item-filter h6{
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin-top: 0;
    margin-bottom: 0px;
    color: #0c2640;
}
.schedule-item-filter svg{
    margin-right: 5px;
    border: 1px solid #f4f4f4;
    font-size: 22px;
    padding: 8px;
}
.schedule-item-filter input[type="radio"]{
    display: none;
}
.schedule-item-filter span{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid #c7c3c3;
    display: inline-block;
}
.schedule-item-filter input[type="radio"]:checked + span{
    background-color: #00B4E5;
    border: 1px solid #00B4E5;
    background-image: url("../../public/admin-icons/checkmark.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.schedule-filter-wrapper{
    margin-top: 40px;
}
.schedule-filter-wrapper h2{
    color: #0c2640;
    font-size: 16px;
    font-family: "Poppins-Medium";
    display: flex;
    align-items: center;
}
.schedule-filter-wrapper h2 svg{
    margin-right: 5px;
}
.schedule-delete-wrapper {
    justify-content: flex-start;
}
.schedule-delete-wrapper span{
    margin-right: 10px;
}
.schedule-delete-container{
    background: #80808008;
    padding: 10px;
    border-radius: 4px;
}
.schedule-delete-container > button{
    width: 100%;
    margin-top: 15px;
    color: #fff;
    background-color: #fc4848;
    border: 0px;
    border-radius: 5px;
    height: 45px;
    font-family: "Poppins-Regular";
    font-size: 16px;
    cursor: pointer;
}
.schedule-delete-container > button:hover{
    background-color: #f72020;
}
.social-stats-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.social-stats-wrapper > .successed{
    background-color: rgba(10, 187, 135, .1);
}
.social-stats-wrapper > .failed{
    background-color: rgba(255,184,34,.1);
}
.social-stats-wrapper > .total{
    background-color: rgba(85,120,235,.1);
}
.social-stats-wrapper > .total > div{
    right: 10px;
}
.social-stats-wrapper > div{
    flex: 1;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
}
.social-stats-wrapper > div > span{
    font-family: "Poppins-Medium";
    font-size: 20px;
}
.social-stats-wrapper > div > h6{
    font-family: "Poppins-Medium";
    font-size: 16px;
    margin: 5px 0;
    color: #00000094;
}
.social-stats-wrapper > div{
    position: relative;
}
.social-stats-wrapper > div > div{
    position: absolute;
    opacity: 0.1;
    z-index: 0;
    right: 5px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.social-stats-wrapper > .successed > div > svg{
    color: rgb(10, 187, 135);
}
.social-stats-wrapper > .failed > div > svg{
    color: rgb(255, 184, 34);
}
.social-stats-wrapper > div > div > svg{
    font-size: 65px;
}
.social-box-container > h2{
    font-family: 'Poppins-Light';
    font-size: 18px;
    padding: 0 14px;
    margin: 0;
    display: flex;
    align-items: center;
}
.social-box-container > h2 > svg{
    margin-right: 10px;
}
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate-circle {
    display: inline-block;
    animation: rotate 2s linear infinite;
  }
.post-list-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.post-list-header h4{
    font-size: 16px;
    font-family: "Poppins-Regular";
    margin-top: 0;
    margin-bottom: 0px;
    color: #0c2640;
    display: flex;
    align-items: center;
}
.post-list-header h4 > svg{
    margin-right: 5px;
}
.post-list-header > a > svg{
    font-size: 20px;
    color: #00B4E5;
}
.social-box-container .fc-toolbar-chunk button[title="This month"]{
    background: #f3f3f3;
    color: #0c2640;
    border: 0px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.social-box-container .fc-toolbar-chunk button[title="This month"]:focus{
    box-shadow: unset;
}
.social-box-container .fc-toolbar-chunk button[title="This month"]:active{
    background: #f3f3f3;
    color: #0c2640;
    box-shadow: unset!important;
    border: 0px;
}
.social-box-container .fc-toolbar-chunk button[title="This month"]::before{
    content: url("../../public/admin-icons/replay-svg.svg");
    line-height: 1px;
    transform: rotate(45deg);
}
.post-list-header a{
    background: rgba(24, 174, 227, 0.08);
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding: 5px;
    text-decoration: none;
    color: #00B4E5;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}
.post-list-search-wrapper > input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid;
    border-color: rgba(37, 49, 60, 0.15);
    padding: 0 13px;
    font-size: 14px;
    font-family: "Poppins-Regular";
    color: #0c2640;
}
.post-list-search-wrapper {
    margin-bottom: 12px;
    margin-top: 12px;
}
.post-item-content{
    display: flex;
    align-items: stretch;
}
.post-item-content > div{
    position: relative;
}
.post-item-content > div:first-child{
    padding: 27px;
    background: rgba(24, 174, 227, 0.08);
    margin-right: 10px;
    border-radius: 3px;
}
.post-item-content > div:first-child svg:last-child{
    font-size: 60px;
    color: #00B4E5
}
.post-item-content > div h6{
    font-size: 16px;
    font-family: "Poppins-Medium";
    color: #0c2640;
    margin-top: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
.post-item-content > div h5{
    font-size: 14px;
    font-family: "Poppins-Regular";
    color: #0c2640;
    margin-top: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
.post-item-content > div h6 > svg, .post-item-content > div h5 > svg{
    margin-right: 3px;
}
.post-item-content > div:last-child{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.post-item-bottom{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.post-item-bottom > p{
    font-size: 12px;
    font-family: "Poppins-Regular";
    margin: 0;
    padding: 3px 5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}
.post-item-bottom > p.success{
    color: #0abb87;    
    background-color: rgba(10, 187, 135, .1);
}
.post-item-bottom > p > svg, .post-item-bottom > button > svg{
    margin-right: 5px;
}
.post-item-bottom > button{
    background-color: transparent;
    font-size: 12px;
    font-family: "Poppins-Regular";
    color: #0c2640;
    display: flex;
    align-items: center;
    border: 0px;
    margin-left: 6px;
    cursor: pointer;
}
.social-box-container{
    background-color: #fff;
    border-radius: 9px;
    position: relative;
    height: 100%;
    box-shadow: 0px 0px 0px 1px #0000000D;
    text-transform: capitalize;
    box-sizing: border-box;
}
.post-item-wrapper{
    border-bottom: 1px solid #0000001a;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 3px;
}
.platform-icon-post{
    font-size: 20px;
    position: absolute;
    top: 0;
    color: #4267B2;
    left: 0;
}
.pages-item-wrapper{
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}
.pages-item-wrapper > .initials{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Poppins-Bold";
    background-color: #28a74559;
    color: #28a745;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 10px;
}
.pages-item-wrapper > img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}
.pages-item-detail > h4{
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
    color: #0c2640;
}
.pages-item-detail > span{
    font-size: 12px;
    font-family: "Poppins-Light";
    margin-top: 0px;
    margin-bottom: 5px;
    color: #0c2640;
}
.publish-tabs-header{
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
}
.publish-tabs-header > div{
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #0c2640;
    display: flex;
    align-items: center;
    flex: 1;
    text-align: center;
    justify-content: center;
    border: 1px solid rgba(37, 49, 60, 0.15);
    background-color: rgba(24, 174, 227, 0.08);
    padding: 15px;
    cursor: pointer;
    text-wrap: nowrap;
}
.publish-tabs-header > div.active{
    color: #00B4E5;
    cursor: initial;
}
.publish-tabs-header > div:not(.active):hover{
    background-color: rgb(24 174 227 / 19%);
}
.publish-tabs-header > div:nth-child(2){
    border-right: 0px;
    border-left: 0px;
}
.publish-tabs-header > div:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.publish-tabs-header > div:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.publish-tabs-header > div > svg{
    margin-right: 5px;
}
.text-wrapper{
    position: relative;
    width: 100%;
    position: relative;
    margin-top: 20px;
}
.text-wrapper  textarea{
    resize: none;
    width: 100%;
    height: 100px;
    font-size: 14px;
    font-family: "Poppins-Light";
    border: 1px solid rgba(37, 49, 60, 0.15);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}
.text-wrapper > input{
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-family: "Poppins-Light";
    border: 1px solid rgba(37, 49, 60, 0.15);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}
.text-wrapper  textarea:focus{
    outline: none;
    border: 1px solid #00B4E5;
}
.text-wrapper p , .upload-images-wrapper p{
    font-size: 16px;
    font-family: 'Poppins-Regular';
    margin: 15px 0 8px 0;
}
.publish-tabs-container h5{
    font-size: 16px;
    font-family: 'Poppins-Regular';
    margin-bottom: 15px;
    margin-top: 0;
}
.publish-tabs-footer > div {
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    justify-content: flex-end;
    align-items: center;
}
.publish-tabs-footer > div > h6{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    margin-right: 24px;
    margin-bottom: 0;
    margin-top: 0;
}
.schedule-container{
    padding: 15px 20px;
    background: #b0afaf1a;
    border-radius: 4px;
}
.schedule-time-wrapper p{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 0;
    text-wrap: nowrap;
}
.date-form-wrapper{
    padding: 5px 10px;
    background: #ffff;
    border-radius: 4px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    cursor: pointer;
}
.schedule-time-wrapper input[type="number"]{
    height: 31px;
    border: 0px;
    border-radius: 4px;
    width: 100%;
    padding: 7px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
}
.schedule-time-wrapper > div:first-child{
    margin-bottom: 20px;
}
.custom-timer{
    background: #efefef;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.custom-timer h5{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    margin-bottom: 0px;
}
.custom-timer > div > div:first-child{
    margin-right: 5px;
}
.custom-timer > div > select{
    padding: 5px 10px;
    border: 0px;
    font-family: 'Poppins-Regular';
    cursor: pointer;
    border-radius: 4px;
}
.custom-timer > div > select:focus{
    outline: 0;
}
.custom-timer > div > select:first-child{
    margin-right: 5px;
}
.preview-tabs-header{
    display: flex;
    overflow: auto;
}
.preview-tabs-header  > div{
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.preview-tabs-header  > div:hover{
    background-color:#f5f8fa;
}
.preview-tabs-header  svg{
    font-size: 20px;
    cursor: pointer;
}

.preview-tabs-header::-webkit-scrollbar {
    height: 4px; 
  }
  
.preview-tabs-header::-webkit-scrollbar-thumb {
    background-color: #cacaca; 
    border-radius: 10px; 
  }
  
.preview-tabs-header::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
    border-radius: 10px;
}
.preview-tabs-body{
    width: 80%;
    margin: auto;
    /* max-height: 610px;
    overflow: scroll;
    scrollbar-width: none; */
}
.publish-preview-padding{
    padding: 20px 10px;
}
.preview-header-wrp{
    border: 1px solid #8080801f;
    padding: 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 0;
}
.bottom-post-actions{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    position: relative;
}
.bottom-post-actions.facebook, .bottom-post-instagram{
    border: 1px solid #8080801f;
    border-top: 0;
    justify-content: space-between;
    top: -5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.bottom-post-actions > div{
    display: flex;
    align-items: center;
    color: #181c32a6;
    font-family: 'Poppins-Medium';
    font-size: 13px;
    font-weight: 500;
}
.bottom-post-actions.facebook > div{
    font-size: 13px;
}
.bottom-post-actions.x > div{
    font-size: 15px;
    margin-right: 20px;
}
.bottom-post-actions > div > span{
    line-height: 1px;
}
.bottom-post-actions > div > svg{
    margin-right: 5px;
}
.rev-select-wrapper > label{
    font-size: 16px;
    font-family: 'Poppins-Regular';
    margin: 15px 0 8px 0;
    display: block;
}
.star-item-wrapper > input{
    display: none;
}
.star-item-wrapper{
    display: flex;
    align-items: center;
    font-family: "Poppins-Regular";
    margin-right: 20px;
}
.stars-wrapper{
    display: flex;
    align-items: center;
}
.star-item-wrapper  > svg{
    color: #FFBE1D;
}
.star-item-wrapper > span{
    margin-right: 5px;
    color: #808080;
}
.flex-1{
    flex: 1;
}
.star-item-wrapper > input:checked + label{
    background-color: #00B4E5;
    background-image: url("../../public/admin-icons/checkmark.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.star-item-wrapper > label{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;
    background-color: #f3f3f3;
    margin-right: 5px;
    cursor: pointer;
}
.rev-select-wrapper input[type="time"]{
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-family: "Poppins-Regular";
    font-size: 0.8em;
    padding: 7px 26px 7px 12px;
    color: #25313C;
}
.rev-select-wrapper input[type="time"]::-webkit-calendar-picker-indicator {
    background: url('../../public/admin-icons/clock-ai.svg') no-repeat;
    background-size: 20px;
    background-position-y: center;
    position: relative;
    right: -20px;
    color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.preview-review {
    background-image: url("../../public/admin-images/background_review.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}
.preview-review > div{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}
.preview-logo-wrapper{
    text-align: center;
    width: 100%;
    border-bottom: 3px solid gray;
    padding: 20px 0;
}
.preview-logo-wrapper > div{
    height: 150px;
}
.preview-logo-wrapper > div > img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.prev-date{
    font-family: 'Poppins-Medium';
    margin-top: 7px;
    display: block;
    margin-left: auto;
    text-align: right;
    font-size: 14px;
    color: #212529b8;
}
.bottom-div-preview{
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    font-family: "Poppins-Regular";
    justify-content: center;
    display: flex;
    align-items: center;
}
.ireview-emoji-picker .epr-emoji-native{
    font-size: 20px!important;
    width: 20px!important;
    height: 20px!important;
}
@media(min-width: 1200px) and (max-width: 1535px){
    .publish-preview-padding{
        padding: 10px;
    }
    .preview-tabs-body{
        width: 100%;
    }
}