.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.m-auto{
    margin: auto;
}
.d-block{
    display: block;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between;
}

.card__address-content {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.card__address-content>img {
    margin-right: 16px;
    max-width: 90px;
    max-height: 90px;
    object-fit: contain;
}

.flex-wrap {
    flex-wrap: wrap;
}

.position-relative {
    position: relative;
}

.form-group {
    margin-bottom: 10px;
}

.mb-0 {
    margin-bottom: 0;
}

.h-100 {
    height: 100%;
}

.gap-10 {
    gap: 10px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.text-danger {
    color: #dc3545 !important;
}

.cursor-pointer {
    cursor: pointer;
}


.w-100 {
    width: 100%;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.btn {
    border: 1px solid transparent;
    display: inline-block;
}

.accordion {
    margin-bottom: 10px;
}
.collapse_card_review{
    border-top: 1px solid #F4F4F4;
}
/* .accordion .collapse_card_review {
    display: block;
    position: relative;
    overflow: hidden;
    animation: collapseAnimationOpen .35s normal forwards;
}

.accordion.close .collapse_card_review {
    animation: collapseAnimation .35s normal forwards;
} */

@keyframes collapseAnimation {
    0% {
        height: 250px;
    }

    100% {
        height: 0;
    }
}

@keyframes collapseAnimationOpen {
    0% {
        height: 0;
    }

    100% {
        height: auto;
    }
}

/* .accordion .collapse_card_review {
    height: auto;
    visibility: visible;

} */

/* .accordion.close .collapse_card_review {
    height: 0;
    visibility: hidden;

} */

.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Poppins-Regular';
    color: #4B4B4B;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #dddddd;
    border-radius: 50%;
    margin: auto;
}

.custom-checkbox input:checked~.checkmark.show {
    background-color: #00b4e5;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark.show::after {
    display: block;
}

.custom-checkbox .checkmark.show::after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../public/admin-images/tools/tick.svg");
    background-size: cover;
    height: 8px;
    width: 9px;
}


.review-page_header {
    margin-bottom: 20px;
}

.review-page-wrapper .review-page_header .card {
    background: #FFFFFF;
    box-shadow: 0px 3.22899px 8.07247px rgb(38 51 77 / 3%);
    border-radius: 8.07247px;
    padding: 0px 15px 0px 15px;
    position: relative;
    cursor: pointer;
}

.review-page-wrapper .review-page_header .card::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 48px;
    width: 4px;
    display: block;
    border-radius: 8.07247px;
    transform: translateY(-50%);
}

.review-page-wrapper .review-page_header .card.blue::before {
    background: #3281C4;
}

.review-page-wrapper .review-page_header .card.light-blue::before {
    background: #00B2E3;
}

.review-page-wrapper .review-page_header .card.light-green::before {
    background: #14CC74;
}

.review-page-wrapper .review-page_header .card.light-yellow::before {
    background: #FDBC1F;
}

.contact_us_btn_section {
    margin-top: 30px;
    padding: 5px 8px;
    background: #80808017;
    border-radius: 5px;
}

.contact_us_btn_section>h6 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.875rem;
    color: #4D5E80;
}

.review-page-wrapper .review-page_header .card .title {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 1.1em;
    line-height: 2rem;
    color: #3281C4;
    margin-left: 0.225rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

.review-page-wrapper .review-page_header .card .arrow-wrap {
    margin-top: 3px;
    cursor: pointer;
    animation: all 0.3s ease;
}

.review-page-wrapper .content-card-header .arrow {
    animation: all 0.3s ease;
}

/* .review-page-wrapper .review-page_header .card .arrow-wrap img,
.review-page-wrapper .content-card-header .arrow {
    transform: rotate(180deg);
} */

.review-page-wrapper .review-page_header .card .arrow-wrap img.rotate,
.review-page-wrapper .content-card-header .arrow.rotate {
    transform: rotate(180deg);
}

.review-page-wrapper .review-page_header .card .title.review_button {
    color: #00B2E3;
}

.review-page-wrapper .review-page_header .card .title.coupon {
    color: #14CC74;
}

.review-page-wrapper .review-page_header .card .title.information {
    color: #FDBC1F;
}

/* Content */

.review-page-wrapper .review-page-content .card.review-details-card,
.review-page-wrapper .review-page-content .card.review-button-card,
.review-page-wrapper .review-page-content .card.coupon-card,
.review-page-wrapper .review-page-content .card.information-card {
   border-radius: 9px;
}

.review-page-wrapper .review-page-content .card {
    padding: 0;
    background: rgba(0, 178, 227, 0.01);
    border-radius: 0.625rem;
    border-radius: 1px;
}

.review-page-wrapper .content-card-header,
.review-page-wrapper .content-card-review {
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;
}

.review-page-wrapper .content-card-header,
.review-page-wrapper .content-card-review {
    padding-top: 9px;
    padding-bottom: 9px;
}

.review-page-wrapper .content-card-review {
    padding-bottom: 18px;
}

.review-page-wrapper .review-page-content .card .content-card-header .title {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 1.875rem;
    color: #4D5E80;
    margin: 0;
    font-weight: 600;
}

.review-page-wrapper .review_page_label {
    font-family: 'Poppins-Light';
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 1.5rem;
    margin-bottom: 6px;
    color: #979797;
}

.review-page-wrapper .review-input {
    display: block;
    width: 100%;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 1.5rem;
    /* padding: 0 10px; */
    color: #4B4B4B;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    height: 32px;
    padding: 10px;
}

.review-page-wrapper .review-input:focus {
    outline: none;
    box-shadow: 0 0 2px rgb(0 179 227), 0 0px 2px rgb(0 178 226) !important;
}

.review-page-wrapper .review-page-content .card.review-button-card .add_review-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 14px;
    background: #18AEE3;
    border-radius: 3px;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
}

.review-page-wrapper .review-page-content .card.review-button-card .add_review-btn img {
    margin-right: 11px;
}

.review-page-wrapper .review-page-content .card.review-button-card .add_review-btn:focus {
    box-shadow: none;
}

.review-page-wrapper .points-icon-wrap {
    cursor: pointer;
    margin-left: -10px;
}

/* .review-page-wrapper .buttons-review-card {
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
} */
.review-page-wrapper .buttons-review-cards {
    width: 100%;
}

.review-page-wrapper .input-icon-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    height: 32px;
}

.review-page-wrapper .input-icon-wrapper img {
    padding-left: 14px;
}

.review-page-wrapper .input-icon-wrapper input {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #0c2640;
    border: none !important;
    box-shadow: unset !important;
}

.review-page-wrapper .input-icon-wrapper input:is(:focus, :focus-visible) {
    outline: none;
    box-shadow: none;
}

.review-page-wrapper .review-page-content .content-card-review .coupon-checkbox {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 1.5rem;
    color: #4B4B4B;
}

.review-page-wrapper .card.information-card .image-uploaded {
    border: 1px dashed #18AEE3;
    background: #E7F8FE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 223px;
    height: 95px;
    margin-right: 20px;
}

.review-page-wrapper .card.information-card .image-uploaded img {
    width: 180px;
    height: 60px;
    object-fit: contain;
}

.review-page-wrapper .card.information-card input[type='file'] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.review-page-wrapper .card.information-card .upload-btn {
    background: #FFFFFF;
    border: 1px solid #CCF2FF;
    border-radius: 5px;
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #18AEE3;
}

.review-page-wrapper .review-page-content .card {
    padding: 0;
    background: rgba(0, 178, 227, 0.01);
    box-shadow: 0px 4px 5px rgb(0 0 0 / 4%);
    border-radius: 0.625rem;
    background-color: #fff;
    border-radius: 1px;
}

.review-page-wrapper .card.right-card-col {
    border: none;
}

.review_details_info_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 3px;
}

.review_details_info_wrapper span {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #18AEE3;
}

.review-page-wrapper .adm-dropzone-header {
    border-radius: 9px 9px 0 0;
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: #464E5F;
    padding: 0.75rem 1.25rem;
    font-weight: 600;
    border-bottom: 1px solid #F4F4F4;
}

.review-page-wrapper .card.right-card-col .card__address-content {
    background: #0C2640;
    border-radius: 0px;
    padding: 25px 46px 15px 46px;
}

.review-page-wrapper .card.right-card-col .card__address-content.hasImg {
    padding: 25px 46px 15px 25px;
}

.review-page-wrapper .card.right-card-col .card__address-content .top .head {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 19.1678px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.review-page-wrapper .card.right-card-col .card__address-content .top .sub-head {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0;
}

.review-page-wrapper .card.right-card-col .card__address-content .bottom span {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
}

.review-page-wrapper .card.right-card-col .content__wrap {
    padding-left: 30px;
    padding-bottom: 40px;
    padding-right: 30px;
}

.review-page-wrapper .right-card-col .experience-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 5.3px 0px #11111A0F;
    border-radius:9px;
    margin-top: 40px;
    padding: 30px;
}

.review-page-wrapper .right-card-col .experience-card .title {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-size: 1.375rem;
    line-height: 1.938rem;
    text-align: center;
    margin: 0;
    color: #000000;
}

.review-page-wrapper .right-card-col .experience-card p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #565656;
}

.review-page-wrapper .right-card-col .experience-card .buttons {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.review_page_url {
    position: relative;
}

.review_page_url span {
    font-size: 0.8em;
    opacity: 0.8;
    font-family: 'Poppins-Regular';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    display: flex;
    align-items: center;
}

.review_page_url input {
    padding-left: 130px !important;
}

.review-page-wrapper .right-card-col .experience-card .buttons .review-btn-platform {
    max-width: 100%;
    min-width: 310px;
    box-sizing: border-box;
    position: relative;
    background: #18AEE3;
    border-radius: 5.72128px;
    margin-bottom: 27px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 1rem;
    line-height: 2.063rem;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: center;
}

.card-position-sticky {
    position: sticky;
    top: 62px;
}

.review-page-wrapper .right-card-col .experience-card .buttons .review-btn-platform img {
    display: block;
    margin-right: 10px;
    position: absolute;
    left: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
}

.contact-us-section {
    width: 100%;
    margin: auto;
    text-align: center;
}

.connect-google-account {
    padding: 20px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: rgb(238, 34, 71);
}

.connect-google-account>a {
    color: #3281C4;
    text-decoration: none;
}

.contact-us-section h6 {
    font-family: "Poppins-Regular";
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-us-section button {
    background: #EE2247;
    border: 0px;
    padding: 15px 20px;
    font-family: "Poppins-Medium";
    color: #fff;
    border-radius: 5px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.contact-us-section a {
    text-decoration: none;
    font-family: "Poppins-Regular";
    display: block;
    margin-top: 10px;
    color: #00B2E3;
    font-size: 14px;
}

.contact-us-section button svg {
    font-size: 1em;
    margin-right: 10px;
    display: block;
}

/* Content */

/* Footer */
.review-page-wrapper .review-page-content .content-card-footer {
    background: #fff;
    border-top: 1px solid #F4F4F4;
    padding: 0.75rem 1.25rem;
}

.review-page-wrapper .review-page-content .content-card-footer .right-wrap p {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4D5E80;
    margin-left: 0.938rem;
}

/* Footer */
.content-card-review ul {
    list-style: none;
    padding-left: 0px;
}

.content-card-review ul li {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #F3F3F3;
}

.content-card-review ul li > svg {
    margin-right: 5px;
    font-size: 20px;
    opacity: 0.5;
    padding-top: 17px;
}

.review-page-wrapper .review-page-foot {
    border-radius: 0px;
    text-align: right;
}

.copy-review-url {
    position: absolute !important;
    right: 5px;
    top: 0px;
    opacity: 0.5;
    cursor: pointer;
}

.copy-review-url>svg {
    font-size: 14px;
}

.review-page-wrapper .review-page-foot .btn-review-page {
    background: #00B2E3;
    border-radius: 3px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    padding: 4px 20px;
}

.btn-review-page .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}

.btn-review-page-white .MuiLoadingButton-loadingIndicator {
    color: #fff !important;
}

.colorpicker-trigger {
    padding-bottom: 12px;
}

.colorpicker-trigger {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
}

.colorpicker-trigger>div:first-child {
    width: 32px;
    height: 32px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.colorpicker-trigger>div:last-child {
    border: 1px solid #ced4da;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 3px;
    min-width: 100px;
    margin-left: 3px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    letter-spacing: 1px;
}

.colorpicker-trigger>input {
    border: 1px solid #ced4da;
    padding: 10px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 3px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    letter-spacing: 1px;
    min-width: 100px;
}

.custom-style {
    padding-bottom: 12px;
}

.custom-style {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 4px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.custom-style>div:first-child {
    width: 22px;
    height: 22px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.custom-style>div:last-child {
    width: -moz-fit-content;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 3px;
    min-width: 100px;
    margin-left: 3px;
    font-family: "Poppins-Regular";
    font-size: 12px;
    letter-spacing: 1px;
}

.custom-style>input {
    border: 0;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 3px;
    font-family: "Poppins-Regular";
    font-size: 12px;
    letter-spacing: 1px;
    min-width: 100px;
}

@media (min-width: 1500px) {
    .review-page-wrapper .card.right-card-col .content__wrap {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/* @media only screen and (min-width: 900px) {
    .coupon__wrap {
        margin-top: 40px;
    }
} */

@media (max-width: 1500px) {
    .review-page-wrapper .right-card-col .experience-card .title {
        font-size: 1.175rem;
        line-height: 1.538rem;
    }

    .review-page-wrapper .right-card-col .experience-card p {
        font-size: 0.875rem;
        line-height: 1.22rem;
    }
}

@media (max-width: 576px) {
    .review-page-wrapper .right-card-col .experience-card .buttons .review-btn-platform {
        width: 205px;
        font-size: 13px;
    }
}

@media (max-width: 415px) {
    .review-page-wrapper .card.right-card-col .content__wrap {
        padding-left: 8px;
        padding-right: 8px;
    }

    .review-page-wrapper .card.right-card-col .card__address-content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .review-page-wrapper .card.right-card-col .card__address-content .top .head {
        font-size: 16px;
    }

    .review-page-wrapper .card.right-card-col .card__address-content .top .sub-head {
        font-size: 13px;
    }
}

@media only screen and (max-width: 370px) {
    .review-page-wrapper .review-page_header .card .title {
        font-size: 0.938rem;
        line-height: 1.2rem;
    }
}

@media (max-width: 340px) {
    .review-page-wrapper .right-card-col .experience-card .buttons .review-btn-platform {
        min-width: 210px;
        font-size: 0.813rem;
    }
}