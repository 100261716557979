.maxHeight-40 {
    height: 40vh;
    overflow-y: auto;
}

.mt-30 {
    margin-top: 30px;
}

.forms-header>h1 {
    font-family: "Poppins-Regular";
    font-size: 26px;
    color: #221f1f;
}

.forms-header>button,
.bottom-blue-button>button {
    background-color: #00B4E5;
    border: 0px;
    padding: 10px 20px;
    outline: 0px;
    border-radius: 3px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.forms-header>button img {
    margin-right: 11px;
}

.manage-inputs-footer>button>.MuiLoadingButton-loadingIndicator {
    color: #fff;
    left: 15px;
}

.drag-drop-field {
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
}

.drag-drop-field>svg {
    position: absolute;
    left: -10px;
    top: 16px;
    opacity: 0.7;
}

.forms-header>button:hover,
.bottom-blue-button>button:hover {
    background-color: #00b3e5c4;
}

.form-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    font-family: "Poppins-Regular";
    border-radius: 5px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
    margin-bottom: 15px;
}

.form-list-item a {
    text-decoration: none;
    color: #221f1f;
}

.form-list-item a:hover {
    color: #00B4E5;
}

.form-actions>svg {
    cursor: pointer;
    font-size: 20px;
}

.embed-div>div {
    display: flex;
}

.succes-Copying {
    width: fit-content;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-left: 10px;
    height: 40px;
}

.link-div {
    background: #80808030;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    color: #0095ff;
}

.embed-div {
    font-family: "Poppins-Regular";
}

.embed-div>h4 {
    color: #171717;
    margin: 0px 0px 3px 0px;
}

.embed-div>p {
    font-size: 13px;
    margin: 0px;
    margin-bottom: 15px;
}

.embed-div code {
    background: #80808030;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.rating-type label>svg {
    color: grey;
    cursor: pointer;
}

.rating-type>input:checked+label>svg {
    color: rgb(255, 196, 0);
}

.form-actions>svg:first-child {
    margin-right: 10px;
    color: grey;
}

.form-actions svg:last-child {
    cursor: pointer;
    font-size: 20px;
    color: rgba(255, 0, 0, 0.623);
}

.form-actions svg:last-child {
    color: red;
}

.link-back {
    color: rgb(77, 77, 77);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.link-back>svg {
    margin-right: 5px;
}

.link-back:hover {
    color: #00B4E5;
}

.forms-details-header {
    display: flex;
    align-items: center;
    width: fit-content;
}

.forms-details-header input {
    border: 1px solid rgb(231, 231, 231);
    font-family: "Poppins-Regular";
    font-size: 26px;
    color: #221f1f;
    background-color: transparent;
    width: fit-content;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
}

.forms-details-header input:read-only {
    border: 0px;
    background-color: transparent;
    padding: 5px 0px;
}

.forms-details-header svg {
    font-size: 20px;
    color: grey;
    cursor: pointer;
}

.my-color .MuiTab-textColorPrimary.Mui-selected {
    color: #00B4E5 !important;
}

.my-color .MuiTabs-indicator {
    background-color: #00B4E5 !important;
}

.form-details-container {
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
    position: relative;
}

.bottom-blue-button>button {
    /* display: block; */
    margin-left: unset;
}

.bottom-blue-button>button img {
    margin-right: 11px;
}

.bottom-fixed {
    width: 100%;
    border-top: 1px solid rgba(194, 187, 187, 0.205);
    padding: 20px;
}

.form-element h4,
.form-element-section h4 {
    margin: 0px;
    font-family: "Poppins-Regular";
    color: #221f1f;
}

.form-element>td,
.form-element-th>th {
    white-space: nowrap;
}

.form-element svg,
.form-element-section svg {
    color: #4d4b4b;
    font-size: 16px;
    margin-left: 10px;
}

.form-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid rgba(194, 187, 187, 0.205);
    border-radius: 5px;
    padding: 3px 10px;
    margin-bottom: 15px;
    padding: 10px;
}

.form-element-section {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(5, 83, 11, 0.205);
    margin-bottom: 15px;
    height: auto;
}

.form-element-section>div>div {
    display: flex;
    align-items: center;
}

.form-element>div:not(.form-element-actions)>div {
    display: flex;
    align-items: center;
}

.form-element>div:not(.form-element-actions)>span {
    font-size: 12px;
    font-family: "Poppins-Medium";
    color: red;
}

.form-element-actions {
    cursor: pointer;
    display: none;
}

.form-element-actions span.icon-rounded-trash {
    margin-left: 8px;
}

.form-element-actions:hover .icon-mydevicon-edit-bordered::before {
    color: rgb(24, 174, 227);
}

.form-element-actions:hover .icon-rounded-trash::before {
    color: #f14a26;
}

.form-element-actions>svg:nth-child(2) {
    color: rgba(255, 0, 0, 0.623);
}

.form-element-actions>svg:nth-child(2):hover {
    color: red;
}

.box-container-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1024;
}

.form-element:hover>.form-element-actions {
    display: flex;
    align-items: center;
}

.footer-privacy {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #000;
    text-transform: capitalize;
    text-align: center;
    margin-top: 20px !important;
}

.footer-privacy a {
    color: #0095ff;
    text-decoration: none;
}

/*Modal*/
.manage-inputs {
    background: #ffff;
    width: 600px;
    max-height: 70vh;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: 0px;
    border-radius: 3px;
}

.manage-inputs-body {
    height: calc(100% - 145px);
    overflow-y: auto;
}

.manage-inputs-header h6 {
    text-align: left;
    font-size: 20px !important;
    font-family: "Poppins-Light";
    margin: 0px;
    padding: 20px;
    color: #00B4E5;
}

.manage-inputs-header p.error {
    padding: 0 20px 20px 20px;
    color: rgb(201, 28, 37);
    margin: 0;
    font-size: 13px;
    font-family: 'Poppins-Regular';
}

.manage-inputs-header {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(194, 187, 187, 0.205);
}

.label-field-container>div {
    display: block;
    margin-bottom: 25px;
}

.label-field-container label {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
}

.label-field-container span {
    font-size: 11px;
    color: #221f1f;
}

.label-field-container input[type='text'],
.label-field-container input[type='email'] {
    border: 1px solid rgba(166, 178, 205, .25);
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 10px;
    box-sizing: border-box;
}

.label-field-container input[type='number'] {
    border: 1px solid rgba(166, 178, 205, .25);
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 10px;
    box-sizing: border-box;
}

.label-field-container input[type='text']:not(.ReactTags__tagInputField),
.label-field-container input[type='email'] {
    width: 100%;
}

.field-display-container {
    padding-left: 20px;
}

.type-num span {
    display: block;
    margin-top: 5px;
}

.custom-react-tags .tag-wrapper {
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    margin-right: 3px;
    display: inline-block;
    margin-bottom: 6px;
}

.custom-react-tags button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    outline: none;
}

.custom-react-tags .ReactTags__selected {
    margin-bottom: 10px;
}

.manage-inputs-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    z-index: 1;
}

.button-cancel-grey {
    background: transparent;
    padding: 8px 15px;
    margin-right: 10px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    border: 1px solid #00B4E5;
    border-radius: 3px;
    color: #00B4E5;
    cursor: pointer;
}

.button-submit-blue {
    background: transparent;
    padding: 8px 15px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    border: 1px solid #00B4E5;
    border-radius: 3px;
    background-color: #00B4E5;
    color: #fff;
    cursor: pointer;
}

.manage-inputs-body-form {
    padding: 0 20px;
}

.manage-inputs-body-form {
    font-family: "Poppins-Regular";
}

/*CLIENT*/
.form-client {
    width: 620px;
    max-width: 100%;
    margin: 0px auto;
    padding-bottom: 15px;
    padding-top: 15px;
}

.form-client>button {
    height: 50px;
    width: 100%;
    background-color: rgba(0, 130, 182, 1);
    border: 0px;
    outline: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
}

.form-client>div {
    box-shadow: 0 1.2px 6px -2px rgb(0 0 0 / 60%);
    padding: 22px 20px;
    background-color: #fff;
    font-family: "Poppins-Regular";
    height: calc(100vh - 290px);
    overflow-y: auto;
}

.form-fullsc {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-client input:not([type="radio"], [type="checkbox"]),
.form-client select {
    width: 100%;
    height: 45px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(230, 230, 230, .8);

}

.form-client input:focus,
.form-client select:focus {
    color: rgba(0, 130, 182, 1);
    border-color: rgba(0, 130, 182, 1);
    outline: 0px;
}

.flex-radios {
    display: inline-block;
    width: 20%;
    text-align: center;
    position: relative;
    margin-top: 15px;
}

.form-client-logo>img {
    max-width: 200px;
    display: flex;
    margin: auto;
    object-fit: contain;
    max-height: 200px;
}

.rating-stars-form>div {
    font-size: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.rating-stars-form>div>label {
    border: 0px;
    cursor: pointer;
    outline: unset;
    padding: 1px 6px;
}

.form-client>div>div>label,
.form-questions {
    font-size: 16px;
    color: #221f1f;
}

.rating-stars-form>div>button {
    border: 0px;
    cursor: pointer;
    outline: unset;
}

.form-client>div>div>button,
.form-questions {
    font-size: 16px;
    color: #221f1f;
}

.flex-radios::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: grey;
    height: 1px;
    right: 0;
    bottom: 17px;
    z-index: 0;
}

.survey-dashboard-header {
    font-family: "Poppins-Regular";
    font-size: 14px;
    padding-top: 20px;
    color: #00B4E5;
}

.flex-radios>input {
    margin: 0px;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.form-client p {
    margin: 4px 0;
    font-size: 12px;
    color: #808080eb;
}

.form-details-container-actions {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-left: auto;
    right: 0;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.form-details-container-actions>button>svg {
    margin-right: 5px;
}

.form-details-container-actions>button {
    border: 0px;
    color: #00B4E5;
    padding: 10px 20px;
    background: rgba(0, 178, 227, 0.1);
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-size:14px;
    margin-right: 5px;
}

.preview-form {
    display: flex;
    font-family: 'Poppins-Regular';
    text-decoration: none;
    color: #0095ff;
}

.nothing-to-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins-Regular";
    height: 100%;
    color: #8e9fa5;
}

.form-client>div>div {
    margin-bottom: 15px;
}

.thank-you-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    color: #196c83;
}

.flex-btns {
    display: flex;
    align-items: center;
}

.flex-btns>button {
    flex: 50%;
    height: 45px;
    border: 0px;
    cursor: pointer;
    margin: 0px;
    font-family: "Poppins-Regular";
}

.flex-btns>button:first-child {
    background-color: rgba(255, 0, 0, 0.452);
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: unset;
    order: 2;
}

.flex-btns>button:first-child:hover {
    background-color: rgba(255, 0, 0, 0.596);
}

.flex-btns>button:nth-child(2):hover {
    background-color: rgba(128, 128, 128, 0.37);
}

.form-logo-container {
    display: flex !important;
    align-items: flex-end;
}

.form-logo-container img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.form-logo-container .MuiButton-text {
    background-color: transparent;
    height: fit-content;
    width: fit-content;
    padding: 5px 10px;
    background-color: #00b3e556;
    margin-bottom: 0px;
    font-size: 12px !important;
    text-transform: capitalize;
}

.form-logo-container .MuiButton-text:hover {
    background-color: #00B4E5;
    color: #fff;
}

.form-logo-container>div {
    margin-left: 10px;
}

.field-container-redirect>div {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.form-actions {
    display: flex;
    align-items: center;
}

.form_list_remove,
.form_list_edit {
    background: unset;
    border: unset;
    cursor: pointer;
}

.form-actions:hover .form_list_edit .icon-mydevicon-edit-bordered::before,
.quick-reply-item-wrapper:hover .form_list_edit .icon-mydevicon-edit-bordered::before {
    color: rgb(24, 174, 227);
}

.form-actions:hover .form_list_remove .icon-rounded-trash::before,
.quick-reply-item-wrapper:hover .form_list_remove .icon-rounded-trash::before {
    color: #f14a26;
}

.field-container-redirect>div>div,
.field-container-redirect>div>input {
    flex: 1 1 0px;
    margin-right: 10px;
}

.rating-stars-form>div>label svg {
    font-size: 45px;
}

.rating-stars-form>div>button svg {
    font-size: 45px;
}

.field-container-redirect>h6 {
    margin: 0px;
    padding-bottom: 20px;
    font-family: 'Poppins-Regular';
    font-size: 13px;
    color: #808080d1;
    font-family: Poppins-Regular;
    color: #221f1f;
}

.field-container-redirect>div>input {
    height: 37px;
    border: 1px solid rgba(166, 178, 205, .25);
    border-radius: 2px;
    padding-left: 10px;
}

.field-container-redirect>div>label {
    margin-right: 10px;
    width: 85px;
}

.goToMap {
    margin-left: 12px;
    text-decoration: none;
    background: #00B4E5 !important;
    padding: 5px 10px;
    border-radius: 5px;
    color: #ffff;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.no-spin {
    -moz-appearance: textfield !important;
}

.date-container {
    position: relative;
}

.date-container svg {
    color: rgb(0 130 182);
    position: absolute;
    left: 35%;
    top: 15px;
    font-size: 20px;
    cursor: pointer;
}

.form-client .react-calendar {
    width: 100%;
    border: 0px;
    background: #00c1f729;
    border-radius: 5px;
    margin-top: 5px;
}

.form-client .react-calendar abbr[title] {
    text-decoration: none;
    color: #173151;
}

.form-client .react-calendar__tile--active:enabled:hover,
.form-client .react-calendar__tile--active:enabled:focus,
.form-client .react-calendar__tile--active {
    background: #00B4E5;
}

.date-container>div:first-child {
    display: flex;
    width: 30%;
    align-items: center;
}

.date-container>div>div {
    position: relative;
}

.date-container>div>div>input {
    padding-left: 15px;
}

.date-container-inputs>div::after {
    content: "/";
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #dbd9d9;
}

.date-container-inputs>div:first-child::after {
    display: none;
}

.add-barcode-reader {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;
}

.add-barcode-reader div {
    margin-top: 10px;
}

.add-barcode-reader label {
    margin-bottom: 10px;
    display: block;
}

.add-barcode-reader input {
    border: 1px solid rgba(230, 230, 230, .8) !important;
    border-radius: 5px;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-right: 10px;
}

.add-barcode-reader button {
    background-color: #e6e6e6;
    border: 0px;
    font-family: "Poppins-Regular";
    border-radius: 3px;
    padding: 12px 20px;
    margin-left: 10px;
    cursor: pointer;
}

#interactive.viewport {
    width: 600px;
    height: 300px;
    position: relative;
}

#interactive.viewport canvas,
video {
    width: 600px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
    width: 600px;
    height: 300px;
}

.add-barcode-reader button:hover {
    background-color: #dadada;
}

.custom-scanner {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 1024;
    margin-bottom: 0px !important;
}

.custom-scanner div.w-full {
    width: 600px;
    height: 200px;
}

.custom-scanner div.w-full video {
    object-fit: cover;
}

/* MEDIA QUERIES */
@media(max-width: 1024px) {
    .manage-inputs {
        width: 80vw;
    }
}

@media(max-width: 768px) {
    .field-container-redirect>div {
        display: block;
    }

    .rating-stars-form>div>label svg {
        font-size: 35px;
    }

    .rating-stars-form>div>button svg {
        font-size: 35px;
    }

    .form-fullsc>form {
        width: 100%;
    }

    .form-client>div {
        height: auto !important;
        margin-top: 30px;
    }

    .field-container-redirect>div>label,
    .field-container-redirect>div>div,
    .field-container-redirect>div>input {
        margin-bottom: 10px;
        display: block;
        margin-right: 0px;
    }

    .field-container-redirect>div>input {
        width: 100%;
        box-sizing: border-box;
    }

    .form-client {
        width: 95%;
    }
}