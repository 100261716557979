input, button{
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6{
  font-weight: 300;
}
html{
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: "Poppins-Black";
  src: url(../public/fonts/Poppins/Poppins-Black.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-BlackItalic";
  src: url(../public/fonts/Poppins/Poppins-BlackItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url(../public/fonts/Poppins/Poppins-Bold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url(../public/fonts/Poppins/Poppins-BoldItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url(../public/fonts/Poppins/Poppins-ExtraBold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url(../public/fonts/Poppins/Poppins-ExtraBoldItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url(../public/fonts/Poppins/Poppins-ExtraLight.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url(../public/fonts/Poppins/Poppins-ExtraLightItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Italic";
  src: url(../public/fonts/Poppins/Poppins-Italic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url(../public/fonts/Poppins/Poppins-Light.woff2) format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-LightItalic";
  src: url(../public/fonts/Poppins/Poppins-LightItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(../public/fonts/Poppins/Poppins-Medium.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url(../public/fonts/Poppins/Poppins-MediumItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../public/fonts/Poppins/Poppins-Regular.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../public/fonts/Poppins/Poppins-SemiBold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url(../public/fonts/Poppins/Poppins-SemiBoldItalic.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Thin";
  src: url(../public/fonts/Poppins/Poppins-Thin.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: "Poppins-ThinItalic";
  src: url(../public/fonts/Poppins/Poppins-ThinItalic.woff2) format('woff2');
  font-display: swap;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
