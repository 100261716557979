.book-demo-section{
    padding-top: 120px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
}
.book-demo-section::before{
    position: absolute;
    content: '';
    background-color: #0086E1;
    height: 570px;
    width: 100%;
    top: 0;
    border-radius: 0 0 0 140px;
    z-index: -1;
}
.book-demo-section-wrapper > div{
    text-align: center;
    padding-top: 50px;
}
.book-demo-section-wrapper > div > h3{
    font-weight: bold;
    color: #fff;
    font-size: 3em;
    margin-top: 20px;
    font-family: "Poppins-Medium";
    margin: 0;
    margin-top: 20px;
}
.book-demo-section-wrapper > div > p{
    color: #fff;
    font-family: "Poppins-Light";
    margin: 0;
    margin-bottom: 1rem;
}
.position-absolute{
    position: absolute;
}
.position-relative{
    position: relative;
}
.images-first-sp{
    left: -102px;
    top: 380px;
    position: absolute;
}
.images-second-sp{
    top: 376px;
    right: -100px;
    position: absolute;
}
.first-image-sp{
    box-shadow: 24px 49px 99px #0000000a;
    border-radius: 35px;
}
.second-image-sp{
    position: absolute;
    bottom: -70px;
    right: -50px;
}

.fourth-image-sp {
    width: 129px;
    position: absolute;
    left: -47px;
    top: 100px;
}
.third-image-sp{
    width: 300px;
    box-shadow: 24px 49px 99px #0000000a;
    border-radius: 35px;
} 
.form-container{
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 36px;
    padding: 80px 50px 50px 50px;
    background-color: #F8F8F8;
    width: 55%;
    margin: auto;
    box-sizing: border-box;
}
.inputs-wrapper-sp{
    margin-bottom: 20px;
}
.inputs-wrapper-sp > span{
    color: red;
    font-family: "Poppins-Regular";
    font-size: 12px;
    padding-top: 10px;
}

.form-container  input:not(.search-box, .MuiSelect-nativeInput){
    font-family: "Poppins-Regular";
    border: 0;
    padding: 12px;
    width: 100%!important;
    border-radius: 7px;
    border: 0px!important;
    min-height: 44px;
    background-color: #F8F8F8!important;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%)!important;
}
.inputs-wrapper-sp.error input{
    box-shadow: 0 0 0 1px red!important;
}
.form-container .react-tel-input .form-control{
    padding-left: 50px!important;
}
.form-container input:focus{
   border: 0px!important;
   box-shadow: 0 0 2px rgb(0 179 227), 0 0px 2px rgb(0 178 226)!important;
}
.form-container > div:first-child{
    margin-bottom: 30px;
    text-align: center;
}
.form-container h3{
    font-weight: bold;
    color: #103254;
    font-size: 40px;
    margin-bottom: 15px;
    font-family: "Poppins-Bold";
    margin: 0px;
}
.why-demo-irevu{
    background-color: #0086E1;
    padding: 70px 0;
}
.why-demo-irevu-div h3{
    font-family: "Poppins-Bold";
    color: #fff;
    font-size: 40px;
}
.why-demo-irevu ul{
    list-style: none;
}
.why-demo-irevu ul li{
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    color: #fff;
    line-height: 1.1;
    margin-bottom: 15px;
    font-family: 'Poppins-Light';
}
.why-demo-irevu ul li svg{
    padding-right: 10px;
    font-size: 25px;
    color: #fff;
    flex-shrink: 0;
}
.quick-and-easily-logos{
    text-align: center;
}
.quick-and-easily-logos img{
    max-height: 140px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    padding: 20px 40px;
}
.quick-and-easily-logos>img:hover {
    transform: scale(1.04);
}
.listing-companies{
    padding: 120px 0;
}
.quick-and-easily-div h3{
    font-size: 40px;
    color: #103254;
    font-weight: bold;
    text-align: center;
    width: 65%;
    margin: auto;
    font-family: "Poppins-Bold";
}
.captcha-div{
    font-size: 12px;
    margin-bottom: 8px;
    font-family: 'Poppins-Regular';
}
.captcha-div a {
    text-decoration: none;
    color: #0087e2;
    margin-top: 8px;
}
.submit-signup{
    display: flex!important;
    background-color: #0086E1!important;
    padding: 14px 45px!important;
    color: #fff!important;
    text-decoration: none!important;
    border-radius: 7px!important;
    border: 0px!important;
    font-family: "Poppins-Regular"!important;
    margin-left: auto!important;
    cursor: pointer!important;
    text-transform: capitalize!important;
    margin-top: 30px!important;
}
.submit-signup>.MuiLoadingButton-loadingIndicator {
    left: 1.5em;
    color: #fff;
}
.submit-signup:disabled{
    opacity: 0.5;
    cursor: initial;
}
.autocomplete-results-sp{
    max-height: 300px;
    overflow: auto;
    margin-bottom: 15px;
}
.autocomplete-results-item-sp{
    padding: 10px;
    cursor: pointer;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
}
.autocomplete-results-item-sp:hover{
    color: #333;
    background: #C0EFFF;
}
.autocomplete-results-item-sp >h4 {
    color: #173151; 
    font-family: "Poppins-Light";
    font-size: 14px;
    margin: 0;
}
.form-container p{
    font-family: "Poppins-Bold";
    color: #01A7DF;
    margin-top: 0px;
}
.wrapper-loc-create{
    max-width: 700px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    --webkit-justify-content: space-around;
    --webkit-align-items: center;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 30px;
    padding: 35px;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
}
.wrapper-loc-create > svg{
    font-size: 60px;
    color: #b0b7c3;
}
.wrapper-loc-create > div > button:first-child{
    border: 1px solid #00B4E5;
    color: #00B4E5;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    /* flex: 50%; */
    font-family: "Poppins-Regular";
    cursor: pointer;
}
.wrapper-loc-create > div:last-child{
    width: 100%;
    display: flex;
    justify-content: center;
    --webkit-justify-content: center;
}
.wrapper-loc-create h2{
    font-family: "Poppins-Medium";
    margin-bottom: 20px;
    margin-top: 20x;
    font-size: 30px;
    text-align: center;
}
.wrapper-inner-create{
    width: 80%;
    margin: auto;
}
.wrapper-loc-create p{
    font-family: "Poppins-Light";
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
}
.wrapper-loc-create > div > button:last-child>.MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}
.signup-alert{
    position: fixed;
    bottom: 30px;
    right: 0;
    z-index: 99999;
    left: 0;
    width: fit-content;
    margin: auto;
}
.signup-alert > div{
    padding: 20px!important;
}
.wrapper-loc-create > div > button:last-child{
    border: 1px solid #00B4E5;
    background-color: #00B4E5;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    /* flex: 50%; */
    font-family: "Poppins-Regular";
    text-transform: capitalize;
}
@media(max-width: 1024px){
    .images-first-sp, .images-second-sp{
        display: none;
    }
}
@media(min-width: 576px) and (max-width: 1024px){
    .form-container{
        padding: 50px 30px 30px 20px;
        background-color: #F8F8F8;
        width: 100%;
        margin: auto;
    }
}
@media(max-width: 576px){
    .book-demo-section::before{
        border-radius: unset;
    }
    .book-demo-section{
        padding-top: 0px;
    }
    .book-demo-section-wrapper > div{
        padding-top: 20px;
    }
    .form-container{
        padding: 50px 30px 30px 20px;
        background-color: #F8F8F8;
        width: 100%;
        margin: auto;
    }
    .submit-signup{
        margin: auto;
        width: 100%;
    }
}