.irevu-table {
    width: 100%;
    overflow-x: auto;
    margin-top: 14px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-5 {
    margin-bottom: 5px;
}

.justify-content-end {
    justify-content: end;
}

.irevu-table table {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    margin-bottom: 10px;
}

.flip-mydev-datatable-top {
    margin-bottom: 10px;
}

.mydev-datatable-header {
    display: flex;
    align-items: center;
    min-height: 45px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mydev-datatable-custom>tbody .mydev-datatable-actions a:first-child {
    padding-left: 0px;
}

.mydev-datatable-header h5 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #25313C;
}

.mydev-datatable-header input.mydev-datatable-forms::placeholder {
    color: #000;
    font-size: 14px;
    opacity: 0.5;
}

.mydev-datatable-form-dropdown>a {
    min-width: fit-content;
    height: 40px;
    box-shadow: 0px 0px 20px rgba(16, 50, 84, 0.03);
    border-radius: 3px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-right: 12px;
    text-align: left;
    color: #25313C;
    line-height: 2;
}

.mydev-datatable-form-dropdown>a:focus {
    box-shadow: unset;
}

.mydev-datatable-form-dropdown>a>img {
    margin-right: 10px;
}

.mydev-datatable-form-dropdown .dropdown-menu {
    width: calc(100% - 12px);
    min-width: calc(100% - 12px);
}

.mydev-datatable-form-dropdown .dropdown-menu>button {
    display: block;
}

.mydev-datatable-form-dropdown>a::after {
    display: none;
}

.mydev-datatable-header button.mydev-datatable-forms {
    min-width: fit-content;
    width: 103px;
    height: 40px;
    box-shadow: 0px 0px 20px rgba(16, 50, 84, 0.03);
    border-radius: 3px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-right: 12px;
    text-align: left;
    color: #25313C;
}

.mydev-datatable-header button.mydev-datatable-forms img {
    margin-right: 10px;
}

.mydev-datatable-header button.mydev-datatable-forms:focus,
.mydev-datatable-header input.mydev-datatable-forms:focus {
    outline: 0px;
}

.mydev-datatable-forms-add:hover {
    color: #fff;
}

.mydev-datatable-header input.mydev-datatable-forms {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    width: 260px;
    height: 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(16, 50, 84, 0.03);
    padding-left: 30px;
    background-image: url("../../public/admin-icons/search.svg");
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: center;
    margin-right: 12px;
}

.mydev-datatable-forms-add {
    min-width: 131px;
    height: 40px;
    background: #18AEE3;
    box-shadow: 0px 10px 20px rgba(24, 174, 227, 0.1);
    border-radius: 3px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;
    cursor: pointer;
}

.mydev-datatable-forms-add>img {
    margin-right: 10px;
}

.mydev-datatable-custom>tbody {
    border-top: 0px;
}

.mydev-datatable-forms button::after {
    display: none;
}

.mydev-datatable-forms .filter-option-inner-inner {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 2;
    color: #25313C;
    text-align: right;
}

#show.MuiSelect-select {
    font-size: 12px;
    padding: 7px 12px 7px 70px;
    font-style: normal;
    font-weight: 400;
    font-family: "Poppins-Regular";
    line-height: 2;
    color: #25313C;
    margin-right: 12px;
}

#show-number {
    padding: 7px 60px 7px 70px;
}

#sort.MuiSelect-select {
    font-size: 12px;
    padding: 7px 12px 7px 65px;
    font-style: normal;
    font-weight: 400;
    font-family: "Poppins-Regular";
    line-height: 2;
    color: #25313C;
    margin-right: 12px;
    border: 0px;
}

#sort.MuiSelect-select::before {
    content: 'Sort:';
    position: absolute;
    left: 30px;
    opacity: 0.5;
}

#sort.MuiSelect-select::after {
    content: url("../../public/admin-icons/arrow.svg");
    position: absolute;
    left: 12px;
    opacity: 0.5;
    top: 9px;
    display: flex;
    align-items: center;
}

#show.MuiSelect-select::before {
    content: 'Show';
    position: absolute;
    left: 10px;
    opacity: 0.5;
}

#show-number.MuiSelect-select::before {
    content: 'Show:';
    position: absolute;
    left: 10px;
    opacity: 0.5;
}

.mydev-datatable-actions .MuiSelect-iconOutlined {
    display: none;
}

#show.MuiSelect-select::after {
    content: url("../../public/admin-icons/icon.svg");
    position: absolute;
    left: 50px;
    opacity: 0.5;
    bottom: 2px;
    top: 0;
    display: flex;
    align-items: center;
}

.mydev-datatable-forms-sort .filter-option-inner-inner::before {
    content: 'Sort:';
    text-align: right;
    padding-right: 2px;
}

.custom-menu-item {
    justify-content: center !important;
    font-family: 'Poppins-Regular' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #25313C !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.custom-menu-item.Mui-selected {
    background-color: #18AEE3 !important;
    color: #fff !important;
}

.mydev-datatable-forms-sort button::before {
    /* content: url('../../images/datatable-images/arrow.svg'); */
    padding-right: 10px;
}

/* DATATABLE MBISHKRIM */
.mydev-datatable-custom {
    border-bottom: 0px;
    border-collapse: separate;
}

.mydev-datatable-custom th {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #25313C;
    border: 0px;
    vertical-align: middle;
    text-align: left;
    border-bottom: 0px;
    padding-left: 10px;
    white-space: nowrap;
}

.mydev-datatable-custom tr {
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 2%);
    border-radius: 5px 5px 0px 0px;
    height: 52px;
}

.mydev-datatable-custom td {
    border: 0px;
    vertical-align: middle;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    padding: 8px 10px;
    box-sizing: content-box;
    font-size: 12px;
    white-space: nowrap;
    color: #25313C;
}

.mydev-datatable-custom tr.selected {
    background: #DDF6FF !important;
    position: relative;
}

/* .mydev-datatable-custom tr.selected::after{
    content: "";
    border-left: 3px solid #18AEE3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 3px;
} */
.mydev-datatable-custom tr.even {
    background: #F8FDFF;
}

.mydev-datatable-custom {
    border-spacing: 0 2px;
}

.mydev-datatable-bold-td {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #25313C;
    word-break: break-all;
}

.mydev-datatable-actions {
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.mydev-datatable-header .mydev-datatable-actions {
    flex-wrap: wrap;
}

.mydev-datatable-actions div {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #25313C;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mydev-datatable-actions button {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #25313C;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0px;
}

.listing_actions button {
    padding-top: 0;
}

table .mydev-datatable-actions div {
    margin-right: 20px;
}

.mydev-datatable-actions button span {
    padding-right: 4px;
    color: #22272F;
}

.mydev-datatable-actions div span {
    padding-right: 4px;
    color: #22272F;
}

.mydev-datatable-custom tbody td.select-checkbox::before {
    width: 14px;
    height: 14px;
    border: 0.5px solid rgba(24, 82, 125, 0.5);
    border-radius: 2px;
    background: #FFFFFF;
    top: unset;
    cursor: pointer;
    left: 27px;
}

.mydev-datatable-custom thead th.select-checkbox::after {
    display: none;
}

.mydev-datatable-custom tbody tr.selected td.select-checkbox::before {
    border: unset;
}

.mydev-datatable-custom tbody tr.selected td.select-checkbox::after {
    /* content: url('../../images/datatable-images/datatable-checked.svg'); */
    text-shadow: unset;
    top: unset;
    border: 0px;
    left: 27px;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: -6px;
    margin-left: -6px;
    display: block;
    font-size: unset;
}

.mydev-datatable-custom thead .sorting,
.mydev-datatable-custom thead .sorting_asc,
.mydev-datatable-custom thead .sorting_desc {
    background-image: none;
}

.mydev-datatable-custom thead .sorting::after,
.mydev-datatable-custom thead .sorting_desc::after {
    /* content: url("/../images/datatable-images/arrow-down-filter.svg"); */
    margin-left: 6px;
    position: absolute;
}

.mydev-datatable-custom thead .sorting_asc::after {
    /* content: url("/../images/datatable-images/arrow-up-filter.svg"); */
    margin-left: 6px;
    position: absolute;
}

.input-select-all {
    display: none;
}

.label-select-all {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 0.5px solid rgba(24, 82, 125, 0.5);
    background: #FFFFFF;
    cursor: pointer;
    margin-left: 3px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.input-select-all:checked+.label-select-all {
    /* background-image: url(/../images/datatable-images/datatable-checked.svg); */
    background-position: center;
    border: 0px;
    background-repeat: no-repeat;

}

.mydev-datatable-table .paginate_button {
    position: relative;
}

.mydevdatatable-icon {
    color: rgb(24, 82, 125);
    font-size: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5px;
}

.mydevdatatable-next-icon {
    transform: rotate(270deg);
}

.mydevdatatable-prev-icon {
    transform: rotate(90deg);
}

.mydev-datatable-table .paginate_button.disabled>span {
    color: #B3C5D2;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #18AEE3;
    color: #fff;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #18AEE3;
    color: #fff;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button {
    border-radius: 3px;
    border: 0px;
    border-radius: 3px;
    border: 0px;
    min-width: 24px;
    padding: 2px;
    height: 24px;
    color: #8BA8BE;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    background: #C0EFFF;
    border-radius: 3px;
    border: 0px;
    background: #18AEE3;
    border-radius: 3px;
    border: 0px;
    min-width: 24px;
    box-shadow: unset;
    padding: 2px;
    height: 24px;
    color: #25313C;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate>.paginate_button,
.mydev-datatable-table .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background: #F7F7F7;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_paginate {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.mydev-datatable-table .dataTables_wrapper .dataTables_info {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    margin-top: 18px;
    color: #25313C;
}

.mydev-datatable-table .ellipsis {
    position: relative;
    cursor: pointer;
}

.grouped-elements {
    position: absolute;
    bottom: 24px;
    right: 8px;
    display: none;
    background: #F8FDFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 3px;
    width: fit-content;
    text-align: center;
}

.grouped-elements>span {
    padding-bottom: 5px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #8BA8BE;
    cursor: pointer;
    padding: 6px 7px 6px 7px;
}

.grouped-elements>span:hover {
    background: #C0EFFF;
}

.show-grouped-nums {
    display: grid;
}

.mydev-datatable-custom tbody tr:hover {
    background: #F8FDFF;
}

.mydev-datatable-custom tbody tr:hover {
    box-shadow: 0 0 0 1px rgba(24, 174, 227, 0.44);
    border-radius: 3px;
}

/* .mydev-datatable-custom  tbody tr:hover > td:first-child{
    box-shadow: inset 1px 0 0 0 rgba(24, 174, 227, 0.44);
    border-radius: 3px;
}
.mydev-datatable-custom  tbody tr:hover > td:last-child{
    box-shadow: inset -8px 0 0px -7px rgb(24 174 227 / 44%);
    border-radius: 3px;
} */
.mydev-datatable-custom tbody tr:hover .icon-mydevicon-edit-bordered::before,
.mydev-datatable-custom tbody tr:hover .mydevicon-search {
    color: rgb(24, 174, 227);
}

.mydev-datatable-custom tbody tr:hover .icon-rounded-trash::before,
.mydev-datatable-custom tbody tr:hover .mydevicon-remove-rounded {
    color: #f14a26;
}

.wrap-white {
    white-space: pre-wrap;
}

.mydev-datatable-actions .dropdown-menu .btn:focus {
    outline: none;
    box-shadow: none;
}

.mydev-datatable-actions .dropdown-menu .btn {
    width: 100%;
}

.mydevdatatable-extended-actions {
    display: flex;
    background: #FFFFFF;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(16, 50, 84, 0.03);
    border-radius: 3px;
    padding: 3px;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    margin-left: unset;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.mydevdatatable-extended-actions>button {
    width: 38px;
    height: 38px;
    box-shadow: 0px 0px 20px rgba(16, 50, 84, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mydevdatatable-extended-actions>button>span::before {
    color: #25313C;
}

.btn-mydevdatatable-blue:hover {
    border: 1px solid #18AEE3;
}

.btn-mydevdatatable-blue:hover>span::before {
    color: #18AEE3
}

.btn-mydevdatatable-red:hover {
    border: 1px solid #f55f44;
}

.btn-mydevdatatable-red:hover>span::before {
    color: #f55f44;
}

.rotate-datatable-action>h5 {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
}

.rotate-datatable-action>.mydevdatatable-extended-actions {
    opacity: 1;
    width: fit-content;
    height: fit-content;
    visibility: visible;
}

.mydev-datatable-custom tbody tr:hover td:first-child {
    border-left: 0.5px solid rgb(24 174 227 / 44%);
    border-radius: 2px;
}

.mydev-datatable-custom tbody tr:hover td:last-child {
    border-right: 0.5px solid rgb(24 174 227 / 44%);
    border-radius: 2px;
}

.mydev-datatable-custom tbody tr td:first-child {
    border-left: 0.5px solid #fff;
    padding-left: 15px;
}

.mydev-datatable-custom thead tr th:first-child {
    border-left: 0.5px solid #fff;
    padding-left: 15px;
}

.mydev-datatable-custom tbody tr td:last-child {
    border-right: 0.5px solid #fff;
}

.mydev-datatable-table .dataTables_processing {
    background: rgba(24, 174, 227, 0.2);
    top: 80px;
    bottom: 70px;
    line-height: 4;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #25313C;
    height: unset;
    min-height: 100px;
}

.mydev-datatable-custom tr.selected td:first-child::before {
    content: "";
    height: 100%;
    width: 3px;
    background: #18AEE3;
    position: absolute;
    top: 0;
    left: 0;
}

.show-color-span {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}

.hidden {
    display: none;
}

.irevu-checkbox {
    width: 14px;
    height: 14px;
    border: 0.5px solid rgba(24, 82, 125, 0.5);
    border-radius: 2px;
    background-color: #fff;
    display: block;
    cursor: pointer;
    box-sizing: border-box;
}

.irevu-checkbox-input:checked+.irevu-checkbox {
    background: url("../../public/admin-icons/datatable-checked.svg");
    border: unset;
}

/* MUI DATATABLE */
.scrollable-div-container {
    height: calc(100vh - 156px);
    /* overflow-y: auto; */
}

.fit-content-w {
    width: fit-content;
    width: -moz-fit-content;
}

.new-datatable .MuiPaper-root {
    box-shadow: unset;
    border-bottom: 0px;
    background-color: transparent;
}

.new-datatable .MuiTableFooter-root {
    border: 0.6px solid #B0B7C336;
    border-top: 0px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
}

.new-datatable .MuiPaper-root>div:nth-child(3) {
    border: 0.6px solid #B0B7C336;
    border-radius: 3px;
}

.new-datatable .MuiPaper-root table tbody tr:last-child td {
    border-bottom: 0px;
}

.new-datatable .MuiPaper-root table tbody tr td:not(.inner-table-td-colspan) {
    border-bottom: 0.6px solid #B0B7C336;
    padding: 8.5px 20px !important;
    background-color: #fff;
}

.new-datatable .MuiPaper-root table thead tr th {
    border-bottom: 0.6px solid #B0B7C336;
    background: #fafafa;
    padding: 8.5px 20px !important;
}

.redirect-link {
    color: #18AEE3;
    text-decoration: none;
}

.new-datatable .MuiTableFooter-root th,
.new-datatable .MuiTableFooter-root td {
    border: 1px solid #B0B7C336;
    border-top: 0px !important;
}

.new-datatable .MuiTableFooter-root thead th:first-child {
    border-top-left-radius: 3px;
}

.new-datatable .MuiTableFooter-root thead th:last-child {
    border-top-right-radius: 3px;
}

.new-datatable .MuiTableFooter-root tbody tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
}

.new-datatable .MuiTableFooter-root tbody tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
}

/* .new-datatable table tbody td:first-child{
    background-color: #fff;
} */
.nowrap-table table th,
.nowrap-table table td {
    white-space: nowrap;
}

.invoices_table table th {
    background-color: #fbfafa;
}

fieldset.MuiFormControl-root {
    padding: 16px 24px 16px 24px !important;
}

.text-right {
    text-align: right !important;
}

.new-datatable th button.MuiButtonBase-root {
    padding: 5px;
    text-transform: capitalize;
    justify-content: left;
}

.table-search-input {
    border: 1px solid #EDEDED;
    background-color: #fff;
    color: #18527D;
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    padding: 10px 34px;
    border-radius: 3px;
    background-image: url("../../public/admin-icons/new-search.svg");
    background-repeat: no-repeat;
    background-position: 10px 50%;
    min-width: 305px;
}

.table-search-input::placeholder {
    color: #18527D;
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
}

.custom-label-datatable {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20.5px;
    /* color: #25313C; */
    color: #000;
    border: 0px;
    vertical-align: middle;
    text-align: left;
    border-bottom: 0px;
    margin: 0px;
    white-space: nowrap;
}

/* Hide the default icons */
.new-datatable .MuiTableSortLabel-iconDirectionAsc path,
.new-datatable .MuiTableSortLabel-iconDirectionDesc path {
    fill: none;
}

.new-datatable .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: #18AEE3;
    font-size: 14px;
    opacity: 0.8;
}


.custom-text-datatable {
    border: 0px;
    vertical-align: middle;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-style: normal;
    padding: 8px 10px;
    box-sizing: content-box;
    font-size: 13px;
    color: #25313C;
    margin: 0px;
    padding-left: 0px;
    white-space: nowrap;
    word-break: break-word;
}

.invoice_number_text {
    color: #00B2E3;
    text-decoration: none;
}

.custom-text-datatable.paid-no,
.custom-text-datatable.paid-yes {
    border-radius: 50px;
    width: 31px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 6px;
}

.custom-text-datatable.paid-no {
    background-color: #F64E60;
}

.paid_td_wrap {
    padding: 12px 4px !important;
}

.custom-text-datatable.paid-yes {
    background-color: #5EB14D;
}

.invoices_actions_btn_wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
}

.invoices_view_btn,
.invoices_download_btn {
    background: #00B2E30D;
    padding: 7px;
    display: flex;
    gap: 5px;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    color: #00B2E3;
    font-family: 'Poppins-Medium';
    font-size: 12px;
}

.invoices_header_container h1 {
    font-family: 'Poppins-Medium';
    font-size: 18px;
    line-height: 27px;
    color: #25313C;
}

.invoices_header_container h6 {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #90969C;
}

.new-datatable td span svg.css-i4bv87-MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: rgba(24, 82, 125, 0.5);
}

.new-datatable th span svg.css-i4bv87-MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: rgba(24, 82, 125, 0.5);
}

.new-datatable th span.Mui-checked svg.css-i4bv87-MuiSvgIcon-root {
    color: #18AEE3
}

.new-datatable .Mui-selected td span svg.css-i4bv87-MuiSvgIcon-root {
    color: #18AEE3
}

.new-datatable table .MuiTableRow-hover:hover .icon-mydevicon-edit-bordered::before,
.new-datatable table .MuiTableRow-hover:hover .mydevicon-search {
    color: rgb(24, 174, 227);
}

.new-datatable table .MuiTableRow-hover:hover .icon-rounded-trash::before,
.new-datatable table .MuiTableRow-hover:hover .mydevicon-remove-rounded {
    color: #f14a26;
}

.download-icon-table {
    margin-right: 5px;
    color: #25313c;
    font-size: 16px;
}

/* .table-header-container h1{
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 1em;
    color: #25313C;
} */
.table-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
}

.table-header-container button {
    background: #00B2E3 !important;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    border-radius: 3px;
    color: #fff !important;
    font-family: 'Poppins-Regular';
    cursor: pointer;
    font-size: 14px;
}

.table-header-container .export_to_excel_btn {
    background: rgba(0, 178, 227, 0.1);
    color: #00B4E5;
}

.export_to_excel_btn:disabled {
    opacity: 0.5;
    cursor: initial;
}

.table-header-container button img {
    margin-right: 11px;
}

.field-container>input,
.field-container-inp {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    outline: 0px;
    font-family: "Poppins-Regular";
    font-size: 0.8em;
    border: 1px solid #ced4da;
}

.field-container>textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    outline: 0px;
    font-family: "Poppins-Regular";
    font-size: 0.8em;
    resize: none;
    border: 1px solid #ced4da;
}

.field-container>input:focus,
.field-container-inp:focus {
    border-color: #18AEE3;
}

.field-container {
    padding: 20px 20px 0 20px;
}

.field-container>label,
.field-container-label {
    font-size: 1em;
    font-family: "Poppins-Medium";
    margin-bottom: 10px;
    display: block;
    color: #22272F
}

.scrollale-div-container {
    height: calc(100vh - 90px);
    overflow-y: auto;
}

.colorpicker-trigger>div:first-child {
    width: 40px;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.colorpicker-trigger {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
}

.colorpicker-trigger>div:last-child {
    border: 1px solid #ced4da;
    width: fit-content;
    padding: 10px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 3px;
    min-width: 100px;
    margin-left: 3px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    letter-spacing: 1px;
}

.colorpicker-trigger {
    padding-bottom: 12px;
}

.sources-container {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.sources-container img {
    width: 25px;
    border-radius: 50%;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
}
.inner-table-muidatatable th, .inner-table-muidatatable td{
    white-space: nowrap!important;
}
.inner-table-td-colspan{
    padding: 20px!important;
    background-color: #ededed;
}
.inner-table-muidatatable th{
    background: grey!important;
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 13px;
}
.inner-table-muidatatable td{
    font-family: 'Poppins-Regular';
    font-size: 13px;
    color: #25313C;
}
.filter-wrapper > h6{
    font-family: 'Poppins-Regular';
    font-size: 13px;
    color: #25313C;
    margin-top: 20px;
    margin-bottom: 5px;
}
.filter-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: flex-end;
    width: fit-content;
}
@media (max-width: 599.95px) {
    .tss-1ls58sa-MUIDataTableBodyCell-simpleCell {
        display: table-cell !important;
    }

    .tss-5wkwbx-MUIDataTableBodyRow-responsiveSimple {
        display: inline-flex !important;
        border-bottom: 0 !important;
    }

    .paid_td_wrap {
        padding: 8px 10px 8px 0 !important;
    }
}

@media (max-width: 440px) {
    .main-container {
        overflow: hidden;
    }
}