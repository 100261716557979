/* .react-tel-input .flag {
    background-image: url('../../public/admin-images/th1_generated.jpg')!important;
    object-fit: contain;
} */

.ml-10 {
    margin-left: 10px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.pb-0 {
    padding-bottom: 0;
}

.load_more_wrapper {
    display: flex;
    align-items: flex-end;
    --webkit-align-items: flex-end;
    width: 119px;
    margin: auto;
    margin-top: 28px;
}

.load_more_wrapper h6 {
    text-align: center;
    font-size: 16px;
    margin: 0;
    font-family: 'Poppins-Regular';
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.background-lightblue {
    position: absolute;
    left: 0;
    right: 0;
    height: 1334px;
    background: #F3F7Fc;
    top: 0;
    z-index: 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the arrows for input type number in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.position-relative {
    position: relative;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.justify-content-start {
    justify-content: start !important;
}

.custom-error-alert {
    background: #FFEBEB !important;
    border-radius: 5px !important;
}

.admin-routes-wrapper {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;
    background-color: #fff;
    border: 0.6px solid #B0B7C336;
    border-radius: 6px;
}

.chart-info-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 253px;
    top: -38px;
    position: absolute;
    right: 0;
}

.chart-info-title>h5 {
    color: #25313C;
    font-size: 18px;
    font-family: "Poppins-Medium";
    margin: 0;
}

.chart-info-title>button {
    color: #1E4FAD;
    font-size: 11px;
    font-family: "Poppins-Medium";
    cursor: pointer;
}

.admin-routes-wrapper>a {
    text-decoration: none;
    color: #25313C;
    font-family: "Poppins-Medium";
    font-size: 14px;
    flex: 1 1;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    position: relative;
    padding: 9px 6px;
    transition: 0.3s;
}

.admin-routes-wrapper>a:hover {
    background: #00b4e5;
    color: #fff;
    border-radius: inherit;
}

.admin-routes-wrapper>a::after {
    content: "";
    display: block;
    height: calc(100% - 10px);
    width: 1px;
    background-color: #00000033;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.admin-routes-wrapper>a:last-child::after {
    display: none;
}

.submit-loading-btn>button {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: #fff !important;
    padding: 8px 15px;
    background: #00B4E5;
    border-radius: 5px;
    border: none;
    float: right;
    cursor: pointer;
    box-sizing: border-box;
    text-transform: capitalize;
}

.flag-review-item {
    padding-right: 40px !important;
    position: relative;

}

.submit-loading-btn>button:hover {
    background-color: #00B4E5;
}

.submit-loading-btn>button>.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}

.pointer {
    cursor: pointer;
}

/*Sidebar*/
.new-sys-sidebar {
    width: 230px;
    position: fixed;
    top: 0;
    height: 0;
}

.sidebar-child-sub {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #A9BFC7;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}

.toggle-nav {
    margin-right: 15px;
    cursor: pointer;
    display: none;
}

.review-notfound {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
}

.review-notfound p {
    color: #1D3954;
    font-family: "Poppins-Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sidebar-main-elements {
    background: #173151;
    min-height: calc(100vh - 50px);
    width: 100%;
    overflow: hidden;
    position: relative;
}

.parent-ul-container {
    /* height: calc(100vh - 300px); */
    height: calc(100vh - 221px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-left: 0px;
    margin: 0px;
    scroll-behavior: smooth;
    transition: scroll-behavior 0.5s ease-in-out;
}

.parent-ul-container>li {
    font-family: 'Poppins-Light';
    font-style: normal;
    list-style: none;
    font-weight: 300;
    font-size: 0.8750em;
    position: relative;
    color: #DDDDDD;
}

.parent-ul-container>li>div {
    width: 100%;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.parent-ul-container>li>div>span {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
}

/* .tss-oeo5eo-MUIDataTableToolbar-filterPaper {
    max-width: 70%!important;
    margin-top: 25px;
    right: 42px;
    left: unset!important;
} */
.new-sidebar-top {
    background: #173D60;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin 0.5s ease-in-out;
    min-height: 61px;
    box-sizing: border-box;
}

.new-sidebar-top>a>img {
    width: 180px;
    object-fit: contain;
}

.new-icon-sidebar {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
}

.parent-ul-container>li>a {
    display: flex !important;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    padding: 0.5em 1em;
}

.parent-ul-container>li.show .navlink-withchildrens>svg {
    color: #00B4E5;
    transform: rotate(90deg);
}

.navlink-withchildrens>svg {
    position: absolute;
    right: 10px;
}

.parent-ul-container>li>a>i {
    font-size: 5px;
    margin-left: auto;
    transform: rotate(270deg);
}

.parent-ul-container>li.show-childs>a>i {
    color: #00B4E5;
    transform: rotate(0deg);
}

.child-ul-container {
    padding-bottom: 13px;
}

.child-ul-container li {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 0.9750em;
    list-style: none;
    position: relative;
    text-decoration: none;
}

.child-ul-container li a.active {
    background-color: rgba(24, 174, 227, 0.1);
}

.child-li-item.active::after {
    content: "";
    height: 100%;
    width: 3px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 7px;
    left: 1px;
}

.child-ul-container li a {
    color: #FFFFFF;
    padding-left: 56px !important;
}

.child-ul-container span {
    font-family: 'Poppins-Regular';
    padding-left: 34px;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #A9BFC7;
    margin-bottom: 8px;
    margin-top: 8px;
    display: block;
}

.parent-ul-container>li.active {
    background-color: rgba(24, 174, 227, 0.1);
    position: relative;
}

.parent-ul-container>li.active::before {
    content: "";
    border-left: 3px solid #00B4E5;
    border-radius: 7px;
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
}

.child-ul-container {
    height: 0px;
    padding-bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease-out;
    transform-origin: top left;
    padding-left: 0px;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.parent-ul-container>li.show-childs>ul {
    padding-bottom: 13px;
    height: fit-content;
    opacity: 1;
    visibility: visible;
    transition: all .5s linear;
}

.parent-li-item.show .child-ul-container {
    padding-bottom: 13px;
    height: fit-content;
    opacity: 1;
    visibility: visible;
    transition: all .5s linear;
}

.child-ul-container li a::before {
    content: "";
    height: 100%;
    width: 1px;
    background-color: #fff;
    opacity: 0.07;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 33px;
    box-sizing: border-box;
}

.child-ul-container li>a {
    padding-left: 34px;
    text-decoration: none;
    display: block;
    padding: 0.7em 1em;
    box-sizing: border-box;
}

.switch-div-container>div {
    margin-bottom: 15px;
    padding-top: 10px
}

.switch-div-container>div>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    --webkit-justify-content: center;
    margin-bottom: 7px;
}

.switch-div-container>div>p {
    font-family: "Poppins-Light";
    color: #fff;
    font-size: 0.7em;
    margin: 0px;
}

.switch-div-container>div>h6 {
    font-family: "Poppins-Bold";
    color: #fff;
    text-transform: uppercase;
    font-size: 0.7em;
    margin: 0px;
}

.switch-div-container>div>div>a:first-child>img {
    margin-right: 12px;
}

.switch-div-container {
    width: 100%;
    text-align: center;
    position: relative;
    bottom: -30px;
    left: 0;
    right: 0;
    padding-top: 5px;
    background-color: #173151;
}

.App.banner .switch-div-container {
    bottom: 10px;
}

.switch-div-container span {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #A9BFC7;
    margin-bottom: 0;
    margin-top: 0;
    margin-bottom: 10px;
    display: block;
}

.switch-div-container>a {
    display: block;
    width: 100%;
    width: 90%;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    color: #00B2E3;
    font-size: 14px;
    text-decoration: none;
    font-family: "Poppins-Regular";
    background-color: #00b2e32b;
    border-radius: 4px;
    text-align: center;
}

.new-sidebar-workspace h4 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 0.8750em;
    color: #FFFFFF;
    margin-bottom: 2px;
    line-height: 0px;
}

.new-sidebar-workspace span {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #A9BFC7;
}

.new-sidebar-workspace-info {
    background-color: rgb(23, 72, 109);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 15px 10px 5px 10px;
    cursor: pointer;
}

.new-sidebar-workspace-img {
    background: #173D60;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
}

.new-sidebar-workspace {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.new-sidebar-workspace-img img {
    padding: 6px 10px;
}

.toggle-sidebar-icon {
    cursor: pointer;
    display: none;
}

.parent-ul-container {
    scrollbar-color: rgb(26 81 123) #173151;
    scrollbar-width: thin;
}

.parent-ul-container::-webkit-scrollbar {
    width: 7px;
}

.parent-ul-container::-webkit-scrollbar-track {
    background: #173151;
}

/* Handle */
.parent-ul-container::-webkit-scrollbar-thumb {
    background: rgb(26 81 123);
    border-radius: 7px;
}

/*end of sidebar*/


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.search-user-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 3%);
    padding: 0.3125em 1.5625em;
    margin-left: 230px;
    z-index: 5001;
    transition: margin-top 0.5s ease-in-out;
}

.search-user-container {
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-acc-info {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.user-image-holder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #00B4E5;
    display: inline-block;
}

.user-image-holder>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.user-name-holder {
    padding-right: 10px;
    padding-left: 15px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8750em;
    color: #173151;
}

.user-arrow {
    display: flex;
    align-items: center;
    color: #C9CCCE;
}

.notifications_wrap_container {
    position: absolute;
    width: 362px;
    box-shadow: 0px 8px 24px 0px #959DA533;
    right: 14px;
    margin: auto;
    background: #fff;
    top: 65px;
}

.notifications_wrap_title {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    padding: 12px 18px 7px 18px;
}

.notifications_wrap_title h5 {
    margin: 0;
    font-size: 14px;
    font-family: "Poppins-Regular";
    font-weight: 600;
    color: #252626;
}

.notifications_wrap_title>button {
    background: transparent;
    border: 0;
    color: #00B2E3;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins-Regular";
    font-weight: 500;
}

.notifications_wrap_tabs {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.notifications_wrap_list>div>p {
    padding: 5px;
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #000000A6;
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    justify-content: center;
}

.notifications_wrap_tabs>button {
    background: transparent;
    border: 0px;
    color: #0c2640cc;
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 500;
    flex: 1 1;
    text-align: left;
    cursor: pointer;
    padding-left: 37px;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 6px;
    padding-bottom: 6px;
}

.notifications_wrap_tabs>button.active {
    color: #0C2640;
    font-weight: 600;
}

.notifications_wrap_tabs>button.active::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #00B2E3;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
}

.notifications_wrap_tabs>button::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #EFEFEF;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
}

.notifications_wrap_tabs>button.active>span {
    background-color: #00B2E3;
    color: #fff;
}

.notifications_wrap_tabs>button>span {
    padding: 1px 5px;
    border: 1px solid #dedbdb;
    border-radius: 4px;
    color: #0c264087;
    font-size: 9px;
    font-family: "Poppins-Regular";
    font-weight: 500;
    margin-left: 5px;
}

.notifications_wrap_bottom {
    padding: 3px 20px;
    text-align: right;
    border-top: 1px solid #EFEFEF;
    min-height: 30px;
}

.notifications_wrap_bottom>button {
    background: transparent;
    border: 0px;
    padding: 5px;
    font-size: 12px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #000000A6;
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
}

.notifications_wrap_bottom>button>svg {
    color: #00B2E3;
    margin-right: 5px;
    font-size: 19px;
}

.navbar-right-side {
    display: flex;
    align-items: flex-end;
}

.video-holder-div {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-top: 3px;
}

.video-holder-div h5 {
    color: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 12px;
    margin: 0;
    margin-left: 6px;
    white-space: nowrap;
}

.admin-notification-holder,
.platforms-holder {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
}

.platforms-holder {
    position: relative;
}

.adm-notification-number {
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    line-height: 19px;
    background: #00B4E5;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7500em;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    left: 6px;
    top: -9px;
    padding: 2px;
}

.admin-notification-holder .MuiBadge-root {
    vertical-align: unset;
}

.admin-notification-holder .MuiBadge-root span {
    color: #fff;
    background-color: #00B4E5;
}

.chart-info-wrap {
    padding-left: 235px;
}

.adm-notification-number-skeleton {
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    line-height: 19px;
    background: #ced4da;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7500em;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    left: 6px;
    top: -9px;
    padding: 2px;
}

.searchbox-adm-icon::before {
    content: url("../../public/admin-icons/new_search_nav.svg");
    position: absolute;
    top: 5px;
    left: 10px;
    cursor: pointer;
}

.search-input-holder {
    position: relative;
    width: 50px;
    -webkit-transition: width 0.3s;
}

.searchbox-adm-input {
    border: 0;
    outline: 0;
    width: 0;
    height: 33px;
    margin: 0;
    padding: 0px;
    font-size: 1em;
    color: #0c2640;
    background-color: transparent;
    background: #FFFFFF;
    border-radius: 5px;
    text-overflow: ellipsis;
}

.search-input-holder.open {
    width: 250px;
    margin-right: 20px;
}

.search-input-holder.open .searchbox-adm-input {
    border: 1px solid rgba(37, 49, 60, 0.12);
    width: 100%;
    padding: 0 20px 0 40px !important;
}

.not-connected-filter {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.6;
    filter: alpha(opacity=60);
}

.search-input-holder.open .searchbox-adm-input::placeholder {
    font-size: 14px;
}

.searchbox-adm-results {
    position: absolute;
    width: 100%;
    min-height: 290px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(37 49 60 / 20%);
    border-radius: 10px;
    margin-top: 36px;
    margin-top: 10px;
    left: 0px;
    padding: 20px 0px 15px 0px;
    z-index: 9999;
    transition: all 0.5s ease;
}

.searchbox-adm-results h6 {
    padding-left: 15px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.8500em;
    line-height: 18px;
    font-family: "Poppins-Regular";
    color: rgba(37, 49, 60, 0.3);
    margin: 0px;
}

.searchbox-adm-results ul {
    margin: 0px;
    padding-left: 0px;
}

.searchbox-adm-results ul li {
    list-style: none;
}

/* 
.user-acc-container {
    position: relative;
} */

.searchbox-adm-results ul li a {
    color: #0c2640;
    font-size: 0.8750em;
    line-height: 18px;
    text-decoration: none;
    display: block;
    font-family: "Poppins-Regular";
    padding: 10px 20px;
}

.searchbox-adm-results ul li a:hover {
    background-color: #00b3e509;
}

.search-general {
    font-family: "Poppins-Regular";
    padding: 10px 15px;
    color: #0c2640;
    font-size: 0.8750em;
}

.user-acc-details {
    position: absolute;
    min-height: 260px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgb(176 183 195 / 49%);
    border-radius: 10px;
    margin-top: 30px;
    right: 25px;
    padding: 16px 20px 30px 20px;
    z-index: 9999;
    width: fit-content;
    max-width: 325px;
    font-family: "Poppins";
}

.user-acc-details-profile {
    display: flex;
    align-items: center;
    padding: 17px 19px;
    cursor: pointer;
}

.user-acc-details-profile h6 {
    margin: 0px;
    font-size: 0.8750em;
    line-height: 1em;
    font-weight: 600;
    color: #0c2640;
    font-family: "Poppins-Regular";
    opacity: 0.8;
    width: 190px;
    word-wrap: break-word;
    white-space: normal;
    display: block;
}

.user-acc-details-profile .user-image-holder {
    margin-right: 0.8750em;
}

.user-acc-details-profile span {
    font-size: 0.8500em;
    margin-top: 0px;
    position: relative;
    top: 3px;
    width: 190px;
    word-wrap: break-word;
    color: #0c2640;
    opacity: 0.4;
    font-family: "Poppins-Regular";
    white-space: normal;
}

.user-acc-details-img {
    width: 41px;
    height: 41px;
    border-radius: 100%;
    margin-right: 0.8750em;
    flex-shrink: 0;
}

.user-acc-details-img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.user-acc-details-options>h3 {
    color: #adb5bd;
    font-size: 0.8500em;
    font-weight: 400;
    font-family: "Poppins-Regular";
    margin: 0 0 10px 0;
}

.logout-div {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
    background-color: #f8f8f8ab;
    color: #fff;
    font-family: 'Poppins-Regular';
    flex-direction: column;
    font-size: 1.5em;
}

.logout-div img {
    width: 400px;
}

.user-acc-details-options>a,
.user-acc-details-logout>a {
    text-decoration: none;
    display: flex;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 0px;
    padding-top: 9px;
    align-items: center;
}

.user-acc-details-logout {
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 0px;
    padding-top: 9px;
    cursor: pointer;
}

.user-acc-details-logout:hover {
    background-color: #f8f9fa;
}

.user-acc-details-logout>button {
    text-decoration: none;
    display: flex;
    padding-bottom: 8px;
    padding-left: 18px;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 9px;
    align-items: center;
    background-color: transparent;
    border: 0px;
    outline: none;
    padding: 0;
}

.user-acc-details-logout>button>h6 {
    font-size: 13.5px;
    color: #0c2640;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 0px;
    padding-top: 9px;
    margin: 0px;
    font-family: "Poppins-Regular";
}

.user-acc-details-options>a:hover,
.user-acc-details-logout>a:hover {
    background-color: #f8f9fa;
}

.user-acc-details-options>a>h6,
.user-acc-details-logout>a>h6 {
    font-size: 13.5px;
    color: #0c2640;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 0px;
    padding-top: 9px;
    margin: 0px;
    font-family: "Poppins-Regular";
}

.user-acc-details-options {
    border-bottom: 1px solid #e9ecef;
    padding-bottom: 3px;
    margin-bottom: 3px;
}

#search-select {
    padding-left: 42px;
}

.search-user-container .MuiInputBase-root::before {
    content: url("../../public/admin-icons/search-icon.svg");
    position: absolute;
    left: 8px;
    z-index: 1;
    background: #E6EAED;
    padding: 6px;
    border-radius: 100%;
    line-height: 0;
}

.search-user-container .MuiInputBase-root svg {
    color: #C5C9CC !important;
    font-size: 25px;
}

.balance-wrapper span {
    display: block;
}

.modal-wallet-top>span {
    outline: 0;
    border: 0px;
    width: 38px;
    height: 38px;
    background: rgba(24, 174, 227, 0.1);
    border-radius: 100%;
    color: #18AEE3;
    opacity: 1;
    display: flex;
    align-items: center;
    position: absolute;
    right: 45px;
    cursor: pointer;
    justify-content: center;
}

.modal-wallet-top,
.modal-wallet-header {
    border-bottom: 0.5px solid #EAEAEA;
}

.modal-wallet-bottom {
    border-top: 0.5px solid #EAEAEA;
}

.modal-wallet-body {
    padding: 20px;
}

.modal-wallet-top {
    display: flex;
    padding: 35px 20px;
    align-items: center;
    justify-content: center;
    --webkit-justify-content: center;
    --align-items-center: center;
}

.wallet-body-box,
.wallet-body-box-custom {
    background: #FFFFFF;
    border: 1px solid rgba(166, 178, 205, 0.2);
    border-radius: 7px;
    padding: 20px;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    transition: 0.3s;
}

.modal-wallet-summary {
    padding: 20px;
}

.modal-wallet-summary h2 {
    font-family: 'Poppins-Bold';
    font-size: 24px;
    line-height: 36px;
    color: #103254;
    margin-bottom: 35px;
}

.wallet-box-summary {
    background: #FFFFFF;
    border: 1px solid rgba(166, 178, 205, 0.2);
    border-radius: 7px;
    padding: 10px;
    text-align: center;
    transition: 0.3s;
}

.wallet-box-summary>div>span {
    width: 47px;
    height: 47px;
    margin-right: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-box-summary-added>div>span {
    background: rgba(45, 206, 249, 0.1);
}

.wallet-box-summary-spent>div>span {
    background: rgba(249, 181, 28, 0.1);
}

.wallet-box-summary-average>div>span {
    background: rgba(255, 100, 113, 0.1);
}

.wallet-box-summary>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.wallet-box-summary>div>h4 {
    margin: 0px;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 29px;
    color: #103254;
}

.wallet-box-summary h6 {
    margin: 0px;
    font-family: 'Poppins-Semibold';
    font-size: 24px;
    line-height: 29px;
    color: #103254;
}

.wallet-box-summary {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between
}

.wallet-body-box span {
    font-family: 'Poppins-Semibold';
    font-size: 40px;
    color: #103254;
    margin-bottom: 0px;
    margin-top: 10px;
    display: block;
}

.wallet-body-box-custom small {
    margin-top: 15px;
}

.wallet-body-box-custom span {
    font-family: 'Poppins-Semibold';
    font-size: 40px;
    color: #103254;
    margin-bottom: 0px;
    display: block;
}

.wallet-body-box button,
.wallet-body-box-custom button {
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 18px 0px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #18527D;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
}

.wallet-body-box:hover button {
    background-color: #18AEE3;
    color: #fff;
}

.wallet-body-box-custom:hover button {
    background-color: #FFBE1D;
    color: #fff;
}

.wallet-body-box-custom>div {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(24, 82, 125, 0.1);
    border-radius: 10px;
    padding: 10px 30px;
    line-height: 60px;
    height: 75px;
    box-sizing: border-box;
    color: #103254;
}

.wallet-body-box-custom>div>input {
    width: 100%;
    height: 100%;
    font-family: 'Poppins-Semibold';
    font-size: 40px;
    border: 0px;
    color: #103254;
    /* padding-left: 10px; */
}

.wallet-body-box-custom>div>input::-webkit-input-placeholder {
    color: #103254;
}

.wallet-body-box:hover,
.wallet-body-box-custom:hover {
    border-color: #18AEE3;
}

.wallet-body-box small,
.wallet-body-box-custom small {
    font-family: 'Poppins-Medium';
    font-size: 14px;
    display: block;
    color: #18527D;
    margin-bottom: 30px;
}

.modal-wallet-header-left h3 {
    margin-top: 0px;
    font-family: 'Poppins-Bold';
    font-size: 35px;
    line-height: 52px;
    color: #103254;
    margin-bottom: 3px;
    text-align: left;
}

.modal-wallet-header-left p {
    font-family: "Poppins-Regular";
    color: #212529;
    margin-bottom: 0px;
    margin-top: 0px;
}

.modal-wallet-header-left p span {
    color: #18AEE3;
    font-family: 'Poppins-Bold';
}

.modal-wallet-header-right>span {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    display: block;
    color: #18AEE3;
}

.modal-wallet-header-right>h4 {
    font-family: 'Poppins-Medium';
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 3px;
    font-weight: 600;
    margin-top: 3px;
    color: #103254;
}

.modal-wallet-content {
    height: calc(100% - 316px);
    overflow: auto;
}

.modal-wallet-header {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --align-items-center: center;
}

.modal-wallet-container {
    background: #ffff;
    width: 80vw;
    margin: auto;
    position: absolute;
    height: 92vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    border-radius: 5px;
    outline: 0;
}

.modal-wallet-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    --webkit-justify-content: center;
}

.modal-wallet-bottom p {
    font-size: 14px;
    color: #8F9BBA;
    font-family: "Poppins-Medium";
    margin: 0px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.modal-wallet-bottom p img {
    margin-right: 5px;
}

.wallet-container,
.wallet-wrapper {
    align-items: center;
    display: flex;
    --webkit-align-items: center;
}

.wallet-wrapper>div>small {
    display: block;
    font-size: 8px;
    font-family: "Poppins-Light";
    color: #898F92;
}

.wallet-wrapper {
    cursor: pointer;
    background: rgba(24, 174, 227, 0.08);
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 7px;
    margin-left: 16px;
    margin-right: 16px;
}

.balance-wrapper small {
    display: block;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    text-align: right;
    color: #173151;
    opacity: 0.8;
}

.balance-wrapper {
    margin-left: 10px;
}

.balance-wrapper span {
    color: #173151;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    white-space: nowrap;
}

.wallet-wrapper>div {
    line-height: 11px;
}

.wallet-wrapper button {
    padding: 0;
}

.wallet-wrapper button:hover {
    background-color: transparent;
}

.wallet-wrapper svg {
    color: #00B2E3;
    font-size: 23px;
    margin-left: 15px;
}

.wallet-wrapper span {
    margin-bottom: 0;
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    color: #444545;
    font-family: "Poppins-Bold";
}

.main-container {
    margin-left: 230px;
    min-height: calc(100vh - 130px);
    margin-top: 50px;
    padding: 1.5625em;
    transition: margin-top 0.5s ease-in-out;
    position: relative;
    background-color: #fbfafa;
}

.save-btn .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #00B4E5;
}

.close-btn .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #CE383A;
}

.save-btn-confirm .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}

.save-btn-confirm .MuiLoadingButton-loadingIndicator span {
    width: 10px !important;
    height: 10px !important;
    margin: 0px !important;
}

.save-btn-white .MuiLoadingButton-loadingIndicator {
    color: #fff !important;
}

.connection-discard .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #00B4E5;
}

.connection-discard {
    text-transform: capitalize !important;
}

.loader-white-bg .MuiBox-root {
    background: rgba(255, 255, 255, .6);
    z-index: 1024 !important;
}

.loader-container-adm .MuiBox-root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins-Regular";
    align-items: center;
    --webkit-justify-content: center;
}

/* NOTIFICATION */
.custom-modal-dialog {
    height: 100vh;
    position: fixed;
    right: 0;
    outline: 0;
    background: #fff;
    width: 555px;
    max-width: 80%;
}

.notification-mui-tab-container {
    padding: 25px;
    border-bottom: 1px solid rgba(37, 49, 60, 0.1);
    display: flex;
    align-items: center;
}

.notification-mui-tab-container>button {
    background: transparent;
    border: 0px;
    font-weight: normal;
    font-size: 10px;
    line-height: 25px;
    border: none;
    color: #0c2640;
    cursor: pointer;
    padding: 10px 0px;
}

.notification-mui-tab button {
    padding: 8px 15px;
    background: #FFFFFF;
    border: 1px solid #00B4E5;
    border-radius: 3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    margin-right: 20px;
    color: #00B4E5;
    text-transform: capitalize;
}

.notification-mui-tab button.Mui-selected {
    background-color: #00B4E5;
    color: #fff;
}

.load_more_noty_btn:hover {
    cursor: pointer;
}

.notification-mui-tab .MuiTabs-indicator {
    display: none;
}

.notification-mui-tab-container .close-modal {
    color: #0c2640;
    font-weight: 200;
    margin-left: auto;
    font-size: 17px;
    cursor: pointer;
}

.custom-modal-footer {
    padding: 25px;
}

.custom-modal-header {
    padding: 20px;
    border-bottom: 1px solid rgba(37, 49, 60, 0.1);
}

.custom-modal-header>h5 {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 18px;
    font-weight: 400;
    color: #201C1C;
}

.custom-modal-footer button.close-btn {
    font-family: 'Poppins-Regular';
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    color: #CE383A;
    padding: 8px 15px;
    background: rgba(206, 56, 58, 0.1);
    border-radius: 3px;
    border: none;
    float: right;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: 10px;
    transition: all 0.3s;
}

.custom-modal-footer button.close-btn:hover {
    background: #CE383A;
    color: #fff;
}

.custom-modal-footer button.save-btn,
.load_more_noty_btn {
    font-family: 'Poppins-Regular';
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    color: #18AEE3;
    padding: 8px 15px;
    background: rgba(24, 174, 227, 0.1);
    border-radius: 3px;
    border: none;
    float: right;
    cursor: pointer;
    box-sizing: border-box;
    text-transform: capitalize;
    transition: all 0.3s;
}

.custom-modal-footer button.save-btn:hover {
    background: #18AEE3;
    color: #fff;
}

.save-button-confirm {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: #00B4E5;
    padding: 8px 15px;
    background: rgba(15, 119, 151, 0.1);
    border-radius: 5px;
    border: none;
    float: right;
    cursor: pointer;
    box-sizing: border-box;
}

.max-height-noty {
    overflow-y: auto;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.noty-item {
    position: relative;
    border-bottom: 1px solid rgba(37, 49, 60, 0.1);
    padding: 16px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.2s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.noty-item h4 {
    font-weight: normal;
    font-size: 0.8em;
    line-height: 21px;
    color: #0c2640;
    font-family: "Poppins-Regular";
    margin: 0px;
}

.noty-item a {
    width: 70%;
    text-decoration: none;
}

.div-container-70 {
    width: 70%;
}

.noty-item p {
    font-weight: normal;
    font-size: 0.7500em;
    line-height: 18px;
    color: #0c2640;
    opacity: 0.7;
    font-family: "Poppins-Regular";
    margin-top: 3px;
    margin-bottom: 0px;
}

.label-adm-info {
    font-weight: normal;
    font-size: 10px;
    opacity: 0.5;
    line-height: 15px;
}

.right-description-notification .label-adm-info {
    display: inline-block;
}

.right-description-notification {
    text-align: right;
    position: absolute;
    right: 25px;
    top: 30px;
    color: #0c2640;
    display: flex;
    font-family: "Poppins-Medium";
}

.right-description-notification>svg {
    display: inline-block;
    color: #0c2640;
    margin-left: 20px;
    position: relative;
    top: 5px;
    cursor: pointer;
    opacity: 1;
    font-family: 'Poppins-Medium';
    font-size: 18px;
}

.right-description-notification>div {
    margin-left: 10px;
}

.right-description-notification>div>svg {
    position: relative;
    bottom: 2px;
    cursor: pointer;
}

.adm-edit-buttons {
    position: absolute;
    background: #F5F5F5;
    border-radius: 7px;
    align-items: center;
    padding: 5px;
    height: fit-content;
    background: #00B4E5;
    display: flex;
    align-items: center;
    right: -17px;
    box-shadow: 0px 0px 10px rgb(24 174 227 / 50%);
}

.adm-edit-buttons svg {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.adm-edit-buttons svg:first-child {
    margin-right: 5px;
    font-size: 20px;
}

.adm-edit-buttons:before {
    content: "";
    position: absolute;
    right: 0;
    top: -11px;
    width: 0;
    height: 0;
    border-top: 6px solid #00B4E5;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transform: rotate(180deg);
    left: 0;
    margin: auto;
}

.custom-modal-content {
    border-bottom: 1px solid rgba(37, 49, 60, 0.1);
}

.tag-color {
    display: block;
    height: 25px;
    width: 25px;
    border-radius: 100%;
}

.flex-header-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 5px;
    --webkit-align-items: flex-end;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.flex-header-container-title h1 {
    margin: 0px;
    font-family: "Poppins-Light";
    font-size: 1.2em;
    color: #0c2640;
}

.flex-header-container-title h6 {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 0.8em;
    color: #0c2640;
    font-weight: bold;
    font-size: 18px;
}

.header-sec-row {
    color: #25313C80;
    font-size: 14px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    margin: 0;
}

.dashboard-container-header .flex-header-container-title h6 {
    font-size: 18px !important;
}

.loading-spinner-dashboard {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.loading-spinner-dashboard p {
    font-family: "Poppins-Regular";
    font-size: 14px;
}

.flex-header-actions {
    display: flex;
    align-items: flex-end;
    --webkit-align-items: flex-end;
}

.flex-header-actions>button,
.competitor-export {
    border: 0px;
    color: #00B4E5 !important;
    padding: 10px 20px;
    background: rgba(0, 178, 227, 0.1) !important;
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-size: 14px;
    font-family: "Poppins-Regular";
}

.reviews-header-actions {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.reviews-header-actions>button>svg {
    margin-right: 5px;
}

.text-center {
    text-align: center;
}

.reviews-header-actions>button {
    color: #00B4E5;
    padding: 10px 20px;
    background: rgba(0, 178, 227, 0.1);
    font-size: 14px;
    font-family: "Poppins-Regular";
    display: flex;
    align-items: center;
    line-height: 20px;
    --webkit-align-items: center;
}

.competitor-export:disabled {
    opacity: 0.5;
    cursor: initial;
}

.link-asbtn {
    font-family: "Poppins-Regular";
    border: 0px;
    color: #00B4E5;
    padding: 10px 20px;
    background: rgba(0, 178, 227, 0.1);
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-size: 14px;
    text-decoration: none;
}

.design-wrapper-right-side>a {
    text-decoration: none;
    background-color: #00B2E3;
    color: #fff;
    font-size: 14px;
    font-family: "Poppins-Regular";
    padding: 10px 22px;
    border-radius: 3px;
    border: 0px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    margin-top: 32px;
    display: block;
    text-align: center;
}

.noty-item.unread {
    background: #D1F3FF;
}

.flex-header-actions>button:first-child {
    margin-left: 0px;
}

.flex-header-actions>button>svg {
    font-size: 17px;
    margin-right: 11px;
    padding-bottom: 2px;
}

.box-mt {
    margin-top: 10px !important;
    margin-bottom: 20px;
}

.box-containers {
    margin-top: 5px !important;
    margin-bottom: 15px;
}

.box-containers>div>div:not(.upgrade-box-container) {
    min-height: 140px;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    background: #fff;
    display: flex;
    box-shadow: 0px 0px 0px 1px #0000000D;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
    position: relative;
    border-radius: 9px;
}

.box-containers>div>div>h6 {
    margin: 0px;
    font-family: "Poppins-Light";
    font-size: 0.8em;
    color: #0c2640;
    font-weight: 300;
}

.box-containers>div>div>span {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 2.5em;
    color: #00B4E5;
}

.tooltip-btn-holder {
    position: absolute !important;
    right: 0;
    top: 0;
}
.tooltip-btn-holder > svg{
    font-size: 14px!important;
}
.filter-container {
    margin-top: 20px !important;
    margin-bottom: 20px;
}

.filter-container input:not(.rdrInputRangeInput) {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    outline: 0px;
    font-family: "Poppins-Regular";
    font-size: 0.8em;
    border: 1px solid #ced4da;
}

.date-range-container>div:first-child {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    background-color: rgb(239, 242, 247);
    height: 41px;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px 0.8750em;
    box-sizing: border-box;
    font-family: "Poppins-Regular";
    cursor: pointer;
    font-size: 0.8500em;
    column-gap: 0.8750em;
    text-align: center;
    border: 1px solid;
    border-color: #ced4da;
}

.date-range-container {
    position: relative;
}

.datepicker-container {
    position: absolute;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 16%);
    z-index: 1055;
    top: 45px;
    right: 0;
    font-family: "Poppins-Regular";
}

.datepicker-container .rdrDateDisplayItem {
    border: 0px;
    box-shadow: unset;
}

.date-range-container>div:first-child>div {
    background-color: #fff;
    padding: 4px 4px;
    border-radius: 2px;
    width: 100%;
    font-size: 0.8500em;
    text-transform: capitalize;
}

/* .filter-container > div {
    flex: 1 0 19%;
} */
.custom-autocomplete fieldset {
    border-color: #e5e5e5 !important;
    border-width: 1px !important;
    border-radius: 4px;
}

.custom-autocomplete input {
    color: #979797;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    padding: 0;
}
.widget_code_content .choose-loc-text {
    padding-top: 25px;
}
.custom-autocomplete .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00B4E5 !important;

}

.filter-manage-tags {
    display: flex;
    /* align-items: center; */
    /* flex-wrap: wrap; */
    --webkit-align-items: center;
    overflow-x: auto;
    position: relative;
}

.filter-manage-tags.open {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    --webkit-align-items: center;
}

.filter-manage-tags.open .all-tags-filter {
    height: 33px;
    border-right: 1px solid #E4E4E7;
    margin-right: 10px;
}

.expand_tags_btn {
    border: 1px solid;
    font-family: "Poppins-Regular";
    color: #18AEE3;
    background-color: #fff;
    border-color: #18AEE3;
    border-radius: 50px;
    font-size: 11px;
    padding: 7px 10px;
    margin-right: 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    background-color: #F0F8FA;
}

.expand_tags_btn::after {
    content: "";
    height: 100%;
    background: #E4E4E7;
    width: 1px;
    position: absolute;
    right: -8px;
}

.manage-review-btn-arrow {
    /* position: absolute; */
    /* top: 0;
    right: -4px; */
    background: none;
    border: none;
    font-size: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    height: 33px;
    justify-content: flex-end;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47942927170868344) 25%, rgba(255, 255, 255, 1) 100%);
    cursor: pointer;
    z-index: 2;
}

.manage-review-btn-arrow.left {
    transform: rotate(180deg);
}

.manage-tags-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 88px;
    right: 0;
}

.filter-manage-tags::-webkit-scrollbar {
    display: none;
}

.tags-keywords-table {
    font-style: italic;
    font-family: "Poppins-Regular";
    color: #000;
    margin: 0;
}

.filter-manage-tags input[type="checkbox"],
.all-tags-filter input[type="checkbox"] {
    display: none;
}

.filter-manage-tags {
    margin: 0;
}

.filter-manage-tags label,
.all-tags-filter label {
    border: 1px solid;
    font-family: "Poppins-Regular";
    color: "#000";
    background-color: #fff;
    border-color: #000;
    border-radius: 50px;
    font-size: 11px;
    padding: 7px 10px;
    margin-right: 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 10px;
    cursor: pointer;
}

.filter-manage-tags label>svg,
.all-tags-filter label>svg {
    margin-right: 5px;
    color: #0089af;
    font-size: 16px;
}

.alert-inside-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.alert-inside-page {
    z-index: 9999;
    position: fixed;
    top: 50px;
    /* top: 0; */
    right: 0;
    left: 230px;
}

.App.banner .alert-inside-page {
    top: 87px;
}

.review-info-holder>div>label,
.header-review-wrapper label {
    border: 1px solid;
    font-family: "Poppins-Regular";
    color: "#000";
    background-color: #fff;
    border-color: #000;
    border-radius: 50px;
    font-size: 11px;
    padding: 4px 7px;
    margin-right: 3px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 10px;
}

.header-review-wrapper label {
    margin-bottom: 0;
}

.header-review-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.review-item-container-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    --webkit-align-items: flex-start;
    --webkit-justify-content: space-between;
    padding: 20px;
}

.review-item-container {
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%); */
    position: relative;
}

.review-item-container-user {
    display: flex;
    align-items: flex-start;
    --webkit-align-items: flex-start;
}

.review-info-holder>div>h4 span {
    color: #00B4E5;
}

.review-info-holder {
    display: flex;
    align-items: flex-start;
    --webkit-align-items: flex-start;
}

.dashboard-rating-wrapper p {
    color: #25313C80;
    font-size: 12px;
    font-family: "Poppins-Regular";
    margin: 0;
    top: -11px;
    position: relative;
}

.dashboard-rating-wrapper {
    padding: 8px 33px 8px 0;
    position: relative;
}

.dashboard-rating-wrapper::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: block;
    background: #54545436
}

/* 
.dashboard-review-wrapper>div {
    background: url("../../public/admin-icons/light-star.svg");
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 85%;
}

.dashboard-review-wrapper>div:last-child {
    background: url("../../public/admin-icons/light-irevu.svg");
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 85%;
} */

.dashboard-review-wrapper {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.dashboard-review-wrapper>div {
    flex: 1 1;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.dashboard-review-wrapper>div::after {
    content: "";
    width: 1px;
    background-color: #54545436;
    position: absolute;
    height: 108px;
    right: 0;
}

.review-info-holder>div {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 52vw;
    --webkit-align-items: flex-start;
}

.dashboard-review-wrapper>div>span {
    color: #464E5F;
    font-size: 32px;
    font-family: 'Poppins-Bold';
    font-weight: 600;
    display: block;
    line-height: 1;
    width: fit-content;
    position: relative;
}

.rating-with-emoji {
    white-space: nowrap;
    margin-top: 7px;
}

.rating-with-emoji>span {
    font-family: 'Poppins-Medium';
    color: #25313C;
    margin-right: 12px;
}

.dashboard-review-wrapper>div>p {
    color: #464E5F;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
}

.dashboard-rating-info {
    display: flex;
    align-items: strech;
    --webkit-align-items: strech;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 23px;
    flex-wrap: wrap;
}

.dashboard-rating-info>div {
    flex: 1;
}

.rating-with-emoji>div {
    display: inline-block;
    position: relative;
    margin-left: 5px;
}

.rating-with-emoji>div:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 3px;
    background-color: #54545412;
    border-radius: 5px;
    width: 1px;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
}

.rating-with-emoji>div>img {
    margin-left: 12px;
}

.review-item-container-user>img {
    margin-right: 20px;
    max-width: 40px;
}

.review-item-container-user {
    font-family: "Poppins-Regular";
    color: #0c2640;
    font-size: 0.8750em;
    font-weight: 400;
}

.review-item-container-user h4 {
    font-family: 'Poppins-Semibold';
    font-size: 16px;
    line-height: 24px;
    color: #25313C;
    margin-top: 0px;
    margin-bottom: 0;
    margin-right: 10px;
}

.review-item-container-user .span-date {
    font-size: 12px;
    line-height: 18px;
    color: #25313C99;
}

.review-item-container-user h4>a {
    color: #00B4E5;
    text-decoration: none;
    margin-left: 3px;
    display: inline-block;
}

.flex-start-loader .MuiBox-root {
    align-items: flex-start !important;
    --webkit-align-items: flex-start !important;
}

.flex-start-loader .MuiBox-root .load8 {
    margin-top: 40vh;
}

.review-item-wrapper {
    position: relative;
    position: relative;
    display: flex;
    align-items: flex-start;
}

.review-item-list-container {
    position: relative;
}

.review-item-wrapper>input {
    display: none;
}

.selected-reviews-wrapper {
    position: fixed;
    bottom: 25px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 250px;
    right: 25px;
    background: #00B2E3;
    border-radius: 8px;
    padding: 12px 20px 12px 65px;
}

.selected-reviews-wrapper>h5 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
    text-transform: capitalize;
    color: #ffff;
}

.button-disabled-connections {
    color: #6c757d;
    font-family: "Poppins-Regular";
    background-color: #fff;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 3px;
    outline: 0px;
    border: 1px solid #dee2e6;
    cursor: initial;
    margin-left: 10px;
    line-height: 29px;
    text-decoration: none;
    min-width: 90px;
    display: flex;
    align-items: center;
}

.button-disabled-connections>svg {
    padding-right: 6px;
}

.button-disabled:disabled {
    opacity: 0.5;
    cursor: initial;
}

.selected-reviews-wrapper>div>button:first-child {
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 8px 8px 16px;
    border: 0px;
    outline: 0;
    font-size: 12px;
    color: #00B2E3;
    font-family: "Poppins-Regular";
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    justify-content: space-between;
    margin-right: 10px;
    text-transform: capitalize;
}

.selected-reviews-wrapper>div>button:first-child .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #00B4E5;
}

.selected-reviews-wrapper>div>button {
    cursor: pointer;
}

.tooltip-reviews-holder {
    position: absolute !important;
    left: 15px;
    color: #ffff !important;
}

.selected-reviews-wrapper>div>button:last-child {
    border-radius: 6px;
    padding: 8px 8px 8px 16px;
    font-family: "Poppins-Regular";
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    justify-content: space-between;
    color: #fff;
    background-color: transparent;
    border: 0.9px solid rgba(255, 255, 255, 0.15);
}

.selected-reviews-wrapper>div>button:nth-child(2) {
    border-radius: 6px;
    padding: 8px 8px 8px 16px;
    font-family: "Poppins-Regular";
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    justify-content: space-between;
    color: #fff;
    background-color: transparent;
    border: 0.9px solid rgba(255, 255, 255, 0.15);
    margin-right: 10px;
}

.selected-reviews-wrapper>div>button>svg {
    font-size: 20px;
    padding-right: 10px;
}

.selected-reviews-wrapper>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.review-item-wrapper>label {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 100%;
    background: rgba(0, 178, 227, 0.05);
    border: 1.86191px solid rgba(0, 178, 227, 0.15);
    margin-right: 10px;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-item-wrapper>label>svg {
    color: #fff;
    font-size: 26px;
    display: none;
}

.review-item-wrapper>input:checked+label {
    background-color: #00B2E3;
}

.review-item-wrapper>input:checked+label>svg {
    display: block;
}

.review-item-list-container a {
    display: block;
    text-decoration: none;
    color: unset;
    width: 100%;
    margin-bottom: 10px;
}

.review-item-container-actions {
    position: relative;
}

.custom-sketchpicker {
    position: absolute;
    z-index: 9999;
}

.review-item-container-actions button {
    color: #25313C;
    font-family: "Poppins-Regular";
    font-size: 11px;
    padding: 11px 15px;
    border-radius: 3px;
    outline: 0px;
    border: 1px solid;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-left: auto;
    background: #f8f8f8;
    border: 0.74px solid #F4F4F5;
    border-radius: 10px;
}

.review-item-container-actions button svg {
    margin-left: 5px;
}

.review-item-container-actions button .MuiCircularProgress-root {
    width: 12px !important;
    height: 12px !important;
    margin-right: 10px;
}

.span-date {
    color: #808488;
    font-family: "Poppins-Regular";
    font-size: 12px;
    display: block;
    margin-bottom: 2px;
    text-align: right;
    margin-right: 3px;
}

.review-item-container-body {
    font-family: "Poppins-Light";
    color: #0c2640;
    font-size: 0.8750em;
    padding: 0px 20px 20px 20px;
    white-space: pre-wrap;
    min-height: 10px;
}

.review-item-container-footer {
    padding: 10px 24px;
    border-top: 1px solid #B0B7C336;
    font-family: "Poppins-Light";
    color: #0c2640;
    font-size: 0.8750em;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    justify-content: space-between;
    flex-wrap: wrap;
}

.review-item-container-footer>div.location-information {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    --webkit-align-items: center;
}

.review-item-container-footer>div.location-information h4 {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 18px;
    color: #0C2640;
    margin: 0;
    padding: 0;
}

.review-item-container-footer>div.location-information h6 {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #0C264080;
    margin: 0;
    padding: 0;
}

.review-item-container-footer>div.location-information>img {
    background: #fff;
    padding: 6px;
    border-radius: 100%;
    margin-right: 10px;
    max-width: 24px;
}

div.customer-information {
    background: #f8f8f8;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.review-item-container-footer>div.customer-information>h6 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Poppins-Light";
    opacity: 0.9;
    font-size: 1em;
    color: #0c2640;
}

.review-item-container-footer>div.customer-information>div {
    font-family: "Poppins-Regular";
    font-size: 0.8750em;
}

.review-item-container-footer>div.customer-information>div span {
    font-family: "Poppins-Bold";
    margin-right: 6px;
}

.no-logo-location {
    width: 29px;
    height: 29px;
    background: #c8dde4;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-family: 'Poppins-Medium';
    margin-right: 10px;
}

.custom-rating-box-sm {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    background: #FFFFFF;
}

.reset-filter-reviews {
    font-size: 0.8750em;
    color: #0c2640;
    text-align: right;
    font-size: 12px;
    font-family: 'Poppins-Regular';
}

.reset-filter-reviews>span {
    color: #00B4E5;
    cursor: pointer;
}

.tags-checkboxes input {
    display: none;
}

.tags-checkboxes label.checked {
    background-color: rgba(128, 128, 128, 0.692) !important;
    color: #fff !important;
    border: 1px solid grey !important;
}

.tags-checkboxes {
    display: block;
    /* width: fit-content; */
    width: 160px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 6px;
    z-index: 1024;
}

.scrollable-tag-container {
    height: calc(100vh - 210px);
    overflow-y: auto;
}

.scrollable-loc-container {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

.tags-checkboxes label {
    display: flex;
    cursor: pointer;
    padding: 5px;
    font-family: "Poppins-Regular";
    color: #0c2640;
    font-size: 0.7750em;
    align-items: center;
    margin-bottom: 1px;
    border-bottom: 1px solid #ddd;
}

.tags-checkboxes label svg {
    margin-right: 5px;
    color: #0089af;
    font-size: 14px;
}

.tags-checkboxes label:hover {
    opacity: 0.7;
}

.tags-checkboxes input:checked+label>span {
    background-color: #00B4E5;
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
}

.tags-checkboxes input:checked+label>span::before {
    content: url("../../public/admin-icons/checkmark.svg");
}

.Skeleton-text {
    font-family: "Poppins-Regular";
}

.Skeleton-text>h5,
.Skeleton-text>h6 {
    margin: 0px;
}

.tags-checkboxes label span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;
    background-color: #d6dfe9d3;
    margin-right: 7px;
}

.rev-action-buttons {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 15px;
}

.bradcrumb-back-to:hover,
.bradcrumb-next-to>button:hover {
    background: #cccccc2f;
}

.bradcrumb-back-to {
    background: #cccccc5c;
    border-radius: 3px;
    width: fit-content;
}

.bradcrumb-next-to {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.bradcrumb-next-to>button:disabled {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
}

.bradcrumb-back-to>button,
.bradcrumb-next-to>button,
.bradcrumb-back-to>a {
    text-decoration: none;
    color: #0c2640;
    font-size: 0.8750em;
    padding: 10px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: "Poppins-Light";
    border: 0;
    cursor: pointer;
}

.bradcrumb-next-to>a>svg {
    opacity: 0.8;
}

.bradcrumb-next-to>button {
    background: #cccccc5c;
    border-radius: 3px;
    width: fit-content;
}

.bradcrumb-next-to>button:first-child {
    margin-right: 5px;
}

/* .bradcrumb-next-to > a:last-child> svg{
    margin-left: 5px;
}
.bradcrumb-next-to > a:first-child> svg{
    margin-right: 5px;
} */
.bradcrumb-back-to>a>svg {
    margin-right: 5px;
    opacity: 0.8;
}

.rev-action-buttons button {
    border: 0px;
    background-color: #fff;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: "Poppins-Regular";
    font-size: 0.8750em;
    color: #0c2640;
    margin-right: 10px;
    box-shadow: 0px 29.9688px 79.9167px rgb(0 0 0 / 10%);
    padding: 7px 10px;
    border-radius: 3px;
    border: 1px solid #00c1f724;
    cursor: pointer;
}

.rev-action-buttons button:hover {
    background-color: #00c1f712;
}

.rev-action-buttons>button.dark-bg-action {
    background-color: #e5e5e5;
}

.rev-action-buttons button>svg {
    margin-right: 5px;
    color: #00B4E5;
}

.review-history .reply-history {
    font-family: "Poppins-Regular";
    font-size: 0.8500em;
    background-color: #00c1f712;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: flex-start;
    width: 50%;
}

.review-history .reply-history textarea {
    border: 0px;
    padding: 12px;
    border-radius: 7px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Poppins-Light';
    resize: none;
    overflow: hidden;
}

.review-history .reply-history textarea:focus {
    outline: 0;
}

.change-reply-content {
    margin-top: 15px;
    background-color: #fff;
}

.change-reply-content>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.change-reply-content>div button {
    background-color: transparent;
    border: 0px;
    padding: 10px;
}

.change-reply-content>div button:first-child {
    background-color: transparent;
    border: 0px;
    color: rgba(255, 0, 0, 0.692);
    font-family: "Poppins-Regular";
    font-size: 0.8500em;
    cursor: pointer;
}

.change-reply-content>div button:last-child {
    background-color: transparent;
    border: 0px;
    color: #00B4E5;
    font-family: "Poppins-Regular";
    font-size: 0.8500em;
    cursor: pointer;
}

.space-nowrap {
    white-space: nowrap;
}

.mt-0 {
    margin-top: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.nothing-to-show {
    font-family: 'Poppins-Regular';
    font-size: 11px;
    color: #40404085;
    text-align: center;
}

.nothing-to-show.flex {
    height: calc(100% - 31px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-history .other-actions-history {
    font-family: "Poppins-Regular";
    font-size: 0.6750em;
    background-color: #ebeff1;
    padding: 5px 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
    --webkit-align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.review-history h6 {
    font-family: "Poppins-Medium";
    font-size: 0.8750em;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.review-history p {
    margin: 4px 0px 0px 7px;
    word-break: break-word;
    white-space: pre-wrap;
}

.review-history span {
    margin: 5px 0px 0px 7px;
}

.review-history>div:last-child img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 100%;
}

.review-history h1 {
    font-family: "Poppins-Regular";
    font-size: 18px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #0c2640;
}

.reply-p-title {
    color: #2c92f9;
}

.review-history {
    margin-top: 25px;
    width: 100%;
}

.review-history>div {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    /* max-height: 800px;
    overflow-y: auto; */
}

.scrollable-note-container {
    height: calc(100vh - 340px);
    overflow: auto;
    padding: 20px
}

.scrollable-note-container>div {
    margin-bottom: 10px;
    font-family: "Poppins-Regular";
    font-size: 0.8750em;
    background-color: #fff1d9f2;
    border-radius: 5px;
    color: #0c2640;
    word-break: break-all;
}

.scrollable-note-container>div>div {
    padding: 15px;
    margin: 0px;
}

.scrollable-note-container>div>div>p {
    margin: 0px;
    font-size: 0.8750em;
    margin: 0px
}

.scrollable-note-container>div>div>span {
    font-size: 10px;
}

.scrollable-note-container>div>div>h6 {
    margin: 0px 0px 10px 0px;
    font-size: 1em;
}

/* .scrollable-note-container > div > div:first-child{
    background-color: #ffd489f2;
    padding: 3px 15px;
    text-align: right;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
} */
.text-note-container {
    padding: 20px;
}

.text-note-container textarea {
    padding: 10px;
    border-radius: 5px;
    resize: none;
    border: 1px solid #d7d7d7;
    height: 100px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    font-family: "Poppins-Regular";
}

.text-note-container textarea:focus {
    border: 1px solid #00B4E5;
    outline: unset;
}

.social-share {
    position: absolute;
    background: #fff;
    padding: 5px;
    top: 40px;
    z-index: 1050;
    border: 1px solid #c5c9cc96;
    border-radius: 5px;
}

.social-share div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: "Poppins-Regular";
    color: #0c2640;
    font-size: 0.8750em;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.social-share div:hover {
    background-color: #00c1f712;
    color: #00B4E5;
}

.social-share div>svg {
    margin-right: 5px;
}

.loader-ireview {
    background: rgba(255, 2555, 255, 0.7);
    position: fixed;
    left: 228px;
    right: 0;
    top: 50px;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    --webkit-justify-content: center;
}

.loader-ireview-absolute {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    --webkit-align-items: flex-start;
    justify-content: center;
    --webkit-justify-content: center;
    top: -12px;
    padding-top: 200px;
    z-index: 1050;
    bottom: 0;
    background: rgba(255, 2555, 255, 0.7);
}

.loader-review-absolute-flex {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    --webkit-align-items: flex-start;
    justify-content: center;
    --webkit-justify-content: center;
    top: 0px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: baseline;
    z-index: 1050;
    width: fit-content;
    margin: auto;
}

.loader-ireview>img,
.loader-ireview-absolute>img,
.loader-review-absolute-flex>img {
    max-width: 250px;
}

.box-container-replies>span {
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: rgb(221, 54, 54);
    margin-left: 10px;
}

.reply-box-container-btns {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: flex-end;
    --webkit-justify-content: flex-end;
    padding: 0 20px 20px 20px;
}

.box-container-replies {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 0 20px 20px 20px;
    flex-wrap: wrap;
}

.btn-white-shadow {
    background-color: #fff;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: "Poppins-Regular";
    font-size: 0.775em;
    color: #0c2640;
    margin-right: 10px;
    box-shadow: 0px 29.9688px 79.9167px rgb(0 0 0 / 10%);
    padding: 7px;
    border-radius: 3px;
    border: 1px solid #00c1f724;
    cursor: pointer;
    text-transform: capitalize;
}

.reply-box-container-btns>button:first-child {
    margin-right: 10px;
    color: #EA5455;
    background-color: transparent;
    cursor: pointer;
    border: 0px;
}

.box-container-replies>button:first-child,
.box-container-replies>button:nth-child(2) {
    background-color: #fff;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: "Poppins-Regular";
    font-size: 0.775em;
    color: #0c2640;
    margin-right: 10px;
    box-shadow: 0px 29.9688px 79.9167px rgb(0 0 0 / 10%);
    padding: 7px;
    border-radius: 3px;
    border: 1px solid #00c1f724;
    cursor: pointer;
}

.box-container-replies>button:first-child:hover,
.box-container-replies>button:nth-child(2):hover {
    box-shadow: 0px 29.9688px 79.9167px rgb(0 0 0 / 22%);
}

.box-container-replies>button:first-child>svg {
    margin-right: 5px;
}

.box-container-replies>div>button {
    font-family: "Poppins-Regular";
    font-size: 0.8750em;
    padding: 5px 10px;
    cursor: pointer;
}

.quick-reply-container-item>input {
    display: none;
}

.quick-reply-container {
    padding: 0px 15px 15px 15px;
}

.language-wrapper {
    width: 20px;
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin-right: 12px;
    margin-bottom: 5px;
    cursor: pointer;
    margin-left: 0px;
}

.language-dropdown {
    width: 185px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgb(176 183 195 / 15%);
    border: 0px;
    border-radius: 0px;
    transform: translate(-70px, 49px) !important;
    position: absolute;
    top: 6px;
}

.language-dropdown>div {
    font-family: "Poppins-Regular";
    font-size: 12px;
    padding: 9px 13px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: rgba(37, 49, 60, 0.8);
}

.language-dropdown>div:hover {
    background: rgba(24, 174, 227, 0.07);
    color: #18AEE3;
}

.language-dropdown img {
    margin-right: 7px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

.language-wrapper>img {
    width: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.positive-filter {
    color: rgb(62 179 73);
    border-color: rgb(62 179 73);
    background-color: rgba(8, 92, 16, 0.1);
    padding: 3px 7px;
    font-size: 12px;
    border: 1px solid;
    border-radius: 20px;
    display: block;
}

.chars-left {
    margin: 5px 0px 0px 0px;
    font-size: 0.7em;
    font-family: "Poppins-Regular";
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    color: #22272F;
}

.chars-left>span {
    color: #00B4E5;
    margin-left: 3px;
    display: block;
}

.positive-filter.active {
    color: #fff;
    background-color: rgb(62 179 73);
}

.all-replies {
    color: #00B4E5;
    border-color: #00B4E5;
    background-color: #00B4E51A;
    padding: 3px 7px;
    font-size: 12px;
    border: 1px solid;
    border-radius: 20px;
    display: block;
}

.all-replies.active {
    color: #fff;
    background-color: #00B4E5;
}

.negative-filter {
    color: rgb(231, 76, 60);
    border-color: rgb(231, 76, 60);
    background-color: rgba(231, 76, 60, 0.1);
    padding: 3px 7px;
    font-size: 12px;
    border: 1px solid;
    display: block;
    border-radius: 20px;
}

.negative-filter.active {
    background-color: rgb(231, 76, 60);
    color: #fff;
}

.quick-reply-container-item {
    margin: 10px 5px;
    background-color: #e1e7ed;
    box-sizing: border-box;
    display: block;
    color: #000;
    font-family: "Poppins-regular";
    font-size: 14px;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #e1e7ed;
}

.quick-reply-container-item>label {
    cursor: pointer;
    padding: 10px;
    display: block;
}

.quick-reply-container-item:hover {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
}

/* .nobtn-div-container{
    height: calc(100vh - 1px);
    overflow-y: auto;
} */
.quick-reply-container-item>label>div {
    margin-bottom: 10px;
    display: flex;
    --webkit-align-items: center;
    align-items: center;
}

.quick-reply-container-item>label>div>span {
    margin-right: 7px;
    display: block;
    padding: 2px 7px;
    border-radius: 20px;
    font-size: 10px;
    box-sizing: border-box;
    border: 1px solid;
}

.filter-reply-types {
    margin: 20px 20px 0px 20px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.filter-reply-types .location-list {
    width: 45%;
    margin-left: auto;
}

.filter-reply-types>span {
    display: block;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
    font-family: "Poppins-Regular";
}

/* .quick-reply-container-item > input:checked + label{
    border: 1px solid #00B4E5;
} */
.default {
    color: #C5C9CC;
}

.animate {
    color: #faaf00;
}

.star {
    margin-right: 3px;
}

@keyframes stretch-bounce {
    0% {
        -webkit-transform: scale(1);
    }

    25% {
        -webkit-transform: scale(1.5);
    }

    50% {
        -webkit-transform: scale(0.9);
    }

    75% {
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

.star:nth-child(1) {
    transition: color 0s ease 1s;
}

.star:nth-child(2) {
    transition: color 0s ease 1.2s;
}

.star:nth-child(3) {
    transition: color 0s ease 1.4s;
}

.star:nth-child(4) {
    transition: color 0s ease 1.6s;
}

.star:nth-child(5) {
    transition: color 0s ease 1.8s;
}

.star:nth-child(1).animate {
    -webkit-animation: stretch-bounce .5s ease-in-out 1s;
}

.star:nth-child(2).animate {
    -webkit-animation: stretch-bounce .5s ease-in-out 1.2s;
}

.star:nth-child(3).animate {
    -webkit-animation: stretch-bounce .5s ease-in-out 1.4s;
}

.star:nth-child(4).animate {
    -webkit-animation: stretch-bounce .5s ease-in-out 1.6s;
}

.star:nth-child(5).animate {
    -webkit-animation: stretch-bounce .5s ease-in-out 1.8s;
}


.dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin: auto;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #ffffff;
    }

    50%,
    100% {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.dot-flashing-sm {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing-sm 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin: auto;
}

.dot-flashing-sm::before,
.dot-flashing-sm::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing-sm::before {
    left: -9px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing-sm 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing-sm::after {
    left: 9px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing-sm 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing-sm {
    0% {
        background-color: #ffffff;
    }

    50%,
    100% {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.dot-flashing-dark {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing-dark 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin: auto;
}

.dot-flashing-dark::before,
.dot-flashing-dark::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing-dark::before {
    left: -12px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing-dark 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing-dark::after {
    left: 12px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing-dark 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing-dark {
    0% {
        background-color: #ffffff48;
    }

    50%,
    100% {
        background-color: rgba(152, 147, 147, 0.2);
    }
}

.MuiRating-iconFilled {
    animation-fill-mode: none;
    animation-delay: 1s;
}

.reply-box-container-btns>button:last-child {
    background-color: #00B4E5;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
}

.reply-box-container {
    height: fit-content;
    padding: 12px 0px;
    border-radius: 15px;
    background-color: #f8f8f8;
    margin-top: 16px;
}

/* END OF NOTIFICATION */
.confirm-reply-buttons {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: flex-end;
    --webkit-justify-content: flex-end;
    margin-top: 25px;
}

.confirm-reply-buttons>button:first-child {
    margin-right: 10px;
    background-color: transparent;
    border: 0px;
    color: rgba(255, 0, 0, 0.692);
    font-family: "Poppins-Regular";
    font-size: 0.8500em;
    cursor: pointer;
}

.confirm-reply-buttons>button:last-child {
    font-family: "Poppins-Regular";
    font-size: 0.8500em;
    cursor: pointer;
    background-color: #00B4E5;
    border: 0px;
    border-radius: 3px;
    color: #fff;
    padding: 3px 10px;
}

.pinned-item {
    position: absolute;
    padding: 6px;
    background: #d3d3d36b;
    border-radius: 20px;
    right: -10px;
    top: -11px;
    cursor: pointer;
}

.noty-item-user {
    display: flex;
}

.noty-item-user>img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 5px;
}

/*Account Settings*/
.account-settings-container {
    font-family: "Poppins-Light";
    color: #0c2640;
}

.account-image {
    position: relative;
    text-align: center;
}

.image-user-info {
    margin-top: 30px !important;
}

.image-user {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: #e6eaed;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-family: "Poppins-Medium";
    font-size: 16px;
    color: #00B4E5;
}

.image-user img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.editIcon {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    z-index: 1;
    top: unset !important;
    width: unset !important;
    height: unset !important;
}

.account-inputs-section #outlined-basic-label {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
}

.account-inputs-section #outlined-basic-label {
    color: #0c2640;
}

.account-inputs-section .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #00B4E5;
}

.account-inputs-section .MuiOutlinedInput-root:hover {
    border: 0px !important;
}

.account-inputs-section #outlined-basic {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
}

.account-inputs-section .MuiButton-outlined,
.password-section .MuiButton-outlined {
    background: #FFFFFF;
    border: 1px solid #00B4E5;
    border-radius: 3px;
    color: #00B4E5;
    text-transform: none;
    padding: 8px 25px;
    font-size: 14px;
    line-height: 29px;
    min-width: 90px;
}

.password-section {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    padding: 30px 0px;
}

.account-inputs-section .MuiButton-outlined:hover,
.password-section .MuiButton-outlined:hover {
    border-color: rgba(0, 180, 229, 0.8);
    color: rgba(0, 180, 229, 0.8);
    background: #fff;
}

.account-inputs-section .MuiOutlinedInput-root,
.account-inputs-section .MuiInputLabel-root {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
}

.account-inputs-section .MuiBox-root {
    border-radius: 10px;
    border: 0px;
}

/* .account-inputs-section .MuiFormControl-root {
    margin: 0px 0px 8px 0px;
} */

/**/
.save-cancel-button {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 30px;
}

.save-cancel-button .MuiButton-contained {
    text-transform: none;
    box-shadow: unset;
    color: #fff;
    padding: 8px 15px;
    font-size: 14px;
    line-height: unset;
}

.save-cancel-button .MuiButton-contained:hover {
    background-color: rgba(0, 180, 229, 0.8);
    box-shadow: none;
}

.MuiTypography-root.MuiTypography-h6 {
    margin: 0px;
    font-family: "Poppins-Medium";
    color: #0c2640;
    margin-bottom: 20px;
    font-size: 16px;
}

.profile-image-holder {
    padding: 25px 0px;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.profile-image-holder .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: #E7F8FE;
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
}

.profile-image-holder h4 {
    font-family: 'Poppins-Medium';
    font-size: 22px;
    line-height: 33px;
    color: #0c2640;
    margin-left: 20px;
    font-weight: unset;
}

.save-changes-section .MuiButton-contained {
    text-transform: none;
    box-shadow: unset;
    color: #fff;
    padding: 8px 15px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 29px;
}

/*********************************************/
/**Account / User Management***/
.account-settings-container .input-icon label {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
    margin-bottom: 0px;
    display: flex;
}

.input-icon {
    margin-top: 0px;
}

.input-icon-holder {
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 25px;
    position: relative;
    background-color: #fff;
}

.input-icon-holder.focused {
    border-color: #18AEE3;
}

.input-icon-holder input {
    border: none;
    font-size: 13px;
    color: #18527D;
    outline: none;
    padding: 0px 0px 0px 17px;
    background-color: #fff;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
    width: 100%;
}

.input-icon-holder input:focus {
    outline: 0;
}

.input-icon-holder input::-webkit-input-placeholder,
.input-icon .MuiInputLabel-root input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
}

.input-icon-holder input::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
}

.input-icon-holder input:-ms-input-placeholder {
    /* IE 10+ */
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
}

.input-icon-holder input:-moz-placeholder {
    /* Firefox 18- */
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
}

.changePadding input {
    padding-left: 0px;
}

.locations-holder {
    margin-top: 25px;
    padding: 0px 45px;
}

.locations-holder .MuiGrid-root {
    padding-bottom: 25px;
    border-bottom: 1px solid #F3F3F3;
}

.location-box {
    background: #FFFFFF;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #667085;
    width: 100%;
}

.location-box-item {
    width: 100%;
    height: 100%;
}

.location-box>div,
.location-box-item>div,
.location-box-item>div>div {
    height: 100%;
}

.location-holder-box .MuiInputBase-input {
    font-family: 'Poppins-Regular' !important;
    font-size: 10px !important;
    line-height: 18px !important;
    color: #667085 !important;
    padding: 10px !important;
    text-transform: capitalize;
}

.align-self-center {
    align-self: center;
}

.MuiMenuItem-root .MuiListItemText-primary {
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.location-box-item .PrivateSwitchBase-root .MuiSvgIcon-fontSizeMedium {
    font-size: 16px !important;
}

.location-holder-box {
    display: flex;
    align-items: flex-start;
    --webkit-align-items: flex-start;
    padding: 20px 15px;
    background: #FFFFFF;
    border: 0.75px solid #F3F3F3;
    border-radius: 7.44713px;
    margin-bottom: 5px;
    cursor: pointer;
    justify-content: space-between;
}

.w-100 {
    width: 100% !important;
    /* margin-left: 10px; */
}

.loc-allowed-border-color {
    border-color: rgba(105, 187, 26, 0.28);
}

.loc-nallowed-border-color {
    border-color: rgba(206, 56, 58, 0.28);
}

.shrink-0 {
    /* flex-shrink: 0; */
    align-items: center;
    --webkit-align-items: center;
    display: flex;
}

.shrink-0 img {
    margin-right: 15px;
}

.location-box h6 {
    margin: 0px 0px 15px 0px;
    font-family: 'Poppins-Medium';
    font-size: 17px;
    line-height: 26px;
    color: #f3f3f3;
    font-weight: 500;
}

.loc-allowed-txt-color {
    color: #7AC037 !important;
}

.loc-nallowed-txt-color {
    color: #CE383A !important;
}

.location-box:last-child {
    border-color: rgba(105, 187, 26, 0.28);
}

.location-box:first-child {
    border-color: rgba(206, 56, 58, 0.28);
}

.location-box:last-child h6 {
    color: #7AC037 !important;
}

.location-box:first-child h6 {
    color: #CE383A !important;
}

.location-holder-box label {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #0c2640;
    width: 115px;
    white-space: pre-wrap;
}

.w-100 .MuiSelect-multiple {
    white-space: pre-wrap !important;
}

.user-management-location .MuiSelect-multiple {
    padding-right: 30px !important;
}

.image-user input {
    visibility: hidden;
}

.image-user {
    position: relative;
}

.uploadedImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: 0;
    object-fit: cover;
}

.uploadedImage img {
    width: 100%;
    height: 100%;
}

.uploadDefault {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.account-inputs-section .react-tel-input .form-control {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 100%;
    padding: 24px 14px 24px 58px;
}

.account-inputs-section .react-tel-input .form-control:hover {
    border: 1px solid #00B4E5;
}

.account-inputs-section .react-tel-input .special-label {
    font-family: "Poppins-Light";
    font-size: 12px;
    color: #0c2640;
}

.phone-group-wrap .special-label {
    display: none !important;
}

.account-inputs-section .react-tel-input .form-control:focus {
    border-color: #00B4E5;
    box-shadow: unset;
}

.account-inputs-section .react-tel-input .country-list .search-box {
    padding: 10px 7px 10px;
    width: 95%;
}

.account-settings-container fieldset.MuiFormControl-root {
    padding: 16px 24px 0px 0px !important;
}

.account-settings-container .MuiFormControlLabel-label {
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #0c2640;
}

.account-settings-container .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.notification-div-wrapper {
    background: #f3f3f3;
    padding: 13px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.rating-list-background {
    margin-top: 25px;
}

.rating-list-background>div,
.daily_summary_box .box {
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 2%);
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.daily_summary_box {
    display: flex;
}

.daily_summary_box .box {
    width: 260px;
}

.daily_summary_box p {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #3F3D56;
    cursor: pointer;
}

.link-asbtn-darkblue {
    background: #00B2E3;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Poppins-Regular';
    cursor: pointer;
    font-size: 14px;
    min-width: 179px;
    text-decoration: none;
    box-sizing: border-box;
}

.location-of-groupname,
.location-of-groupname>span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #212529 !important;
    margin: 0;
    font-family: "Poppins-Regular";
}

.link-asbtn-darkblue img {
    margin-right: 11px;
}

.rating-list-background>div>label {
    padding: 12px;
    cursor: pointer;
    padding-right: 0px;
    line-height: 0;
}

.disable-inner-dropdown {
    pointer-events: none;
    opacity: 0.5;
}

.account-settings-container .MuiSwitch-switchBase * {
    pointer-events: auto !important;
}

.account-settings-container .table-header-container {
    margin-bottom: 0px;
}

.account-settings-container .table-header-container a {
    text-decoration: none;
}

.table-holder-section {
    margin-top: 50px;
}

.user-profile-photo {
    margin-top: 25px;
    margin-bottom: 40px;
}

.location-holder-box .w-100 {
    margin-left: 10px;
}

/****************************************/
.iframe-holder {
    background: #fff;
    margin: auto;
    /* display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center; */
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    /* padding: 5em; */
    left: 0;
    border-radius: 20px;
    /* border: 5px solid #00B4E5; */
    box-sizing: border-box;
    width: 1000px;
    /* height: 67vh; */
    height: max-content;
    padding-bottom: 22px;
    outline: 0px;
}

.fitVideo {
    padding-top: 56.25%;
    width: 100%;
    overflow: hidden;
}

/* Youtube modal */
.fitVid-wrapper {
    padding: 0 1rem;
}

.iframe-holder iframe {
    border-radius: 12px;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
}

/* Youtube modal */

.iframe-holder .iframe_holder_wrapper {
    height: 49vh;
    padding: 10px;
}

.upload-coupon-wrapper {
    padding: 20px;
}

.info-btn-container {
    cursor: pointer;
}

.info-btn-container {
    background-color: transparent;
    border: 0px;
    display: flex;
    align-self: center;
}

.iframe-holder .iframe_head__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 19px 12px 19px;
}

.iframe-holder .iframe_head__wrapper button {
    /* position: absolute;
    top: 16px;
    right: 20px; */
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    background: #EAF5FF;
    border-radius: 100%;
    border: 0px;
    height: 29px;
    cursor: pointer;
    width: 29px;
}

.iframe-holder>button>svg {
    color: #fff;
    font-size: 50px;
}

.Location-add {
    color: #fff;
    font-family: "Poppins-Regular";
    background-color: #00B4E5;
    font-size: 0.85em;
    padding: 8px;
    border-radius: 3px;
    outline: 0px;
    border-color: #00B4E5;
    border: 1px solid;
    cursor: pointer;
    margin-left: 10px;
    text-transform: uppercase;
}

.location-item {
    padding: 12px 20px;
    font-family: 'Poppins-Regular';
    font-size: 0.9em;
    color: #0c2640;
    cursor: pointer;
}

.location-item-hover:hover {
    background-color: #00b3e51a;
}

.location-item svg {
    color: #00B4E5;
}

.location-res-container {
    box-shadow: 0px 2.9688px 79.9167px rgb(0 0 0 / 7%);
    max-height: 263px;
    overflow: auto;
    padding: 20px 0px;
}

.select-search-loc {
    max-width: 28vw;
    height: 38px;
}

.custom-selected .Mui-selected {
    background-color: #00b4e56b !important;
}

.source-information-wrapper h1 {
    font-family: 'Poppins-Bold';
    font-weight: 500;
    font-size: 18px;
    margin-top: 0;
    color: #0c2640;
}

.source-information-wrapper>div {
    margin-bottom: 20px;
}

.source-information-wrapper {
    background: #fff1df;
    padding: 25px 25px 5px 25px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: fit-content;
}

.source-information-wrapper p {
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;
    color: #000000d6;
}

/*Dashboard*/
.rating-box svg {
    color: #faaf00;
    font-size: 25px;
    margin-left: 2px;
}

.source-widget-infos {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 36px 0;
    border-radius: 5px;
    min-height: calc(100% - 80px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    box-sizing: border-box;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    height: calc(100% - 34px);
    box-sizing: border-box;
}

.source-widget-infos>div h6,
.source-widget-infos-blured>div h6 {
    font-family: "Poppins-Regular";
    color: #fff;
    font-weight: 400;
    font-size: 11px;
    margin: 0;
}

.source-widget-infos-blured p {
    margin: 0px;
    font-family: "Poppins-Medium";
    color: #0c2640;
    font-size: 11px;
    position: relative;
    bottom: -20px;
}

.breakdown-connect {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.breakdown-connect>a {
    font-family: 'Poppins-Regular';
    color: #fff;
    background-color: #0065E3;
    padding: 6px;
    text-decoration: none;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-size: 11px;
    flex: 1;
    box-sizing: border-box;
}

.source-widget-infos>div span {
    font-family: "Poppins-Bold";
    color: #fff;
    font-size: 32px;
    display: flex;
    font-weight: 600;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    line-height: 33px;
}

.source-widget-top h6 {
    margin: 0px;
    margin: 0px;
    font-family: "Poppins-Medium";
    color: #0c2640;
    font-size: 11px;
    text-transform: capitalize;
}

.a-primary {
    font-family: 'Poppins-Regular';
    color: #fff;
    background-color: #00B4E5;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    padding: 6px;
    text-decoration: none;
    border-radius: 3px;
    text-align: center;
    font-size: 0.8em;
}

.source-widget-top>img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 50%;
}

.source-widget-top {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    padding: 7px 10px;
}

.review_collection_dashboard {
    position: relative;
    border-radius: 7px;
    justify-content: center;
}

.custom-flex-direction {
    flex-direction: column;
    align-items: flex-start !important;
}

.custom-flex-direction span {
    font-family: "Poppins-Regular";
}

.blured-review-collection p {
    font-family: 'Poppins-Regular';
    width: 50%;
    text-align: center;
}

.blured-review-collection {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bfbfbfa8;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    flex-direction: column;
}

.blured-review-collection button {
    font-family: 'Poppins-Regular';
    color: #fff;
    background-color: #00B4E5;
    border: 1px solid #00B4E5;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}

.review_collection_body_container>div:first-child::after {
    content: "";
    width: 2px;
    background-color: #CDE6F9;
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.review_collection_container_dashboard>svg {
    padding: 5px 10px;
    color: #7f7f7f99;
    display: flex;
    margin-left: auto;
    font-size: 20px;
    cursor: pointer;
}

.review_collection_container_dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-flex-direction: column;
    background-size: contain;
}

.dashboard-box-container-alone {
    background-color: #fff;
    border-radius: 9px;
    position: relative;
    height: 100%;
    box-shadow: 0px 0px 0px 1px #0000000D;
    text-transform: capitalize;
    box-sizing: border-box;
}

.dashboard-box-container-alone span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 31px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins-Medium";
    font-size: 15px;
    color: #464E5F;
}

.dashboard-box-container-alone h6 {
    margin: 0px;
    padding: 7px 14px;
    font-family: "Poppins-Medium";
    font-size: 11px;
    color: #464E5F;
    border-bottom: 0.2px solid #0000001c;
}

.dashboard-box-containers,
.review_collection_container_dashboard {
    background-color: #fff;
    border-radius: 9px;
    position: relative;
    height: 100%;
    padding-bottom: 10px;
    box-shadow: 0px 0px 0px 1px #0000000D;
    text-transform: capitalize;
    box-sizing: border-box;
}

.dashboard-box-containers-bottom {
    background-color: #fff;
    border-radius: 9px;
    position: relative;
    height: 100%;
    padding: 20px;
    box-shadow: 0px 0px 0px 1px #0000000D;
    box-sizing: border-box;
}

.activate-feature-toggle {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    ;
}

.activate-feature-toggle h6 {
    margin: 21px;
    color: #212529;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins-Medium';
}

.disabled_location_wrapper {
    pointer-events: none;
    opacity: 0.5;
}

.source-widget-infos-blured {
    background-color: #cbcbcb;
    text-align: center;
    padding: 36px 0;
    border-radius: 5px;
    min-height: calc(100% - 80px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    box-sizing: border-box;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.source-widget-infos-blured>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-around;
}

.dashboard-box-containers>h2 {
    margin: 0px;
    padding: 7px 14px;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #464E5F;
    border-bottom: 0.2px solid #0000001c;
}

.dashboard-box-containers table {
    width: 100%;
}

#dashboard-snapshot .dashboard-box-containers table th,
#dashboard-snapshot .dashboard-box-containers table td {
    text-align: left !important;
}

.dashboard-box-containers table th {
    margin: 0px;
    padding: 5px 15px;
    font-family: "Poppins-Regular";
    font-size: 13px;
    color: #464E5F;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
}

.dashboard-box-containers table td {
    margin: 0px;
    padding: 5px 15px;
    font-family: "Poppins-Regular";
    font-size: 13px;
    color: #464E5F;
    white-space: nowrap;
    text-align: center;
}

.analytics-infos-total {
    display: inline-block;
    font-family: "Poppins-Regular";
    font-size: 10px;
    color: #5D5D5D;
    box-sizing: border-box;
}

.analytics-infos-positive {
    display: inline-block;
    font-family: "Poppins-Regular";
    font-size: 24px;
    color: #4B4B4B;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
}

.analytics-visits>div {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.analytics-visits>div>span {
    color: #464E5F;
    font-size: 32px;
    font-family: 'Poppins-Bold';
    font-weight: 600;
    display: block;
}

.analytics-phrases-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 10px 15px;
}

.analytics-phrases-title>h5 {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins-Medium";
    color: #000000;
    margin: 0px;
}

.analytics-phrases-title>svg {
    padding: 0 4px;
    cursor: pointer;
    font-size: 20px;
}

.analytics-phrases>span {
    text-align: left;
    margin-bottom: 11px;
    display: block;
    margin-top: 20px;
    font-family: "Poppins-Medium";
    font-size: 11px;
    color: #464E5F;
}

.analytics-phrases>div {
    margin-bottom: 7px;
}

.analytics-phrases>div>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
}

/* .analytics-visits::after {
    content: "";
    border-right: 1px solid #E8E8E8;
} */

.analytics-phrases>div>div h6 {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins-Regular";
    color: #000000;
    margin: 0px;
}

.collapse-other-phrases .analytics-phrases {
    position: absolute;
    top: 25px;
    background: #fcfcfc;
    left: 0;
    border-radius: 5px;
    min-height: -moz-fit-content;
    min-height: fit-content;
    z-index: 10;
    max-height: 200px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    overflow: auto;
}

.collapse-other-phrases .analytics-phrases>div>div {
    padding: 5px 0;
}

.collapse-other-phrases {
    position: relative;
}

.collapse-other-phrases span {
    color: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 7px;
}

.analytics-phrases>div>div span {
    color: #0065E3;
    font-size: 10px;
    font-family: "Poppins-Regular";
    display: block;
    margin-left: 10px;
}

.analytics-visits>div>h6 {
    color: #0065E3;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 400;
    margin-top: 0;
}

.link-visited-container {
    padding: 0 5px;
}

.analytics-visits {
    display: flex;
    align-items: center;
}

.link-visited-wrapper>div:first-child {
    padding-right: 10px;
}

.link-visited-wrapper>div:last-child {
    padding-left: 10px;
}

.analytics-phrases>div:first-of-type>div {
    padding: 5px 10px;
    margin-right: 10px;
    position: relative;
    background-color: #f3f3f3ee;
    border-radius: 100px;
    margin-bottom: 5px;
}

.analytics-phrases>div {
    border-radius: 9px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.connect-to-platform {
    color: #464E5F;
    font-family: "Poppins-Medium";
    display: block;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    text-align: center;
    margin-top: 10px;
    background-color: #F3F7Fc;
    width: 90%;
    margin: 10px auto;
}

.analytics-infos-positive>span:first-child {
    color: #4B4B4B;
    font-family: "Poppins-Medium";
    font-size: 24px;
    margin-right: 10px;
}

.star-title-container {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
}

.star-title-container-table {
    display: flex;
    align-items: center;
}

.star-title-container svg,
.star-title-container-table svg {
    color: #faaf00;
    margin-left: 3px;
}

.facebook-recommendations {
    margin-top: 3px;
    padding: 0 15px;
}

.facebook-recommendations>h5 {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin: 0px;
    font-family: "Poppins-Light";
    font-size: 10px;
    color: #464E5F;
    margin-bottom: 10px;
}

.facebook-recommendations>h5>svg {
    margin-right: 5px;
    color: #faaf00;
    font-size: 1em;
}

.recommended-num,
.not-recommended-num {
    font-family: "Poppins-Bold";
    font-size: 11px;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.recommended-num {
    left: 10px;
}

.not-recommended-num {
    right: 10px;
}

.facebook-recommendations-percentage,
.facebook-recommendations-title {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    margin-top: 3px;
}

.facebook-recommendations-title {
    margin-bottom: 3px;
}

.facebook-recommendations-percentage>div,
.facebook-recommendations-title>div {
    font-family: "Poppins-Medium";
    font-size: 10px;
    color: #464E5F;
}

.dashboard-container-header {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.right-side-dashboard-elements {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    position: relative;
}

.right-side-dashboard-elements .date-range-container {
    position: unset;
}

.right-side-dashboard-elements>div:first-child {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-right: 10px;
    font-family: 'Poppins-Regular';
}

.right-side-dashboard-elements>div:first-child>span {
    margin-left: 10px;
}

.right-side-dashboard-elements>button {
    height: 41px;
    background: #00B2E3 !important;
    border: 1px solid #00B2E3 !important;
    padding: 10px 20px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    min-width: 100px;
    border-radius: 3px;
    color: #fff !important;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
}

.right-side-dashboard-elements>button:disabled {
    cursor: initial;
    opacity: 0.5;
}

.datepicker-dashboard-container .rdrStaticRanges>button:first-child {
    color: #000 !important;
    font-weight: 400 !important;
}

.datepicker-dashboard-dates>div {
    padding: 4px 8px !important;
}

.right-side-dashboard-elements>button>svg {
    margin-right: 11px;
    font-size: 14px;
}

.scrollable-box-table {
    max-height: 385px;
    overflow-y: auto;
}

.scrollable-box-sm-table {
    max-height: 150px;
    overflow-y: auto;
}

.tables-main-container {
    display: flex;
    flex-wrap: wrap;
}

.tables-main-container>div {
    flex: 50%
}

.phrases-table-positive>thead>tr>th:first-child>span {
    background-color: rgb(120, 181, 90);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
}

.phrases-table-negative>thead>tr>th:first-child>span {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
}

.phrases-table td>div,
.trending-keywords-table td>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.phrases-table td>div>svg,
.trending-keywords-table td>div>svg {
    font-size: 15px;
    color: #faaf00;
    margin-left: 5px;
}

.phrases-table tbody tr {
    height: 20px;
}

.trending-keywords-table td>span.negative {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
}

.trending-keywords-table td>span.neutral {
    background-color: #faaf00;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
}

.trending-keywords-table td>span.positive {
    background-color: rgb(120, 181, 90);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
}

.padding-20 {
    padding: 20px;
}

/* KASTRIOT TABLE */
.general__table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    /* table-layout: fixed; */
    margin-top: 40px;
}

.general__table tbody tr {
    margin-bottom: 100px;
}

.general__table tr {
    /* background-color: #f8f8f8;
    border: 1px solid #ddd; */
    padding: .35em;
}

.general__table tbody tr {
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgba(37, 49, 60, 0.02);
    border-radius: 5px;
}

.general__table th,
.general__table td {
    padding: 0.4em;
    /* text-align: center; */
    font-style: normal;
}

.general__table td {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0c2640;
}

.reputation__table td {
    font-size: 16px;
}

.general__table td.review-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.general__table td.review-rating svg,
.reputation_review_rating_td svg {
    color: #FFC107;
}

.table_wrapper {
    overflow-x: auto;
}

.table_wrapper table thead tr {
    background: #f3f3f3;
}

.table_wrapper table thead {
    position: sticky;
    top: 0;
}

.table_wrapper table {
    border-collapse: collapse;
}

.general__table td.location_name a {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #00B2E3;
    text-decoration: none;
}

.general__table td.location_name span {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #00B2E3;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}

.general__table th {
    white-space: nowrap;
    text-align: inherit;
    text-transform: uppercase;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
    opacity: 0.7;
}

.reputation__table th {
    font-family: 'Poppins-Medium';
    color: #212529;
    font-size: 16px;
    opacity: 1;
}

/*END OF KASTRIOT TABLE*/
.suggestion_wrapper {
    display: flex;
    gap: 15px;
}

.setup-suggestion-sec_el {
    min-height: 110px;
    background: #fff;
    width: 100%;
    border: 0.6px solid #B0B7C336;
    border-radius: 9px;
    z-index: 1;
    position: relative;
}

.setup-suggestion-first_el {
    background: #fff;
    width: 215px;
    height: 215px;
    border-radius: 100%;
    border: 0.6px solid #B0B7C336;
    margin-left: 18px;
    top: -108px;
    position: absolute;
    z-index: 1;
}

.setup-suggestion-container {
    width: 100%;
    position: relative;
}

.chart_wrap .label_text {
    position: absolute;
    top: 54px;
    /* left: 23%; */
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins-Regular';
    margin: 0;
    font-size: 15px;
}

.chart_wrap {
    position: absolute;
    top: -97px;
    left: 28px;
}

.chart_wrap .label_text .total_text {
    font-family: 'Poppins-Light';
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #18527D;
    text-align: center;
}

.chart_wrap .label_text .value_number {
    position: relative;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 29px;
    color: #18527D;
    padding-top: 15px;
}

.choose-loc-text {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    color: #25313C;
    margin: 0;
    text-align: center;
}

.suggestion_wrapper .chart_wrap {
    width: 220px;
    padding-left: 50px;
}

/* .connection_cards_wrapper {
    margin-left: 2.3em;
} */
/* .setup_connection_card::before{
    content: "";
    display: block;
    background: #B0B7C336;;
    width: 97%;
    position: absolute;
    bottom: 0;
    margin: auto;
    height: 1px;
} */
.setup_connection_card {
    /* flex: 1 0 30%; */
    box-sizing: border-box;
    height: -moz-fit-content;
    height: fit-content;
    padding: 7px 20px 7px 15px;
    font-size: 14px;
    color: #25313C;
    font-weight: 500;
    font-family: "Poppins-Medium";
    position: relative;
    border-bottom: 1px solid #fefe;
    flex: 0 0 33.3333%;
}

.setup_connection_card img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    object-fit: cover;
}

/* .setup_connection_card.show_border::after{
    content: "";
    position: absolute;
    right: 0;
    height: 78%;
    width: 1px;
    top: 4px;
    background-color: #B0B7C336;
} */

.setup_connection_card.show_border {
    border-right: 1px solid #fefe;
    border-left: 1px solid #fefe;
}

.setup_connection_card .circle {
    width: 15px;
    height: 15px;
    margin: 10px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    background: #ECECEC;
}

.setup_connection_card .circle.active {
    background-color: #01ba98;
}

.setup_connection_card .circle svg {
    color: #fff;
}

.setup_connection_card .connection_link {
    text-decoration: none;
    font-family: 'Poppins-Medium';
    font-size: 13px;
    color: #25313C;
}

.setup_connection_card .connection_link h4 {
    margin: 0;
    white-space: nowrap;
}

.blue-hyperlink {
    color: #00B4E5;
    font-family: "Poppins-Regular";
    font-size: 13px;
    text-decoration: none;
    display: inline-block;
}

.suggestion_wrapper .hide_btn {
    min-height: 30px;
    border: 0px;
    color: #00B4E5;
    background: rgba(15, 119, 151, 0.1);
    font-family: "Poppins-Regular";
    font-size: 13px;
    min-width: 100px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    padding: 0 12px;
    margin: 18px 0;
}

/* Connections page */
.connections_page .header-container h1,
.connections_settings_page .header-container h1 {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 1.2em;
    color: #0c2640;
}

.connections_page .header-container h6,
.connections_settings_page .header-container h6 {
    margin: 0px;
    font-family: "Poppins-Light";
    font-size: 0.8em;
    color: #0c2640;
}

.connections_page .connect_btns_wrapper {
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.connections_page .connect_btns_wrapper .connect_btn {
    color: #00B2E3;
    font-family: "Poppins-Regular";
    background-color: #fff;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 3px;
    outline: 0px;
    border: 1px solid #00B2E3;
    cursor: pointer;
    margin-left: 10px;
    line-height: 29px;
    text-decoration: none;
    min-width: 90px;
    text-align: center;
}

.connections_page .foot p {
    font-family: "Poppins-Regular";
    font-size: 14px;
    text-align: center;
    margin-top: 40px;
}

/* .connections_settings_page .alert_wrapper {
    margin: 30px 0;
} */

.connections_settings_page .alert_wrapper .css-1pxa9xg-MuiAlert-message {
    font-family: 'Poppins-Regular';
}

.accounts_settings_wrap {
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 2%);
    border-radius: 5px;
    padding: 30px 20px;
}

.accounts_settings_wrap .title {
    font-family: 'Poppins-Regular';
    font-size: 18px;
    margin: 0;
    margin-bottom: 15px;
    color: #0c2640;
}

.accounts_settings_wrap label {
    font-family: 'Poppins-Light';
    margin-bottom: 12px;
    font-size: 16px;
    display: block;
}

.account_settings_page .submit-btn {
    background-color: #18AEE3;
    border: 0px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Poppins-Light';
    cursor: pointer;
    font-size: 14px;
    margin-top: 25px;
}

.suggestion_wrapper .css-1medlio-MuiTypography-root {
    font-family: 'Poppins-Regular';
}

.full-width {
    max-width: 100% !important;
    flex-basis: 100% !important;
}

.connection_cards_wrapper {
    padding: 0 30px;
}

.full-width .connection_cards_wrapper {
    display: flex;
    flex-wrap: wrap;
}

/* Connections page */

/* welcome to iReview section */
.welcome_section_wrap {
    width: 100%;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 5px;
    padding: 40px 20px 20px 20px;
    /* margin-top: 40px; */
}

.not-valid-flex button,
.connect-google-b {
    background: #fff;
    border: 0px;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    cursor: pointer;
    box-shadow: 0 0 2px rgb(0 0 0 / 6%), 0 1px 1px rgb(0 0 0 / 5%);
    min-height: 45px;
    font-size: 13px;
    text-decoration: none;
    color: #0c2640;
    box-sizing: border-box;
    width: fit-content;
}

.connect-google-b {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    width: fit-content;
}

.connect-google-b img {
    margin-right: 10px;
}

.not-valid-flex {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    background: #FFF3E5;
}

.not-valid-buttons {
    display: flex;
    flex-direction: column;
    --webkit-flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-valid-buttons span {
    font-family: "Poppins-Regular";
    margin-bottom: 10px;
    font-size: 14px;
}

.not-valid-flex p {
    font-family: 'Poppins-Light';
    font-size: 14px;
    width: 80%;
    line-height: 33px;
    margin-top: 0;
}

.did_you_mean_buttons>button:first-child {
    background: rgb(1, 186, 152);
    color: #fff;
    margin-right: 15px;
    padding: 15px 15px;
    line-height: 0;
    min-height: 32px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.did_you_mean_buttons>button:first-child>span {
    margin-right: 18px;
}

.did_you_mean_buttons>button:first-child:hover {
    background: rgb(5 212 174);
}

.did_you_mean_buttons>button:last-child:hover {
    background-color: rgb(255 25 37);
}

.did_you_mean {
    padding: 18px;
}

.did_you_mean_buttons {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.ignore-notvalid {
    height: fit-content;
    --webkit-align-self: flex-end;
    margin-bottom: 0px;
    background-color: rgb(255, 151, 30) !important;
    color: rgb(255, 255, 255) !important;
    justify-self: flex-end;
    --webkit-justify-self: flex-end;
}

.did_you_mean_buttons>button:last-child {
    background: rgb(201, 28, 37);
    color: #fff;
    margin-right: 15px;
    padding: 15px 15px;
    line-height: 0;
    min-height: 32px;
    width: 52px;
    margin-bottom: 0;
}

.welcome_section_wrap .title {
    margin: 0px;
    font-family: "Poppins-Bold";
    font-size: 1.2em;
    color: #0c2640;
}

.did_you_mean p span {
    color: #173151;
    font-weight: 500;
    font-family: 'Poppins-Medium';
}

.welcome_section_wrap p {
    font-family: 'Poppins-Light';
    font-size: 14px;
    font-size: 16px;
    color: #6d6565;
    margin: 0;
}

.welcome_section_wrap p.green_color {
    color: rgb(120, 181, 90);
    font-family: 'Poppins-Bold';
}

/* welcome to iReview section */
/*Competitor*/
.competitor-box-item {
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    border-radius: 4px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.refresh-competitor-spinner>img {
    animation: spin 1s linear infinite;
}

.refresh-competitor {
    background: rgba(0, 180, 229, 0.05);
    border: 1px solid rgba(0, 180, 229, 0.15);
    border-radius: 8px;
    height: 44px;
    color: #00B4E5;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
    cursor: pointer;
}

.refresh-competitor>img {
    margin-right: 10px;
}

.my-loc .competitor-box-item {
    background: rgba(0, 180, 229, 0.05);
    border: 1px solid rgba(0, 180, 229, 0.15);
    box-shadow: 0px 12px 22px rgb(176 183 195 / 9%) !important;
}

.competitor-box-item>h1 {
    margin: 0;
    font-family: 'Poppins-Medium';
    font-size: 1.2em;
    margin-top: 10px;
}

.sort-reviews-date {
    position: absolute;
    right: 0;
    top: -36px;
    font-family: 'Poppins-Light';
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    --webkit-align-items: "center";
    padding: 3px 12px;
}

.sort-reviews-date>svg {
    color: #00B2E3;
    margin-right: 3px;
}

.sort-reviews-date>span {
    font-family: "Poppins-Medium";
    font-size: 14px;
    margin-left: 5px;
}

.competitor-box-item>h6 {
    margin: 0;
    font-family: 'Poppins-Regular';
    font-size: 0.9em;
    margin-top: 3px;
    opacity: 0.9;
}

.competitor-box-item-chart {
    margin-top: 10px;
}

.competitor-box-item-award {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-around;
}

.competitor-box-item-award>div {
    text-align: center;
}

.competitor-box-item-award>img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.competitor-box-item-award h6 {
    margin: 0;
    font-family: 'Poppins-Light';
    font-size: 1.1em;
}

.competitor-box-item-award span {
    font-family: 'Poppins-Medium';
    font-size: 1.1em;
}

.competitor-box-item-rating {
    margin-top: 30px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.competitor-box-item-rating svg {
    font-size: 2.2em;
    color: #faaf00;
    margin-right: 15px;
}

.competitor-box-item-rating p {
    font-size: 1em;
    margin: 0px;
    font-family: "Poppins-Light";
}

.competitor-box-item-keywords>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    flex-wrap: wrap;
}

.competitor-box-item-keywords>div>span {
    color: #18aee3;
    background-color: rgba(24, 174, 227, .05) !important;
    border: 1px solid #18aee3;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 5px;
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 5px;
}

.competitor-box-item-keywords {
    margin-top: 25px;
}

.competitor-box-item-keywords>h4 {
    margin: 0px 0px 10px 0px;
    font-family: "Poppins-Light";
    font-size: 1.2em;
}

.competitor-box-item-bottom {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    --webkit-justify-content: space-between;
    justify-content: space-between;
    column-gap: 3px;
}

.competitor-box-item-bottom>div {
    text-align: center;
}

.competitor-box-item-bottom>div span:first-child {
    font-family: "Poppins-Light";
    font-size: 1.1em;
    display: block;
}

.competitor-box-item-bottom>div p {
    margin: 7px 0px 0px 0px;
    font-family: "Poppins-Light";
    font-size: 1.1em;
}

.competitor-box-item-bottom>div span:last-child {
    width: 100%;
    display: block;
    height: 12px;
    border-radius: 2px;
    margin-top: 10px;
}

.competitor-box-item-bottom>div span.positive {
    background-color: #06D49D;
}

.competitor-box-item-bottom>div span.neutral {
    background-color: #FFC107;
}

.competitor-box-item-bottom>div span.negative {
    background-color: #ED476E;
}

.no-competitor-box-item {
    display: flex;
    --webkit-justify-content: center;
    --webkit-align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
}

.no-competitor-box-item>img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.no-competitor-box-item>p {
    font-family: "Poppins-Regular";
    font-size: 1em;
}

.no-competitor-box-item>p>span {
    font-family: "Poppins-Bold";
}

.no-competitor-box-item>button,
.competitor-box-item-keywords-search>button {
    background-color: #00B2E3;
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: "Poppins-Light";
    cursor: pointer;
    border: 0px;
}

.no-competitor-box-item>button {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.no-competitor-box-item>button>img {
    margin-right: 11px;
}

.competitor-box-item-keywords-search>button {
    padding: 8px 15px;
}

.competitor-box-item-keywords-search {
    margin-bottom: 10px;
}

.competitor-box-item-keywords-search>input {
    padding: 0.6em 0.8em;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-family: "Poppins-Regular";
    width: calc(100% - 100px);
    margin-right: 5px;
}

.no-review-container {
    position: relative;
    text-align: center;
}

.no-review-container>p>span {
    font-weight: 600;
    display: block;
}

.no-review-container>img {
    max-width: 100%;
    object-fit: contain;
    margin-top: 10px;
    width: 300px;
}

.no-review-container p {
    font-size: 1em;
    font-family: "Poppins-Light";
}

.no-review-container>.MuiButtonBase-root {
    right: -20px;
}


/* Platforms dropdown */
.platforms-dropdown-holder {
    position: absolute;
    /* width: 328px; */
    width: 417px;
    min-height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-top: 28px;
    left: -240px !important;
    /* left: -178px !important; */
    /* padding: 15px; */
    z-index: 9999;
    display: none;
    text-align: left;
    transition: 0.4s ease-in-out;
    max-height: calc(100vh - 270px);
    overflow: hidden;
}

@media only screen and (min-width: 2000px) {
    .platforms-dropdown-holder {
        max-height: calc(100vh - 50px);
    }
}

.platforms-dropdown-holder.overflow-show {
    overflow: auto;
}

.platforms-dropdown-holder::-webkit-scrollbar {
    width: 7px;
    background-color: #e9e6e6;
    border-radius: 12px;
}

.platforms-dropdown-holder::-webkit-scrollbar-track {
    background: #D4D8DF;
}

.platforms-dropdown-holder::-webkit-scrollbar-thumb {
    background: rgba(186, 187, 189, 0.5);
}

.platforms-dropdown-holder.show {
    display: block;
}

.platforms-dropdown-holder.show .scrollable-div-container {
    height: unset;
    /* height: 555px;
    overflow-y: auto; */
}

.platforms-dropdown-holder .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 8px;
    border-bottom: 1px solid #e7e7e7;
    padding: 16px 21px;
}

.platforms-dropdown-holder.show .item {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.platforms-dropdown-holder.show .item.delay-0 {
    transition-delay: 0s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-1 {
    transition-delay: 0.1s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-2 {
    transition-delay: 0.2s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-3 {
    transition-delay: 0.3s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-4 {
    transition-delay: 0.4s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-5 {
    transition-delay: 0.5s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-6 {
    transition-delay: 0.6s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-7 {
    transition-delay: 0.6s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-8 {
    transition-delay: 0.6s;
    opacity: 1;
    transform: translateY(0);
}

.platforms-dropdown-holder.show .item.delay-9 {
    transition-delay: 0.6s;
    opacity: 1;
    transform: translateY(0);
}


.platforms-dropdown-holder .item:hover {
    background: #F9F9F9;
}

.platforms-dropdown-holder .item:last-child {
    padding-bottom: 30px;
}

.platforms-dropdown-holder .item svg {
    color: red;
    font-size: 30px;
}

.platforms-dropdown-holder .item .ipaymer-link,
.platforms-dropdown-holder .item .urless-link {
    cursor: not-allowed;
}

.platforms-dropdown-holder .item .ipaymer-link svg,
.platforms-dropdown-holder .item .urless-link svg {
    color: #a7a4a4;
}

.platforms-dropdown-holder>div>label {
    display: block;
    font-size: 24px;
    line-height: 36px;
    font-family: "Poppins-Semibold";
    margin-bottom: 12px;
    color: #173151;
    padding: 24px 19px 0 19px;
}

.platforms-dropdown-holder a {
    display: flex;
    align-items: center;
    gap: 12px;
    /* margin-right: 0px;
    width: 160px; */
    text-decoration: none;
}

.platforms-dropdown-holder .item h4 {
    font-family: 'Poppins-Semibold';
    font-size: 16px;
    line-height: 20.8px;
    color: #131312;
    margin: 0;
}

.platforms-dropdown-holder .item h6 {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 13px;
    color: #13131280;
    margin: 0;
}

.platforms-dropdown-holder .platform-holder-icon {
    margin: 0;
}

.platforms-dropdown-holder a img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.platforms-dropdown-holder a span {
    font-family: 'Poppins-Regular';
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    text-align: center;
    padding-top: 5px;
}

/* .platforms-dropdown-holder::after {
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #ffffff;
    border-left: 9px solid transparent;
} */

/* Platforms dropdown */

/* Handle File Upload */
.handle-file-upload .image-uploaded {
    border: 1px dashed #18AEE3;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 223px;
    height: 95px;
    margin-right: 20px;
}

.handle-file-upload .image-uploaded img {
    width: 180px;
    height: 60px;
    object-fit: contain;
}

.handle-file-upload input[type='file'] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.handle-file-upload .upload-btn {
    background: #FFFFFF;
    border: 1px solid #CCF2FF;
    border-radius: 5px;
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #18AEE3;
}

/* Handle File Upload */

/* Subscription */
.stepper-body-sub {
    margin-top: 90px;
}

.stepper-body-sub .cart-details-body {
    padding: 20px;
}

.stepper-body-sub .cart-form-inputs>input {
    height: 38px;
}

.sub_container>h4 {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 35.519px;
    line-height: 39px;
    letter-spacing: -1.18397px;
    color: #0F172A;
    margin-bottom: 5px;
    margin-top: 0px;
}

.sub_container>span {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11.6557px;
    line-height: 18px;
    color: #4B5262;
}

.sub_container_div {
    background: #FFFFFF;
    border: 0.7px solid #F2F4F7;
    box-shadow: 0px 8.78731px 11.7164px -2.9291px rgb(16 24 40 / 8%), 0px 2.9291px 4.39366px -1.46455px rgb(16 24 40 / 3%);
    border-radius: 5px;
    margin-top: 20px;
}

.sub_search_container>input {
    width: 100%;
    border: 0px;
    height: 40px;
    padding: 10px;
}

.sub_search_container::before {
    content: url("../../public/admin-icons/new_search_nav.svg");
    position: absolute;
    left: 15px;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
}

.sub_search_container {
    padding-left: 40px;
    position: relative;
}

.sub_locations_results {
    background: #F9FAFB;
    padding: 15px 10px 0px 10px;
    max-height: 390px;
    overflow: auto;
}

.sub_locations_results>ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.sub_locations_results>ul>li p {
    margin: 0px;
}

.sub_locations_results>ul>li {
    padding-bottom: 15px;
}

.sub_locations_results>ul>li label span,
.sub_plan_list_item label>div>span,
.sub_plan_list_item_children label>div:first-child>div>span {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #00B2E3;
}

.alert-inside-plan {
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #ff1414;
    margin-top: 10px;
    display: block;
}

.a-la-carte-items {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.sub_plan_list_item.locked,
.sub_plan_list_item_children.locked {
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
}

.sub_locations_results>ul>li.blueCheckbox label span,
.sub_plan_list_item label>div>span,
.sub_plan_list_item_children label>div:first-child>div>span {
    border: 1px solid #00B2E3;
}

.sub_locations_results>ul>li.greenCheckbox label span {
    border: 1px solid #28a745;
}

.sub_locations_results>ul>li.greenCheckbox label span svg {
    color: #28a745;
}

.sub_locations_results>ul>li.greenCheckbox input:checked+label span svg {
    color: #fff;
}

.locked>label>div:last-child>h5 {
    font-size: 12px;
}

.locked>label {
    padding: 20px 18px !important;
}

.subs-details-wrapper {
    font-family: "Poppins-Regular";
    font-size: 12px;
    padding: 16px;
    background: #8080800a;
    margin-top: 10px;
    display: block;
    border-radius: 5px;
}

.subs-details-wrapper>div {
    margin-bottom: 5px;
}

.subs-details-wrapper>div>span {
    font-family: "Poppins-SemiBold";
    margin-right: 7px;
}

.rotate-span .collapse-details-sub svg {
    transform: rotate(180deg);
}

.locked>label>div:last-child {
    align-items: flex-end;
    flex-direction: column;
}

.locked>label>div:last-child>h6 {
    font-size: 14px;
}

.sub_locations_results>ul>li.greenCheckbox input:disabled+label {
    opacity: 0.6;
    pointer-events: none;
}

.sub_locations_results>ul>li>div:first-child {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.sub_locations_results>ul>li>div:first-child>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.collapse-details-sub {
    color: #a1a1a1;
    margin-top: 1px;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
}

.edit-card-wrapper>svg {
    opacity: 0.8;
    font-size: 13px;
    cursor: pointer;
    margin-left: 5px;
    display: none;
}

.edit-card-wrapper:hover>svg {
    display: inline-block;
}

.sub_locations_results>ul>li div h6 {
    margin: 0;
    font-family: "Poppins-Regular";
    align-self: center;
    margin-left: auto;
    font-size: 12px;
    padding: 2px 4px;
    width: fit-content;
    border-radius: 20px;
    background: #00800005;
    white-space: nowrap;
}

.sub_locations_results>ul>li>div h6.redSpan {
    color: #d51e01;
    border: 1px solid #d51e01;
}

.sub_locations_results>ul>li>div h6.greenSpan {
    color: #4BB543;
    border: 1px solid #4BB543;
}

.sub_locations_results>ul>li.blueCheckbox input:checked+label span,
.sub_plan_list_item input:checked+label>div>span,
.sub_plan_list_item_children input:checked+label>div:first-child>div>span {
    background-color: #00B2E3;
    color: #fff;
}

.sub_locations_results>ul>li.greenCheckbox input:checked+label span {
    background-color: #28a745;
    color: #fff;
}

.sub_locations_results>ul>li label span svg,
.sub_plan_list_item label>div>span svg {
    font-size: 30px;
}

.sub_locations_results>ul>li label p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #101828;
}

.sub_locations_results>ul>li label {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
}

.sub_locations_results>ul>li input {
    display: none;
}

.count-locations {
    display: inline-block;
    width: fit-content;
    text-align: center;
    padding: 5px 12px;
    background: #00B2E3;
    color: #fff;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 3%);
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
}

.sub_plan_list {
    margin-top: 20px;
}

.sub_plan_list_item input,
.sub_plan_list_item_children input {
    display: none;
}

.sub_plan_list_item input:checked+label,
.sub_plan_list_item_children input:checked+label {
    border-color: #00B2E3;
}

.sub_plan_list_item label,
.sub_plan_list_item label>div,
.sub_plan_list_item_children label>div:first-child,
.sub_plan_list_item_children label>div:first-child>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.sub_plan_list_item label,
.sub_plan_list_item_children>label {
    justify-content: space-between;
    --webkit-justify-content: space-between;
    position: relative;
    background: #FFFFFF;
    border: 0.591983px solid #E5EAF1;
    border-radius: 7.1038px;
    margin-bottom: 12px;
    padding: 25px 18px;
    cursor: pointer;
}

.sub_plan_list_item_children>label {
    display: flex;
    flex-direction: column;
}

.sub_plan_list_item label h6,
.sub_plan_list_item_children label>div:first-child h6 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0F172A;
    margin-left: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.period-wrapper {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.sub-period-wrapper {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #101828;
    font-weight: 500;
    margin-bottom: 10px;
}


.sub_plan_list label h5 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 22px;
    line-height: 15px;
    color: #606571;
}

.sub_plan_list label h5 span {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 9.47173px;
    line-height: 15px;
    color: #606571;
}

.agreements-list {
    padding: 0 20px;
    list-style: none;
}

.agreements-list>span {
    font-family: "Poppins-Regular";
    font-size: 16px;
    padding: 20px 10px;
    display: block;
}

.agreements-list>li {
    font-family: "Poppins-Regular";
    font-size: 14px;
    margin-bottom: 20px;
    cursor: pointer;
}

.agreements-list>li>span:last-child {
    margin-left: 5px;
}

.sub-bottom-right>div:not(.contract_wrapper),
.sub-bottom-right>.total_div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    padding: 10px 0px;
}

.sub-bottom-right .des_text {
    margin: 15px 0;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-left: 8px;
}

.sub-bottom-right .contract_wrapper {
    display: flex;
    align-items: center;
}

.sub-bottom-right .des_text button {
    text-decoration: underline;
    border: 0;
    background: unset;
    padding: 0;
    cursor: pointer;
}

.sub_total_div>h4,
.discount_div>h4 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.84);
}

.sub_total_div>span {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 13.47173px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.84);
}

.discount_div>span {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 13.47173px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.84);
}

.total_div>h4 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 18px;
    line-height: 14px;
    margin: 0px;
    color: #00B2E3;
}

.total_div>span {
    font-family: 'Poppins-Bold';
    font-size: 18px;
    line-height: 15px;
    color: #00B2E3;
}

.all-selected li.MuiButtonBase-root:not(:first-child) {
    background-color: #00b4e56b !important;
}

.breake-option-words li {
    white-space: normal;
    color: #333333;
    font-family: "Poppins-Regular";
    font-size: 14px;
}

.group-list-wrapper {
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    padding: 14px;
    margin-bottom: 30px;
}

.group-list-wrapper table {
    width: 100%;
}

.group-list-wrapper th {
    color: #0C2640;
    font-size: 17px;
    margin: 0px;
    font-family: "Poppins-Medium";
    margin-bottom: 10px;
    text-align: left;
}

.group-list-wrapper td {
    color: #000;
    margin: 0;
    font-family: 'Poppins-Light';
    padding: 5px 0px;
}

.group-list-wrapper button {
    background: transparent;
    padding: 5px;
    border: 0px;
    cursor: pointer;
}

.add-group-wrapper {
    flex-direction: column;
    width: fit-content;
    margin: auto;
    height: fit-content;
    width: 600px;
    height: 400px;
    background: #00b4e517;
    border-radius: 15px;
    font-family: "Poppins-SemiBold";
    font-size: 20px;
    cursor: pointer;
    color: #00b4e58a;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
    max-width: 90%;
}

.group-wrapper-existing input {
    display: none;
}

.group-wrapper-existing {
    margin: 20px;
}

.group-wrapper-existing>div:first-child {
    display: flex;
    align-items: center;
    position: relative;
}

.group-wrapper-existing h6 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    margin: 10px 0px 10px 20px;
}

.group-wrapper-existing svg {
    padding: 10px;
    position: absolute;
    font-size: 15px;
    right: 0;
    cursor: pointer;
}

.group-wrapper-existing input:checked+label {
    border: 1px solid #00B0FF;
}

.group-wrapper-existing label {
    width: 100%;
    padding: 15px 10px;
    font-family: "Poppins-Regular";
    display: block;
    box-sizing: border-box;
    background: #00c2f713;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #00c2f713;
}

.add-group-wrapper img {
    width: 200px;
}

.add-group-wrapper div {
    padding-top: 20px;
}

.blue-btn-sub {
    margin-top: 8px;
    background: #00B2E3;
    color: #fff;
    border-radius: 3px;
    font-family: 'Poppins-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    width: 100%;
    padding: 8px 15px;
    border: 0px;
    min-height: 53px;
    cursor: pointer;
}

.sub-question-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub-question-body h4 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.84);
    margin-bottom: 15px;
}

.sub-question-body>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.sub-question-body>div button {
    border: 1px solid #00B2E3;
    font-size: 14px;
    font-family: "Poppins-Regular";
    border-radius: 4px;
    color: #00B2E3;
    padding: 5px 20px;
    cursor: pointer;
}

.sub-question-body>div button:first-child {
    background-color: #fff;
    color: #00B2E3;
    margin-right: 10px;
}

.sub-question-body>div button:last-child {
    background-color: #00B2E3;
    color: #fff;
}

.cart-details-container {
    border-radius: 15px;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    border: 0;
    width: 100%;
}

.cart-header-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    border-bottom: 1px solid #E7E7E7;
    position: relative;
    min-height: 88px;
    box-sizing: border-box;
}

.cart-header-wrapper>button {
    position: absolute;
    right: 20px;
}

.card_details_wrapper {
    padding: 0 20px;
    border-bottom: 1px solid rgba(149, 157, 165, 0.2);
}

.card_details_wrapper>h6 {
    color: #062536;
    font-size: 14px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    margin: 12px 0;
}

.cart-header-wrapper>button {
    background: #EAF5FF;
    border: 0px;
    padding: 13px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cart-header-wrapper>button>svg {
    font-size: 22px;
    color: #009EF8;
}
.card-list-container{
    display: flex;
    align-items: center;
    padding: 20px 20px 0 20px;
    justify-content: space-between;
}
.card-list-container > div:last-child{
    display: flex;
    align-items: center;
}
.sliding-button svg{
    border: 1.24px solid #E4E4E4;
    border-radius: 100%;
    color: #E4E4E4;
    margin: 0 5px;
    cursor: pointer;
}
.cart-details-header {
    background: #F1FAFD;
    border: 1px solid #00B2E3;
    border-radius: 8px 8px 0px 0px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 12.8154px;
    line-height: 18px;
    color: #565656;
    padding: 0.75rem 1.25rem;
}
.card-list-wrapper>div:first-child{
    display: flex;
    align-items: center;
}
.card-add-item{
    background: #AFD9E440;
    border-radius: 3px;
    padding: 7px 13px;
    color: #00B2E3;
    font-size: 12px;
    font-family: "Poppins-Light";
    font-weight: 400;
    border: 0px;
    cursor: pointer;
    white-space: nowrap;
    margin-left: auto;
}
.cart-details-header>h3 {
    margin: 0;
}
.card-list-wrapper::-webkit-scrollbar {
    display: none !important;
}

.card-list-wrapper{
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.card_error{
    font-size: 11px;
    font-family: "Poppins-Regular";
    color: #ff3d3d;
    margin: 0;
    display: block;
}
.card-list-el > input{
    display: none;
}
.card-list-el > input:checked + label > span{
    border: 3px solid #00B2E3;
}
.card-list-el > input:checked + label{
    border: 1px solid #00B2E3;
}
.card-list-el > label{
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 6px 9px;
    border: 0.6px solid #E4E4E4;
    border-radius: 3px;
    margin-right: 3px;
}
.card-list-el > label > p{
    white-space: nowrap;
    margin-right: 19px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    color: #535353;
    font-weight: 600;
    font-family: "Poppins-Bold";
}
.card-list-el > label > span{
    width: 10px;
    height: 10px;
    background: #E4E4E4;
    border-radius: 100%;
    display: block ;
    margin-right: 5px;
    box-sizing: border-box;
}
.card-list-el > label > p > span{
    font-weight: 400;
    font-family: "Poppins-Regular";
}
.cart-details-body-top {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 1rem;
    margin-bottom: 35px;
}

.cart-details-body-top>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    flex-wrap: wrap;
}

.cart-details-body-top>div>h5 {
    margin: 0px;
    position: relative;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.84);
    padding-right: 6px;
}

.cart-details-body-top>div>h5::after {
    content: '';
    border: 0.72px solid #E4E4E4;
    margin-left: 10px;
}

.cart-details-body-top>div>span {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    font-family: 'Poppins-Medium';
    color: #00B2E3;
}

.cart-details-body-top>div>img:not(:last-child) {
    margin-right: 15px;
}

.cart-form-inputs {
    display: block;
    margin-bottom: 16px;
}

.cart-info-details {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.collapsing-wrapper .cart-info-details>div.cart-form-inputs {
    flex: 1;
}

.cart-form-inputs h6 {
    font-family: 'Poppins-Medium';
    margin-top: 6px;
    margin-bottom: 5px;
}

.cart-expiry-details {
    display: flex;
}

.cart-expiry-details>.cart-form-inputs {
    width: 48px;
    margin-right: 8px;
}

.cart-expiry-details>.cart-form-inputs:last-child {
    width: 60px !important;
}

.cart-form-inputs>input::placeholder {
    color: #555555;
    font-family: "Poppins-Light";
    font-size: 12px;
}

.card-details-footer {
    padding: 20px;
}

.card-details-footer>button {
    display: flex;
    height: 44px;
    border-radius: 5px;
    color: #fff;
    background-color: #00B2E3;
    font-family: "Poppins-Medium";
    align-items: center;
    font-size: 12px;
    width: 100%;
    border: 0px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    text-transform: capitalize;
}

.card-details-footer>button:disabled {
    background-color: #BDBEBF;
    cursor: initial;
}

.card-details-footer>button>svg {
    margin-left: 10px;
}

.cart-form-inputs>label,
.cart-info-details>div:first-child>label {
    font-family: 'Poppins-Light';
    font-size: 12px;
    margin-bottom: 2px;
    display: block;
}

.disabled-wallet-btn {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
}

.cart-form-inputs input {
    width: 100%;
    border: 0.652235px solid #E0E0E0;
    border-radius: 3.91341px;
    height: 32px;
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    border-color: #EAEAEA;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #1A1A1A;
    font-family: 'Poppins-Regular';
}

.cart-form-inputs input:disabled {
    cursor: not-allowed;
}

.security_code_inp {
    position: relative;
}

.security_code_inp input {
    padding-left: 30px;
}

.security_code_inp img {
    position: absolute;
    left: 8px;
    top: 9px;
}

.save-el-btn>img {
    margin-right: 5px;
    width: 13px;
}

.card_details_wrapper .cart-form-inputs input {
    background-color: #F9F9F9;
}

.collapsing-wrapper {
    padding: 0 20px;
    border-bottom: 1px solid rgba(149, 157, 165, 0.2);
}

.collapsing-wrapper>h6 {
    color: #062536;
    font-size: 12px;
    font-family: "Poppins-Regular";
    margin: 12px 0px;
}

.save-el-btn {
    height: 44px;
    border-radius: 5px;
    background-color: #00B2E3;
}

.save-el-btn:hover {
    background-color: #00B2E3 !important;
}

.card-title {
    font-family: 'Poppins-Medium';
    padding-bottom: 25px;
    margin: 0px;
    font-size: 18px;
    color: #18AEE3;
    padding-left: 20px;
    padding-right: 20px;
}

.cart-form-inputs input:focus {
    border-color: #00B2E3;
}

.jp-card-container {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    width: 350px;
    max-width: 100%;
    height: 200px;
    margin: auto;
    z-index: 1;
    position: relative;
}

.jp-card {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1;
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 315px;
    border-radius: 10px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    transition: all 400ms linear;
}

.jp-card .jp-card-back,
.jp-card .jp-card-front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    transition: all 400ms linear;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px;
    background: #ddd;
}

.jp-card .jp-card-back:after,
.jp-card .jp-card-front:after {
    content: " ";
    display: block;
}

.jp-card .jp-card-back:after {
    content: " ";
    display: block;
    background-color: #fff;
    background-image: -webkit-linear-gradient(#fff, #fff);
    background-image: linear-gradient(#fff, #fff);
    width: 80%;
    height: 16%;
    position: absolute;
    top: 40%;
    left: 2%;
}

.jp-card .jp-card-back:before,
.jp-card .jp-card-front:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 10px;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
}

.jp-card .jp-card-back .jp-card-shiny,
.jp-card .jp-card-front .jp-card-shiny {
    width: 50px;
    height: 35px;
    border-radius: 5px;
    background: #ccc;
    margin-bottom: 15px;
    position: relative;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before {
    content: attr(data-before);
    margin-bottom: 5px;
    font-size: 7px;
    text-transform: uppercase;
}

.jp-card .jp-card-back .jp-card-display.jp-card-focused,
.jp-card .jp-card-front .jp-card-display.jp-card-focused {
    opacity: 1;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
}

.jp-card .jp-card-back .jp-card-shiny:before,
.jp-card .jp-card-front .jp-card-shiny:before {
    content: " ";
    display: block;
    width: 70%;
    height: 60%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #d9d9d9;
    position: absolute;
    top: 20%;
}

.jp-card .jp-card-back .jp-card-shiny {
    position: absolute;
    top: 66%;
    left: 2%;
}

.jp-card .jp-card-back .jp-card-shiny:after {
    position: absolute;
    left: 120%;
    top: 5%;
    color: white;
    font-size: 7px;
    width: 230px;
    opacity: 0.5;
}

.jp-card .jp-card-back .jp-card-cvc {
    position: absolute;
    top: 40%;
    left: 85%;
    -webkit-transition-delay: 600ms;
    -moz-transition-delay: 600ms;
    transition-delay: 600ms;
    font-family: "Poppins-Regular";
    font-size: 14px;
}

.jp-card .jp-card-back .jp-card-bar {
    background-color: #444;
    background-image: -webkit-linear-gradient(#444, #333);
    background-image: linear-gradient(#444, #333);
    width: 100%;
    height: 20%;
    position: absolute;
    top: 10%;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-number {
    font-family: "Poppins-Regular";
    font-size: 24px;
    clear: both;
    margin-bottom: 30px;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-name {
    text-transform: uppercase;
    font-family: "Poppins-Regular";
    font-size: 15px;
    max-height: 45px;
    position: absolute;
    bottom: 0;
    width: 190px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jp-card-expiry {
    font-size: 16px;
}

.jp-card .jp-card-back .jp-card-display,
.jp-card .jp-card-front .jp-card-display {
    color: #fff;
    font-weight: normal;
    opacity: 0.5;
    -webkit-transition: opacity 400ms linear;
    -moz-transition: opacity 400ms linear;
    transition: opacity 400ms linear;
}

.jp-card .jp-card-front .jp-card-lower {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 30px;
}

.credit-card_modal .bottom .credit_card-info .jp-card .jp-card-back,
.jp-card .jp-card-front {
    background: linear-gradient(45deg, #101828 0%, #475467 100%);
    box-shadow: 8.43038px 10.538px 16.8608px rgb(0 0 0 / 8%);
    border-radius: 21.076px;
}

.jp-card .jp-card-back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
    font-family: "Poppins-Regular";
    letter-spacing: 0;
    position: relative;
    float: right;
    width: 25%;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after,
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before {
    font-family: "Poppins-Regular";
    font-weight: bold;
    font-size: 7px;
    white-space: pre;
    display: block;
    opacity: 0.5;
}

.jp-card.jp-card-flipped {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
    position: absolute;
    content: attr(data-after);
    text-align: right;
    right: 100%;
    margin-right: 5px;
    margin-top: 2px;
    bottom: 0;
}

.card-list-item {
    border-radius: 10px;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #F1FAFD;
    border: 0;
    padding: 20px;
    width: 100%;
    display: block;
    border: 1px solid #F1FAFD;
    transition: border-color .15s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
}

.card-list-sub {
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.card-list-item>div {
    margin-bottom: 10px;
}

.card-list-sub input {
    display: none;
}

.card-list-sub>div {
    min-width: 200px;
    margin: 5px;
    position: relative;
}

.card-list-sub>div>a {
    position: absolute;
    top: 10px;
    right: 12px;
    color: #000;
}

.card-list-sub input:checked+label {
    border-color: #00B0FF;
}

.card-list-item>div>h6 {
    margin: 0px;
    font-family: "Poppins-Medium";
    color: #00B2E3;
    font-size: 14px;
    margin-bottom: 5px;
}

.card-list-item>div>p {
    font-family: "Poppins-Medium";
    color: #2c2d2f;
    font-size: 12px;
}

.card-list-item>div span {
    font-family: "Poppins-Bold";
    color: #2c2d2f;
    font-size: 12px;
}

.card-number-img {
    background-position-y: center;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 13px);
    background-size: 27px;
}

.card-number-img[data-card="Unknown"] {
    background-image: url("../../public/admin-icons/unkown_card.svg");
}

.card-number-img[data-card="Visa"] {
    background-image: url("../../public/admin-icons/visa.svg");
}

.card-number-img[data-card="Mastercard"] {
    background-image: url("../../public/admin-icons/mastercard.svg");
}

.card-number-img[data-card="Maestro"] {
    background-image: url("../../public/admin-icons/maes.svg");
}

.card-number-img[data-card="Discover"] {
    background-image: url("../../public/admin-icons/discover.svg");
}

.card-number-img[data-card="Amex"] {
    background-image: url("../../public/admin-icons/amex.svg");
}

.accordion-container-items {
    display: block;
}

.accordion-container-items button {
    width: 100%;
    height: 45px;
    margin-bottom: 10px;
    border: 1px solid #00B2E3;
    color: #00B2E3;
    border-radius: 4px;
    font-family: "Poppins-Regular";
    background-color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.accordion-container-items button.active {
    background-color: #00B2E3;
    color: #fff;
}

.accordion-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
}

.card-action-holder {
    position: absolute;
    right: 10px;
    top: 10px;
}

.card-action-holder a {
    text-decoration: none;
    color: unset;
    margin-right: 10px;
}

.card-action-holder button svg {
    font-size: 15px;
    margin-left: 7px;
}

.card-action-holder button {
    cursor: pointer;
    background-color: transparent;
    border: 0px;
    padding: 0px;
}

.card-action-holder button:focus {
    outline: none;
}

.last-step-subscriptions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.last-step-subscriptions>div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.success-step-subscriptions>svg {
    font-size: 55px;
    color: #4BB543;
    margin-bottom: 20px;
}

.failed-step-subscriptions>svg {
    font-size: 55px;
    color: #CE383A;
    margin-bottom: 20px;
}

.last-step-subscriptions>div>h5 {
    margin: 0;
    font-family: "Poppins-Medium";
    font-size: 1.5em;
    margin-bottom: 5px;
}

.last-step-subscriptions>div>span {
    font-family: "Poppins-Light";
    font-size: 0.8em;
}

.upgrade-box-container {
    background: #fff;
    border-radius: 9px;
    padding: 30px;
    background-image: url("../../public/admin-icons/arrows-bg.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    text-align: center;
    box-shadow: 0px 0px 0px 1px #0000000D;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upgrade-box-container>div {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5px;
    line-height: 25px;
    color: #272727;
}

.upgrade-box-container a {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #000000;
    text-decoration: none;
    display: block;
}

.upgrade-box-container p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    width: 70%;
    color: #272727;
    max-width: 210px;
    text-align: center;
    line-height: 16px;
    margin: 2px auto 12px auto;
}

.unsubscribe-btn {
    background: transparent;
    border: 0px;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    position: absolute;
    bottom: 14px;
    color: #00B2E3;
    cursor: pointer;
}

.agree-terms {
    font-family: "Poppins-Regular";
    font-size: 14px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.agree-terms a {
    text-decoration: none;
    color: #00B2E3;
    margin-left: 5px;
}

.wrapper-analytics-phrases {
    max-width: 400px;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-align-items: center;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 9px;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
}

.wrapper-loc-unsubscribe {
    max-width: 700px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-align-items: center;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 30px;
    padding: 35px 0;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
}

.wrapper-loc-unsubscribe>div {
    padding: 0 35px;
    box-sizing: border-box;
}

.wrapper-loc-edit-cart {
    max-width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-align-items: center;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
    width: 500px;
}

.wrapper-loc-edit-cart .cart-details-container {
    box-shadow: unset;
}

.wrapper-loc-edit-cart .jp-card-container {
    margin-left: auto;
    margin-right: unset;
}

.wrapper-loc-edit-cart .custom-modal-footer {
    padding-right: 20px !important;
}

.wrapper-loc-unsubscribe label {
    font-family: "Poppins-Regular";
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
}

.wrapper-loc-unsubscribe>div {
    width: 100%;
}

.wrapper-loc-unsubscribe>div>div {
    margin-bottom: 30px;
}

.wrapper-loc-unsubscribe textarea {
    width: 100%;
    resize: none;
    border-radius: 4px;
    border: 0px;
    border: 1px solid rgba(166, 178, 205, .25);
    font-size: 14px;
    padding: 10px 26px 10px 12px;
    box-sizing: border-box;
    height: 80px;
    font-family: "Poppins-Regular";
}

.wrapper-loc-unsubscribe textarea:focus {
    outline: 0px;
}

.wrapper-loc-unsubscribe>div>h6 {
    font-family: "Poppins-Bold";
    color: #103254;
    font-size: 40px;
    margin-bottom: 15px;
    margin-top: 0px;
}

/* end of Subscription */

.setupsuggestion-modal {
    padding: 10px 0 0 20px;
}

.dialog-popup>svg {
    font-size: 45px;
    color: #00B2E3;
}

.dialog-popup p {
    font-family: 'Poppins-Regular';
    text-align: center;
    line-height: 26px;
    font-size: 15px;
    color: #101828;
}

.dialog-popup {
    height: 30vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    justify-content: center;
}

.setupsuggestion-modal .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setupsuggestion-modal .wrapper .ios-wrap {
    margin-bottom: 14px;
}

.setupsuggestion-modal .wrapper .android-wrap {
    width: 100%;
    text-align: center;
}

.setupsuggestion-modal .wrapper .title {
    font-family: 'Poppins-Regular';
    color: #0c2640;
    font-size: 16px;
    margin: 0 0 12px 0;
}

.social_ifram-wrapper iframe {
    border: none;
    width: 100%;
    height: 100vh;
}

.span-information {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    display: block;
    float: right;
    padding: 5px 0px;
}

/*NEXT BILLING*/
.next-billing-wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.next-billing-wrapper svg {
    font-size: 35px;
    color: #00B0FF;
}

.next-billing-wrapper h6 {
    margin: 0;
    font-family: 'Poppins-Regular';
    font-size: 14px;
}

.plan-span {
    font-weight: "Poppins-Bold";
}

.last-billed-span {
    color: #ff1414;
}

.next-billing-span {
    color: green;
}

.next-billing-wrapper h5 {
    margin: 0;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    margin-left: 10px;
}

.next-billing-wrapper span {
    margin: 0;
    font-family: 'Poppins-Medium';
    font-size: 12px;
}

.connections-logo-loc {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.next-billing-wrapper>div {
    background-color: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    padding: 20px;
    border-radius: 5px;
}

.next-billing-wrapper>div>div:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
}

.btn-aslink {
    background-color: transparent;
    border: 0px;
    font-family: "Poppins-Regular";
    color: #00B0FF;
    cursor: pointer;
}

.next-billing-wrapper>div>div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    --webkit-align-items: center;
}

.couponPreview {
    font-family: "Poppins-Regular";
}

.coupon-platform-name {
    display: flex;
    align-items: center;
    --webkit-aign-items: center;
    text-transform: capitalize;
}

.coupons_generated_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.coupons_generated_header>button {
    background: #E6EAED;
    border: 0px;
    border-radius: 4px;
    margin-left: 10px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    padding: 0 15px;
    color: #333333;
    height: 35px;
    cursor: pointer;
}

.coupons_generated_header>button:hover {
    background-color: #dbdbdb;
}

.coupon-platform-name>img {
    margin-right: 10px;
}

.wrapper-setup {
    max-width: 761px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-align-items: center;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 3px;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
}

.wrapper-setup-ai {
    max-width: 676px;
    height: 60vh;
    display: flex;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 3px;
    box-sizing: border-box;
    outline: unset;
    flex-direction: column;
}

.wrapper_setup_subscription_contract {
    max-width: 1420px;
    height: 90vh;
}

.wrapper_setup_subscription_contract .wrapper-setup-header {
    justify-content: center !important;
}

.wrapper_setup_subscription_contract h5,
.wrapper_setup_subscription_contract .bold_text_wrapper p {
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
}

.wrapper_setup_subscription_contract p {
    line-height: 25px !important;
}

.wrapper_setup_subscription_contract span {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.wrapper-setup-header {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 35px 35px 0px 35px;
    --webkit-justify-content: space-between;
}

.wrapper-setup-footer>button:first-child {
    color: #00B2E3;
    background-color: transparent;
    margin-right: 35px;
    cursor: pointer;
    border: 0px;
    font-size: 14px;
}

.wrapper-setup-footer a {
    text-decoration: none;
    background-color: #00B2E3;
    width: 100%;
    display: block;
    padding: 10px 20px;
    text-align: center;
    font-family: 'Poppins-Regular';
    color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
}

.wrapper-setup-footer a img {
    margin-right: 5px;
}

.wrapper-setup-body {
    height: calc(100% - 160px);
    padding: 0px 35px 0px 35px;
    overflow-y: auto;
    box-sizing: border-box;
}

.sender_workspace_body {
    height: calc(100% - 185px);
}

.no_workspace_wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_workspace_wrapper p {
    font-family: 'Poppins-Regular';
}

.no_workspace_wrapper p a {
    color: #00B2E3;
}

.wrapper-setup-body p {
    font-size: 16px;
    line-height: 32px;
    color: #23262F;
    font-family: "Poppins-Regular";
}

.wrapper-setup-header>img {
    width: 60px;
    margin-bottom: 15px;
}

.wrapper-setup-body>h3 {
    font-size: 16px;
    line-height: 32px;
    color: #23262F;
    font-family: "Poppins-Regular";
}

.wrapper-setup-body>h3>span {
    font-family: "Poppins-Bold";
}

.wrapper-setup-body-video {
    width: 100%;
    height: 60%;
    background: linear-gradient(0deg, rgba(15, 50, 83, 0.44), rgba(15, 50, 83, 0.44));
    border-radius: 13px;
}

.wrapper-setup-body>div>h6 {
    color: #25313C;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.wrapper-setup-footer>button:last-child {
    background: #00B2E3;
    border-radius: 3px;
    font-size: 14px;
    padding: 10px 14px;
    outline: 0px;
    color: #FCFCFD;
    cursor: pointer;
    font-family: "Poppins-Regular";
    border: 0px;
}

.wrapper-setup-footer {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: flex-end;
    padding: 0px 35px 35px 35px;
    --webkit-justify-content: flex-end;
    display: block;
    box-sizing: border-box;
    width: 100%;
}

.wrapper-setup-header h3 {
    margin: 0px;
    color: #23262F;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}

.couponPreview>div {
    border: 2px dashed #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 20px;
    margin: 20px auto;
    width: 80%;
    min-height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.couponPreview>div>h6 {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 18px;
}

.couponPreview>div>h6:first-child {
    font-size: 18px;
    font-family: "Poppins-Medium";
}

.couponPreview>div>h6:last-child {
    font-family: "Poppins-SemiBold";
}

.couponPreview>div>h6:last-child>span {
    color: green;
}

.slash-eye-wrapper {
    border: 1px solid #00B2E3;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: center;
    --webkit-justify-content: center;
    background-color: #00B2E3;
}

.slash-eye-wrapper>svg {
    color: #fff;
}

.locked-page {
    background: #F3FCFF;
    backdrop-filter: blur(15.5px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../public/admin-images/unlock-bg.png");
    background-repeat: no-repeat;
    z-index: 1;
}

.locked-page h1 {
    font-family: "Poppins-Medium";
    color: #212529;
    margin-bottom: 5px;
    font-size: 30px;
}

.locked-page>div {
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(176, 183, 195, 0.09);
    border-radius: 10px;
    padding: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.locked-page>div>p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #747474;
    margin: 0px;
    width: 80%;
}

.locked-page>div>span,
.locked-page .link-as-button {
    background: #00B2E3;
    border-radius: 3px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 20px;
    width: fit-content;
    display: block;
    margin-top: 12px;
    font-size: 14px;
}

.locked-alacarte-btn {
    color: #25313c;
    font-family: "Poppins-Regular";
    background-color: #6c757d2e;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 3px;
    outline: 0px;
    cursor: pointer;
    filter: grayscale(1);
    margin-left: 10px;
    line-height: 29px;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 0px;
    cursor: initial;
}

.locked-alacarte-btn img {
    margin-right: 10px;
}

.locked-page-btn {
    position: relative;
    padding-bottom: 37px;
}

.locked-page-btn button {
    background: linear-gradient(180deg, rgba(0, 178, 227, 0.05) 0%, rgba(0, 178, 227, 0.05) 100%);
    border: 1px solid rgba(0, 178, 227, 0.15);
    box-shadow: inset 0px 1.73276px 4.33189px rgb(255 255 255 / 20%);
    border-radius: 40px;
    color: #00B2E3;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Poppins-Bold";
    position: relative;
    padding: 7px 15px 7px 43px;
    cursor: pointer;
}

.locked-page-btn span {
    display: block;
    width: 30px;
    height: 30px;
    background: rgba(24, 174, 227, 0.15);
    position: absolute;
    border-radius: 100%;
    left: 5px;
    top: 5px;
}

.locked-page-btn span::after {
    content: "";
    display: block;
    width: 6px;
    height: 21px;
    background: rgba(24, 174, 227, 0.15);
    position: absolute;
    left: 12px;
    background: linear-gradient(180deg, #82E4FF 0%, #07C7FC 100%);
    bottom: 4px;
    z-index: 1;
}

.lock-item-svg {
    position: absolute;
    top: 18px;
    left: -2px;
    z-index: 1;
}

.locked-page>div:hover .lock-item-svg {
    animation: slide-lock 1.5s ease-in-out infinite;
}

.lock-item-svg::after {
    content: ""
}

.review_page_logo>input {
    display: none;
}

.review_page_logo {
    margin-bottom: 0px !important;
}

.review_page_logo label svg {
    font-size: 20px;
}

.review_page_logo label {
    background: #CCF2FF;
    width: 250px;
    height: 100px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00B4E5;
}

/* Quick Replies */
.quick-reply-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
}

.quick-reply-item-wrapper button {
    background: transparent;
    border: 0px;
    cursor: pointer;
    margin-left: 2px;
}

.quick-reply-item-wrapper p {
    color: #25313C;
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin: 0px;
}

.quick-reply-item-wrapper h6 {
    color: #25313C;
    font-size: 12px;
    font-family: "Poppins-Regular";
    margin: 7px 0px 0px 0px;
}

.quick-reply-item-wrapper h6>span {
    font-family: "Poppins-Bold";
}

.quick-reply-types {
    display: flex;
}

.quick-reply-types input {
    display: none;
}

.quick-reply-item-wrapper>div>div>img {
    margin-right: 13px;
    margin-top: 4px;
}

.quick-reply-item-wrapper>div>span,
.quick-reply-types label {
    border-radius: 52px;
    display: block;
    padding: 8px 25px;
    width: fit-content;
    text-align: center;
    font-family: "Poppins-Regular";
    font-size: 9px;
    margin-bottom: 10px;
}

.quick-reply-item-wrapper>div>span.negative,
.quick-reply-types label.negative {
    background: rgba(253, 86, 86, 0.05);
    border: 0.619777px solid rgba(253, 86, 86, 0.15);
    color: #FD5656;
}

.quick-reply-item-wrapper>div>span.positive,
.quick-reply-types label.positive {
    background: rgba(94, 177, 77, 0.05);
    border: 0.619777px solid rgba(94, 177, 77, 0.15);
    color: #5EB14D;
}

.quick-reply-types label.positive,
.quick-reply-types label {
    margin-right: 10px;
    cursor: pointer;
}

#positive-quick-reply:checked+label {
    background-color: #5EB14D;
    color: #fff;
}

#negative-quick-reply:checked+label {
    background-color: #FD5656;
    color: #fff;
}

.search-input-replies {
    background: #FFFFFF;
    border-radius: 3px;
    height: 34px;
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    border: 0px;
    font-size: 12px;
    color: #25313C;
    padding-left: 10px;
    border: 1px solid rgba(37, 49, 60, 0.12);
    background-image: url("../../public/admin-icons/reply-search-icon.svg");
    background-repeat: no-repeat;
    background-position: 98% center;
}

.search-input-replies::placeholder {
    color: #25313C;
    opacity: 0.5;
}

/* End Of Quick Replies */
/*CUSTOM LOCATION AND GROUP FILTER*/
.loc-group-container input[type="radio"] {
    display: none;
}

/* .group-locations > .left-loc-icon{
    padding-left: 30px;
} */

.select-search-header::before {
    content: url("../../public/admin-icons/search-icon.svg");
    position: absolute;
    left: 8px;
    z-index: 1;
    background: #E6EAED;
    padding: 6px;
    top: 4px;
    border-radius: 100%;
    line-height: 0;
}

.select-search-header::after {
    content: url("../../public/admin-icons/small-down-arrow.svg");
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 10px;
}

.loc-group-container div label {
    color: #333333;
    font-size: 14px;
    font-family: "Poppins-Regular";
    display: block;
    cursor: pointer;
    padding: 8px 20px 8px 50px;
    position: relative;
}

.left-loc-icon-gr label {
    font-size: 12px !important;
    opacity: 0.9;
    padding: 8px 20px 8px 12px !important;
}

.left-loc-icon label::before {
    content: url("../../public/admin-icons//loc-icon.svg");
    position: absolute;
    left: 25px;
    top: 0;
    bottom: 0;
    display: flex;
    margin-top: 5px;
    align-items: center;
}

.group-locations-selected {
    background-color: #00b4e526;
}

.element-container:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.element-container>input:checked+label {
    background-color: #00b4e56b;
}

.select-search-header {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25313C;
    font-weight: 400;
    font-size: 13px;
    font-family: 'Poppins-Regular';
    border: 1px solid rgba(37, 49, 60, 0.15);
    border-radius: 6px;
    cursor: pointer;
    padding: 8px 27px 8px 48px;
    min-height: 38px;
    box-sizing: border-box;
}

.search-inside-dropdown {
    background: #F7F7F7;
    border-radius: 6px;
    height: 38px;
    border: 0px;
    margin: 0px 10px 10px 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding-left: 32px;
    background-position-x: 12px;
    background-image: url("../../public/admin-icons/search.svg");
    background-repeat: no-repeat;
    background-position-y: center;
}


.search-inside-dropdown::placeholder {
    color: rgba(37, 49, 60, 0.25);
    font-size: 12px;
    font-family: "Poppins-Medium";
}

.group-name-span {
    font-size: 12px;
    opacity: 0.9;
    padding: 8px 20px 8px 12px;
    font-family: "Poppins-Regular";
    display: block;
    cursor: pointer;
    color: #333333
}

.loc-group-container-wrapper {
    position: absolute;
    z-index: 1000;
    background: #fff;
    top: 52px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 14%);
    border-radius: 6px;
    padding-bottom: 10px;
}

.loc-group-container {
    max-height: 263px;
    overflow: auto;
}

.loc-group-tabs>div>div {
    color: rgba(51, 51, 51, 0.5);
    font-size: 14px;
    cursor: pointer;
    font-family: "Poppins-Regular";
}

.loc-group-tabs>div>div.active {
    color: #333333;
}

.loc-group-tabs>div>div.active::after {
    content: "";
    border-bottom: 1.2px solid #00B2E3;
    bottom: -1px;
    position: absolute;
    left: -8px;
    right: -8px;
}

.loc-group-tabs>div>div:first-child {
    margin-right: 25px;
}

.loc-group-tabs>div>div {
    position: relative;
    padding-bottom: 10px;
}

.loc-group-tabs {
    padding: 10px 20px;
}

.loc-group-tabs>div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(37, 49, 60, 0.15);
}

.dialog-information-wrapper>div:last-child {
    padding: 0px 32px 32px 32px;
    text-align: center;
}

.dialog-information-wrapper>div>img {
    margin-bottom: 32px;
}

.dialog-information-wrapper>div:first-child {
    padding: 10px 10px 0px 10px;
}

.dialog-information-wrapper button {
    background-color: transparent;
    border: 0px;
    margin-left: auto;
    display: flex;
    cursor: pointer;
}

.dialog-information-wrapper p {
    font-family: "Poppins-Regular";
    color: #87898E;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 32px;
    text-align: center;
    margin-top: 0px;
}

.dialog-information-wrapper a {
    text-decoration: none;
    display: flex;
    width: 100%;
    background: #00B2E3;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    justify-content: center;
}

.dialog-information-wrapper a img {
    margin-right: 10px;
}

.dialog-information-wrapper h6 {
    font-size: 18px;
    font-family: "Poppins-Medium";
    font-style: normal;
    color: #00B2E3;
    margin-bottom: 32px;
    margin-top: 0px;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.dialog-information-wrapper button svg {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
}

/*END OF LOCATION AND GROUP FILTER*/

/*AUTOPILOT*/
.ai-settings-wrapper {
    margin-top: 30px;
}

.settings-text-wrapper {
    padding: 20px;
    background: #ccc3;
    margin-top: 20px;
}

.settings-text-wrapper h6 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
}

.settings-text-wrapper textarea {
    width: 100%;
    resize: none;
    border: 0.851434px solid rgba(0, 178, 227, 0.16);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Poppins-Regular';
}

.settings-div-wrapper {
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    max-width: 100%;
    border-radius: 3px;
    margin-bottom: 24px;
    padding: 20px;
    width: 700px;
    box-sizing: border-box;
}

.settings-div-wrapper-predefined {
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    max-width: 500px;
    border-radius: 3px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 100%;
}

.settings-div-wrapper-nowidth {
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    max-width: 500px;
    border-radius: 3px;
    margin-bottom: 24px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.settings-div-wrapper.active {
    background-color: #c7eef966;
}

.settings-signature-wrapper h6 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-size: 14px;
    color: #3D3D3D;
    margin-top: 0px;
    margin-bottom: 5px;
}

.settings-signature-wrapper {
    margin-top: 30px;
    max-width: 500px;
    width: 100%;
}

.settings-signature-wrapper textarea {
    resize: none;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 0.8px solid rgba(0, 178, 227, 0.3);
    font-family: 'Poppins-Regular';
    border-radius: 3px;
}

.settings-signature-wrapper textarea:focus {
    outline: unset;
}

.connections-wrapper {
    padding-bottom: 30px;
}

.ai-connections-wrapper>div {
    max-width: 100%;
    width: 500px;
}

.connections-wrapper>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    --webkit-align-items: center;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    padding: 10px;
    margin: 10px 0px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.connections-wrapper>div>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.connections-wrapper>div>div:first-child>img {
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 100%;
}

.connections-wrapper>div>div>h6 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #0c2640;
    margin-top: 0px;
    margin-bottom: 0px
}

.connections-wrapper>div a {
    color: #00B2E3;
    font-family: "Poppins-Regular";
    background-color: #fff;
    font-size: 12px;
    padding: 8px 15px;
    border-radius: 3px;
    outline: 0px;
    border: 1px solid #00B2E3;
    cursor: pointer;
    margin-left: 10px;
    text-decoration: none;
}

.connections-wrapper>div>div>img {
    margin-right: 10px;
}

.predefined-responses-list {
    padding-right: 15px;
    max-height: 270px;
    overflow: auto;
}

.predefined-responses-list textarea:read-only {
    border: 0px;
    background-color: #f3f3f3;
}

.predefined-responses-list>div {
    margin-bottom: 10px;
    position: relative;
}

.predefined-responses-list>div>span {
    position: absolute;
    right: -5px;
    top: -3px;
    font-family: monospace;
    font-size: 15px;
    bottom: 0;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.predefined-responses-list textarea {
    width: 98%;
    height: auto;
    line-height: 1.2;
    border: 1px solid #00b0ff80;
    border-radius: 5px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #212529;
    resize: none;
}

.predefined-responses-list textarea:focus {
    outline: 0;
}

.settings-div-wrapper-nowidth h5,
.predefined-header-container>h5 {
    white-space: nowrap;
}

.predefined-header-container>button {
    background-color: #00B2E3;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 14px;
    border-radius: 4px;
    border: 0px;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.predefined-header-container>button>svg {
    margin-left: 7px;
}

.predefined-header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    border-bottom: 1px solid #8080802e;
    padding-bottom: 17px;
}

.predefined-header-container>h5 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #0c2640;
    margin-top: 0px;
    margin-bottom: 0;
}

.settings-div-wrapper h5,
.settings-div-wrapper-nowidth h5 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #0c2640;
    margin-top: 0px;
    margin-bottom: 25px;
    border-bottom: 1px solid #8080802e;
    padding-bottom: 17px;
}

.settings-div-wrapper>div,
.settings-div-wrapper-nowidth>div {
    display: flex;
    align-items: flex-end;
    --webkit-align-items: flex-end;
    flex-wrap: wrap;
}

.settings-text-wrapper textarea:focus {
    outline: 0px;
}

.wrapper-setup-body-ai div h4 {
    margin: 0px;
    color: #737373;
    font-family: "Poppins-Regular";
    font-size: 26px;
}

.wrapper-setup-body-ai div h4 span {
    margin: 0px;
    color: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 40px;
}

.autopilot-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    flex-wrap: wrap;
}

.rating-breakdown-ai-header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.rating-breakdown-ai-header h5 {
    color: #3D3D3D;
    font-family: "Poppins-Regular";
    font-size: 11px;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 400;
}

.autopilot-div-container>span {
    display: block;
    color: #242121;
    font-size: 14px;
    margin-top: 12px;
    font-family: "Poppins-Medium";
    text-align: center;
}

.rating-breakdown-ai>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-bottom: 5px;
}

.rating-breakdown-ai>div>span {
    color: #8A8989;
    font-size: 12px;
    font-family: "Poppins-Medium";
    margin-right: 20px;
}

.rating-breakdown-ai>div>h6 {
    color: #8A8989;
    font-size: 12px;
    font-family: "Poppins-Medium";
    margin-left: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.rating-breakdown-ai>div>span>img {
    width: 10px;
    height: 10px;
    object-fit: cover;
}

.rating-breakdown-ai {
    padding: 20px;
}

.percentage-span {
    margin-left: 20px;
    font-family: "Poppins-Regular";
}

.autopilot-div-container>label {
    margin-right: 0px;
}

.autopilot-wrapper>div>h5 {
    color: #1E1919;
    font-size: 30px;
    font-family: "Poppins-Bold";
    margin-top: 0px;
    margin-bottom: 0px;
}

.autopilot-wrapper>div>p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    max-width: 900px;
    width: 100%;
    color: #3D3D3D;
    margin-top: 0px;
}

.latest_response_table td {
    padding: 10px !important;
    vertical-align: bottom;
}

.latest_response_table p {
    margin: 0px;
    overflow: hidden;
    width: 18vw;
    text-overflow: ellipsis;
    font-family: "Poppins-Regular";
    font-size: 12px;
    opacity: 0.9;
}

.latest_response_table h5 {
    display: inline-block;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #000;
    margin-bottom: 3px;
    margin-top: 0px;
    opacity: 1;
}

.latest_response_table img {
    width: 27px;
    height: 27px;
    object-fit: contain;
}

.latest_response_table a {
    text-decoration: none;
    font-family: "Poppins-Regular";
    color: #00B2E3;
    font-size: 12px;
}

.custom-vertical-progress {
    background: #EDF2F7;
    min-height: 200px;
    position: relative;
    border-radius: 5px;
}

.custom-vertical-progress span {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #281414;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.custom-vertical-progress-inner {
    background: #00B2E3;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
}

.custom-vertical-progress-inner span {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-size: 12px;
}

.rating-progress-wrapper>div {
    flex: 1 1 auto;
    max-width: 100px;
    margin: 0 15px;
}

.rating-progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: calc(100% - 35px);
}

.custom-vertical-progress-header {
    font-family: "Poppins-Regular";
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
    margin-bottom: 15px;
}

.custom-vertical-progress-header img {
    width: 15px;
    margin-left: 10px;
}

.latest-response-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
}

.latest-response-container {
    margin-top: 50px;
}

.latest-response-comment {
    display: flex;
    align-items: center;
}

.latest-response-comment svg {
    flex: 0 0 auto;
    width: 21px;
    color: #AEB5BB;
}

.replies-vs-ai-first {
    display: flex;
    align-items: center;
}

.replies-vs-ai-first h5 {
    margin: 0;
    color: #3D3D3D;
    font-family: "Poppins-Regular";
    font-size: 18px;
    margin-left: 10px;
    margin-top: 5px;
}

.latest-response-comment p {
    padding-left: 20px;
}

.replies-vs-ai {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.25);
    padding: 33px;
}

.latest-response-wrapper {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 2.572463274002075px -0.6431158185005188px rgba(0, 0, 0, 0.25);
    padding: 25px 25px 60px 25px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.replies-vs-ai-fourth {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: flex-end;
    --webkit-justify-content: flex-end;
}

.replies-vs-ai-fourth>div>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.replies-vs-ai-fourth>div>div::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 18px;
    height: 100%;
    border-right: 1px dashed #ddd;
}

.replies-vs-ai-fourth>div>div {
    color: rgba(0, 0, 0, 0.71);
    font-family: "Poppins-Medium";
    font-size: 27px;
    padding-left: 37px;
    position: relative;
}

.replies-vs-ai-fourth>div>div h6 {
    font-size: 17px !important;
    margin: 0;
}

.replies-vs-ai-fourth>div>div span {
    font-size: 17px !important;
    font-family: "Poppins-Regular";
    margin-left: 5px;
}

.avg-reply-time>div>div {
    color: #3D3D3D;
    font-family: "Poppins-Medium";
    font-size: 18px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    margin-bottom: 16px;
}

.avg-reply-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
}

.replies-avg-ai div {
    justify-content: flex-end;
    --webkit-justify-content: flex-end;
}

.avg-reply-time>div>div>img {
    margin-left: 7px;
}

.replies-avg-ai span {
    text-align: right;
}

.avg-reply-time>div span {
    color: #9DA4B0;
    font-size: 12px;
    font-family: "Poppins-Regular";
}

.replies-vs-ai-second>div {
    width: fit-content;
    text-align: center;
}

.replies-vs-ai-second>div>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    color: #9DA4B0;
    font-family: "Poppins-Light";
    font-size: 12px;
    justify-content: center;
    --webkit-justify-content: center;
}

.replies-vs-ai-second>div>div>img,
.replies-vs-ai-third>div img {
    margin-right: 7px;
}

.replies-vs-ai-second {
    text-align: center;
}

.replies-avg-self-third div div,
.replies-avg-ai-third div div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    color: #9DA4B0;
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin-right: 15px;
}

.replies-avg-self-third>div,
.replies-avg-ai-third>div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    --webkit-align-items: flex-end;
    text-align: center;
}

.replies-avg-self-third h6 {
    color: #FBBC04;
    font-size: 28px;
    font-family: "Poppins-Bold";
    margin: 0px;
    text-align: right;
}

.replies-avg-ai-third h6 {
    color: #00B2E3;
    font-size: 28px;
    font-family: "Poppins-Bold";
    margin: 0px;
    text-align: right;
}

.replies-avg-self-third h6 span,
.replies-avg-ai-third h6 span {
    font-size: 14px;
}

.replies-vs-ai-second span,
.replies-vs-ai-third span {
    color: #3D3D3D;
    font-size: 18px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    display: block;
    margin-top: 24px;
}

.replies-vs-ai-third>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    color: #9DA4B0;
    font-family: "Poppins-Light";
    font-size: 12px;
}

.reply-time-ai-header {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.reply-time-ai-header h6 {
    color: #49454F;
    font-family: "Poppins-Bold";
    font-size: 14px;
    margin-right: 48px;
    margin-bottom: 0px;
    position: relative;
    cursor: pointer;
    padding-bottom: 16px;
}

.reply-time-ai-header h6.active {
    color: #00B2E3;
}

.replies-vs-ai {
    margin-bottom: 20px;
}

.reply-time-ai-header h6.active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    bottom: 0;
    background: #00B2E3;
}

.latest-response-header>div,
.latest-response-header>span {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.latest-response-header>div>h5 {
    color: #091728;
    font-family: "Poppins-Regular";
    font-size: 12px;
    margin-left: 5px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
}

.latest-response-header>span {
    color: #3D3D3D;
    font-family: "Poppins-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.latest-response-header>span img {
    width: 9px;
}

.latest-response-wrapper p {
    color: #3D3D3D;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    line-height: 30px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin: 0;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.latest-response-header h2 {
    color: #3D3D3D;
    font-family: "Poppins-Light";
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.latest-response-user {
    color: #AEB5BB;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 12px;
}

.latest-response-user svg {
    margin-right: 7px;
}

.latest-response-wrapper a {
    border-radius: 3px;
    background: #33C1E9;
    color: #fff;
    font-family: "Poppins-Bold";
    font-size: 16px;
    display: block;
    padding: 6px 16px;
    text-decoration: none;
    width: fit-content;
    position: absolute;
    bottom: 16px;
    right: 18px;
}

/*END OF AUTOPILOT*/

/* GAMIFY REVIEW COLLECTION */
.review_collection_header_container {
    display: flex;
    align-items: center;
    background: #F3F7FC;
    border-radius: 3px;
    padding: 3px;
}

.review_collection_container {
    background-color: #00b2e31f;
    border-radius: 3px;
}

.review_collection_container_header {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
    padding: 15px 17px;
}

.review_collection_header_container>div {
    font-size: 10px;
    color: #464E5F;
    font-family: "Poppins-Medium";
    font-weight: 600;
    cursor: pointer;
    /* background-color: #eefdff; */
    position: relative;
    padding: 2px 8px;
}

.review_collection_header_container>div.active {
    background-color: #fff;
}

.custom-chip {
    color: #464E5F;
    font-size: 11px;
    font-family: 'Poppins-Medium';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: #ffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 12px;
    border: 1px solid #dddddd63;
    border-radius: 20px;
}

.custom-chip>span {
    margin-left: 5px;
    color: #fff;
    background-color: #18aee3;
    padding: 2px 4px;
    border-radius: 100%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review_collection_container_dashboard {
    background-image: url("../../public/admin-icons/light_blue_star.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
}

.review_collection_body_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.review_collection_body_container>div {
    flex: 1;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}

.review_collection_body_container>div>span {
    font-size: 50px;
    font-weight: 600;
    font-family: "Poppins-Bold";
    color: #464E5F;
    display: block;
    position: relative;
    line-height: 54px;
}

.review_collection_bottom_container {
    padding: 5px;
    width: 100%;
    text-align: center;
    color: #464E5F;
    font-size: 11px;
    font-weight: 500;
    font-family: "Poppins-Medium";
}

.review_collection_bottom_container>span {
    width: 8.2px;
    height: 8px;
    background-color: #2194EE;
    border-radius: 100%;
    margin-right: 4px;
    display: inline-block;
}

.review_collection_body_container>div>p {
    color: #0065E3;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    font-family: "Poppins-Regular";
}

.review_collection_container_header>button>span {
    font-size: 10px;
    margin-left: 4px;
}

.review_collection_container_header>button {
    background-color: #F3F7FC;
    border: 0px;
    padding: 1px 5px;
    color: #464E5F;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
}

.blue-button-gamify {
    background: #00B2E3;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Poppins-Regular';
    cursor: pointer;
    font-size: 14px;
    min-width: 179px;
}

.gamify-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
}

.blue-button-gamify img {
    margin-right: 11px;
}

.info-progress-collection>div {
    text-align: left;
}

.info-progress-collection h6 {
    color: #0C2640;
    text-align: left;
    font-family: "Poppins-Regular";
    font-size: 14.796px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 0px;
}

.info-progress-collection span {
    color: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 14.796px;
    font-style: normal;
    font-weight: 400;
    background: rgba(0, 178, 227, 0.12);
    border-radius: 3px;
    padding: 8px;
    min-width: 37px;
    height: 37px;
    display: block;
    box-sizing: border-box;
    text-align: center;
    width: fit-content;
}

/* .review_collection_star_wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding: 20px;
} */
.review_collection_star_wrapper_dsh {
    width: 100%;
    padding: 20px;
}

.chart-tooltip {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0C2640;
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: auto;
    margin-top: 10px;
}

.chart-tooltip span {
    width: 14px;
    height: 14px;
    background-color: #00B2E3;
    border-radius: 100%;
    display: block;
    margin-right: 8px;
}

.review_collection-list {
    text-align: left;
}

.review_collection-list>div:first-child>h4 {
    color: #00B0FF;
}

.review_collection-top>div:first-child,
.review_collection-list>div:not(.gamify_winners_wrap),
.gamify_winners_wrap div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.review_collection-list>div {
    margin-bottom: 10px;
}

.review_collection-list>div h4,
.review_collection-list>div span {
    color: #494A4B;
    font-size: 16px;
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.review_collection-top {
    padding: 0 16px;
}

.review_collection-top>div>h5,
.not_mentioned_title {
    color: #25313C;
    font-size: 14.8px;
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.review_collection-top>div>h6 {
    color: #25313C;
    font-size: 14.8px;
    font-family: "Poppins-Light";
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.review_collection_star_wrapper,
.review_collection_star_wrapper_dsh {
    text-align: center;
}

.review_collection_star_wrapper>button.red-alert-button {
    border-radius: 3px;
    background: #CE383A;
    padding: 8px 15px;
    color: #FDFDFD;
    text-align: center;
    font-size: 14px;
    font-family: "Poppins-Regular";
    border: 0px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}

.review_collection-stopwatch {
    border: 1px solid #00B2E3;
    border-radius: 5px;
    color: #444647;
    padding: 7px 40px;
    font-weight: 600;
    /* font-size: 40px; */
    font-size: 16px;
    font-family: "Poppins-Medium";
    /* width: fit-content; */
    width: 70%;
    margin-left: auto;
    background-color: #fff;
    margin-right: auto;
}

.gamify-mui-datatable tbody tr:hover {
    cursor: pointer;
}

.star-progress-filler {
    width: fit-content;
    margin: auto;
    position: relative;
}

.star-progress-filler>div {
    position: absolute;
    top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    --webkit-align-items: center;
    --webkit-justify-content: center;
}

.star-progress-filler>div span {
    display: block;
}

.star-progress-filler>div span:first-child {
    color: #0C2640;
    text-align: center;
    font-family: "Poppins-Regular";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.star-progress-filler>div span:last-child {
    color: #0C2640;
    text-align: center;
    font-family: "Poppins-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper-middle-modal {
    max-width: 700px;
    height: 60vh;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 30px;
    box-sizing: border-box;
    outline: unset;
}

.wrapper-middle-modal .custom-modal-footer {
    width: 100%;
    box-sizing: border-box;
}

.review-date-wrapper {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    padding: 10px 0 10px 0;
    position: relative;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.review-date-wrapper svg {
    margin-right: 8px;
    color: #91979C;
    font-size: 12px;
}

.increase_right::after {
    right: 100px !important;
}

.review-date-wrapper>h6::after {
    display: block;
    background: #ECEEF0;
    transform: translateY(-50%);
    left: 97px;
    right: 24px;
    content: "";
    position: absolute;
    bottom: 50%;
    height: 1px;
    z-index: 0;
    top: 50%;
}

.review-date-wrapper>h6 {
    color: #0C2640;
    font-size: 12px;
    font-family: "Poppins-Medium";
    margin: 0;
    display: flex;
    align-items: center;
}

.wrapper-review-collection .custom-modal-footer button {
    float: none !important;
    margin-left: 5px;
}

.wrapper-review-collection {
    max-width: 450px;
    height: fit-content;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    bottom: 0;
    margin: auto;
    border-radius: 15px;
    box-sizing: border-box;
    outline: unset;
}

.wrapper-review-collection .custom-modal-footer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    --webkit-justift-content: flex-end;
}

.custom-modal-body-stp {
    padding: 18px;
    height: calc(100% - 200px);
}

.custom-modal-body-stp .end_date_wrapper {
    width: 50%;
}

.end_date_wrapper .input-text-holder,
.daily_target_wrapper .input-text-holder {
    width: 100% !important;
}

.custom-modal-footer-target {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-top: 0.6px solid #E7E7E7
}

.custom-modal-footer-target>button {
    flex: 1;
    height: 44px;
    font-size: 12px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}

.custom-modal-footer-target button:first-child {
    border: 1px solid #BDBEBF;
    color: #BDBEBF;
    background-color: #fff;
}

.custom-modal-footer-target button:last-child:disabled {
    opacity: 0.8;
    cursor: initial;
}

.custom-react-calendar {
    border-radius: 5px;
    background: #FCFBFC !important;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
    border: 0px !important;
    position: absolute;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input-date-holder {
    padding: 15px;
    border: 1px solid #00B0FF;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #212529;
    border-radius: 3px;
    max-width: 100%;
    margin-top: 25px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
}

.input-text-holder {
    display: block;
    padding: 15px;
    border: 1px solid #EAEAEA;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #212529;
    border-radius: 3px;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
}

.input-date-label {
    color: #36393C;
    font-size: 16px;
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 600;
}

.input-date-holder svg {
    margin-right: 10px;
}

.gamify-reulst-wrapper {
    position: absolute;
    top: 37px;
    max-height: 260px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgb(176 183 195 / 49%);
    left: 0;
    right: 0;
    z-index: 100;
    margin-top: 9px;
    overflow: auto;
    border-radius: 10px;
    padding: 10px 0;
}

.gamify-reulst-wrapper h6 {
    padding: 5px 10px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 0px;
    cursor: pointer;
}

.gamify-reulst-wrapper h6:hover {
    background-color: #00b2e32e;
}

.gamify-chart-header input {
    border-radius: 3px;
    background: #FFF;
    border: 1px solid rgba(37, 49, 60, 0.12);
    padding: 10px 40px 10px 20px;
    outline: 0px;
}

.gamify-chart-header input:active {
    border-color: #00B0FF;
}

.search-right-icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
}

.gamify-chart-header {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    justify-content: space-between;
    --webkit-justify-content: space-between;
}

.gamify-chart-header>h6 {
    margin: 0px;
    color: #25313C;
    font-size: 18px;
    font-family: "Poppins-Medium";
}

/* END OF GAMIFY REVIEW COLLECTION */

.workspace_error {
    font-size: 0.88em;
    font-family: "Poppins-Medium";
    margin: 10px 0;
    display: block;
    color: #FF0000;
}

.connect-popup-container {
    background: #fff;
    width: fit-content;
    padding: 30px 40px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    text-align: center;
    border-radius: 5px;
}

.connect-popup-container:focus {
    outline: 0px;
}

.connect-popup-container svg {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
    padding: 15px;
}

.text-alert-source {
    font-family: "Poppins-Medium";
    color: #f52f2f;
    margin-bottom: 10px;
    font-size: 12px;
    display: block;
}

.connect-popup-container p {
    font-family: "Poppins-Regular";
    font-size: 16px;
    margin: 0px;
}

.connect-popup-container p:first-of-type {
    margin-top: 20px;
}

.connect-popup-container p:last-of-type {
    margin-bottom: 30px;
}

.connect-popup-container a {
    text-decoration: none;
    background-color: #18AEE3;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 16px;
    display: block;
    padding: 5px 10px;
    border-radius: 3px;
    margin-top: 10px;
}

.connect-popup-container button {
    background-color: #18AEE3;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 16px;
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    border: 0px;
}

/* FLAGGING */
.report_review_list {
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
    border-radius: 9px;
    background: #FFF;
    width: fit-content;
    margin: auto;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
}

.report_review_list.active {
    transform: translateX(0);
    padding: 25px 15px;
    border: 1px solid #00B2E3;
}

.report_review_list:not(.active) {
    transform: translateX(10px);
}

.report_review_list>h1 {
    color: #000;
    font-family: "Poppins-Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.report_review_list_wrapper {
    margin-top: 22px;
}

.report_review_list_item {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    cursor: pointer;
}

.report_review_list_item:hover {
    background-color: #80808012;
    border-radius: 4px;
}

.report_review_list_item:hover>svg {
    color: #00B2E3;
}

.report_review_list_item>div>h6 {
    font-size: 14px;
    color: #000;
    font-family: "Poppins-Regular";
    margin: 0;
}

.report_review_list_item {
    padding: 6px 12px;
    margin-bottom: 5px;
}

.report_review_list_item>div>p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
    width: 360px;
    font-family: "Poppins-Regular";
    max-width: 100%;
    margin: 2px 0;
}

.report_review_list_item>svg {
    margin-left: 10px;
    color: #AFB2B280;
    cursor: pointer;
}

.report_review_list>span {
    font-family: "Poppins-Medium";
    color: #00B2E3;
    font-size: 12px;
    display: block;
    cursor: pointer;
    padding: 30px 13px;
}

.report_review_reason>div>h1 {
    text-align: center;
    font-size: 14px;
    font-family: "Poppins-Medium";
    color: #000;
    margin: 0;
}

.report_review_reason>div {
    text-align: center;
}

.report_review_reason>div>svg {
    position: absolute;
    top: 17px;
    cursor: pointer;
    color: #00B2E3;
    left: 10px;
    padding: 10px;
}

.report_review_reason>div>img {
    margin: 30px 0;
}

.report_review_reason p {
    font-family: "Poppins-Regular";
    font-size: 14px;
    margin: 0;
    width: 325px;
}

.report_review_reason>div>button {
    border-radius: 3px;
    background: #00B2E3;
    border: 0px;
    color: #fff;
    padding: 10px 20px;
    font-family: 'Poppins-Regular';
    margin: 20px auto 10px auto;
    width: fit-content;
    cursor: pointer;
}

.serie-tooltip-promoter {
    text-align: left;
    padding-bottom: 10px;
}

.serie-tooltip-promoter>h1 {
    font-family: "Poppins-Regular";
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #25313C;
    padding: 0px 10px !important;
    line-height: 5px;
}

.serie-tooltip-promoter>h1>span {
    display: inline-block;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
}

.serie-tooltip-promoter>h1>p {
    font-family: "Poppins-Medium";
    margin-left: 10px;
    font-size: 12px;
    display: inline-block;
}

.serie-category-header {
    background: #eceff1;
    border-bottom: 1px solid #ddd;
    font-family: "Poppins-Medium" !important;
    font-size: 13px;
    margin: 0;
    padding: 5px;
}

/* END OF FLAGGING */
@keyframes slide-lock {
    0% {

        transform: rotate(0);
    }

    50% {
        transform: rotate(7deg);
    }

    100% {

        transform: rotate(-7deg);
    }
}

/* MANAGE REVIEWS */
.manage_reviews_flex_header h1 {
    font-family: "Poppins-Medium";
    font-size: 18px;
    line-height: 27px;
    color: #25313C;
}

/* .manage_reviews_flex_header h6 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    line-height: 21px;
    color: #25313C80;
} */

.manage_reviews_box_containers>div>div:not(.upgrade-box-container) {
    position: relative;
    box-shadow: 0px 0px 0px 1px #0000000D;
    align-items: start;
    padding: 41.63px 23.62px;
}

.analysis_box {
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 0px 1px #0000000D;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.mt-40 {
    margin-top: 40px;
}

.mt-20 {
    margin-top: 20px;
}

.analysis_box h2 {
    margin: 0px;
    padding: 0 0 10px 0;
    font-family: "Poppins-Light";
    font-size: 1em;
    color: #0c2640;
}

.manage_reviews_box_containers .logo_wrapper {
    position: absolute;
    bottom: -5px;
    right: 0;
}

.manage_reviews_box_containers>div>div>h6 {
    font-family: 'Poppins-Regular';
    font-size: 12.2px;
    line-height: 16.8px;
}

.manage_reviews_box_containers>div>div>.reviews_title {
    color: #00B2E3;
}

.manage_reviews_box_containers>div>div>.responded_title {
    color: #5EB14D;
}

.manage_reviews_box_containers>div>div>.not_responded_title {
    color: #FD5656;
}

.manage_reviews_box_containers>div>div>.pinned_title {
    color: #FF7F00;
}

.manage_reviews_box_containers>div>div>span {
    font-family: 'Poppins-Semibold';
    font-size: 32px;
    line-height: 48px;
    color: #464E5F;
}

.manage-review-new .filter_wrapper {
    border: 1px solid #E4E4E7;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;
}

.manage-review-new .filter_wrapper input:not(.rdrInputRangeInput) {
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 21px;
    color: #25313C;
    border: none;
    background: #F4F4F5;
}

.manage-review-new .filter_wrapper input:not(.rdrInputRangeInput)::-webkit-input-placeholder {
    color: #25313C;
}

.manage-review-new .filter_wrapper .input_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #F4F4F5;
    padding-left: 15px;
    border-radius: 5px;
}

.manage-review-new .oldest-to-newst-btn {
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 21px;
    color: #25313C;
    border: none;
    background-color: unset;
}

.manage-review-new .filter-container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.manage-review-new .grid_reviews_sort_wrapper {
    padding: 4px !important;
}

.manage-review-new .grid_reviews_sort_wrapper.has__border {
    border-right: 1px solid #F4F4F5;
}

.manage-review-new .date-range-container>div:first-child {
    background-color: unset;
    border: none;
    color: #25313C;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 21px;
    justify-content: center;
}

.manage-review-new .datepicker-container {
    top: 56px;
    box-shadow: 0px 0px 16px 0px #0000000F;
}

.manage-review-new .slider-tags-wrapper {
    margin: 10px 0 10px 0;
}

/* .manage-review-new .all-tags-filter {
    border-right: 0.6px solid #E4E4E7;
    z-index: 2;
} */

.manage-review-new .review-item-container {
    border: 0.6px solid #B0B7C336;
    /* box-shadow: 0px 6px 15px 0px #25313C05; */
}

.manage-review-new .review-item-container-head {
    padding: 28px 24px 0 24px;
}

.manage-review-new .review-item-container-body {
    margin: 0 24px 9px 24px;
    padding: 0 0 23px 0;
}

/* 
.manage-review-new .review-item-container-footer {
    border-top: none;
    padding-bottom: 12px;
    padding-top: 0;
} */

.manage-review-new .review-item-container-footer .reply_review_item_wrapper div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0;
}

.manage-review-new .review-item-container-footer .reply_review_item_wrapper span {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #25313C80;
}

.manage-review-new .review-item-container-footer .reply_review_item_wrapper svg {
    color: #25313C;
    font-size: 19px;
}

.manage-review-new .review-item-container-user {
    align-items: center;
    --webkit-align-items: center;
}

.manage-review-new .review-item-container-user>img {
    width: 36px;
    border-radius: 200px;
    padding: 5px;
    background: #ffff;
    box-shadow: 0px 14px 14px -16px rgba(0, 0, 0, 26.75);
}

.manage-review-new .read_more_wrapper button {
    border: none;
    font-family: 'Poppins-Semibold';
    font-size: 12px;
    line-height: 18px;
    color: #25313C80;
    background-color: unset;
    cursor: pointer;
    padding-left: 0;
    margin-left: 5px;
}

.manage-review-new .review-item-container-body p {
    display: inline;
    font-family: 'Poppins-Regular';
    font-size: 12.5px;
    line-height: 18.75px;
    color: #25313C;
    margin: 0;
}

.manage-review-new .read_more_wrapper {
    position: relative;
}


/* MANAGE REVIEWS */

.video_tutorial_section {
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 999;
    overflow: hidden;
    /* border-radius: 30px 0px 30px 30px; */
    border-radius: 1000px 1000px 0px 1000px;
    transition: bottom 0.2s;
}

.video_tutorial_section.scrolled-class {
    bottom: 100px;
}

/* LOCKED PAGE */
.locked_section_wrapper .loc-group-container-wrapper {
    top: auto;
}

.locked_section_wrapper .search-user-holder {
    margin-left: 0;
}

.locked_section_wrapper .top__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 7px;
    padding: 19px 50px;
    background-color: #fff;
}

.locked_section_wrapper .locked_content_wrapper {
    background: #F3F7FC;
    padding: 0 50px;
    margin-top: 23px;
}

.locked_section_wrapper .locked_content_wrapper .left {
    padding-top: 110px;
}

.locked_section_wrapper .locked_content_wrapper .left .review_title {
    display: flex;
    align-items: center;
    gap: 13px;
    font-family: 'Poppins-Medium';
    font-size: 32px;
    line-height: 60px;
    color: #333;
    margin: 0;
}

.locked_section_wrapper .locked_content_wrapper .left .review_title .star-images {
    display: flex;
    gap: 4px;
}

.locked_section_wrapper .locked_content_wrapper .left h3 {
    font-family: 'Poppins-Bold';
    font-size: 50px;
    line-height: 60px;
    color: #333;
    margin: 0 0 11px 0;
}

.locked_section_wrapper .locked_content_wrapper .left h3 span {
    color: #00B4E5;
}

.locked_section_wrapper .locked_content_wrapper .left .text-content p {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    margin-bottom: 12px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons {
    display: flex;
    align-items: center;
    gap: 18.23px;
    margin-bottom: 123px;
    margin-top: 25px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 15px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn,
.locked_section_wrapper .locked_content_wrapper .left .buttons .support-btn {
    padding: 15px 45px;
    border-radius: 10px;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 24px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn {
    position: relative;
    background: none;
    color: #fff;
    transition: 0.3s ease-in;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn span,
.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn img {
    z-index: 3;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn .background-overlay-1,
.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn .background-overlay-2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: -1; */
    transition: all 0.4s ease;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn .background-overlay-1 {
    background: linear-gradient(90deg, #15537E 0%, #00B4E5 70.85%, #FB0 141.7%);
    z-index: 2;
    border-radius: 10px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn .background-overlay-2 {
    background: linear-gradient(90deg, #15537E 0%, #00B4E5 50%, #FB0 100%);
    opacity: 0;
    z-index: 1;
    border-radius: 10px;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn:hover .background-overlay-1 {
    opacity: 0;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .reactivate-btn:hover .background-overlay-2 {
    opacity: 1;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .support-btn {
    border: 1px solid #333333;
    color: #333;
    transition: all .2s;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .support-btn:hover {
    color: #fff;
    background-color: #333;
}

.locked_section_wrapper .locked_content_wrapper .left .buttons .support-btn:hover>svg path {
    fill: #fff;
}

.locked_section_wrapper .sign_out__btn {
    background: transparent;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}

.locked_section_wrapper .locked_content_wrapper .left .remove_from_account {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #333;
    display: block;
    margin-bottom: 60px;
    text-decoration: underline;
    background: transparent;
    border: none;
    cursor: pointer;
}

.locked_section_wrapper .sign_out__btn:disabled,
.locked_section_wrapper .locked_content_wrapper .left .remove_from_account:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.locked_section_wrapper .locked_reviews {
    margin-top: 50px;
    height: calc(100vh - 140px);
    overflow-y: auto;
    position: relative;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For IE and Edge */
}

.locked_section_wrapper .locked_reviews::-webkit-scrollbar {
    display: none;
}

.locked_section_wrapper .locked_reviews .review_background_linear {
    background: linear-gradient(180deg, rgba(243, 247, 252, 0.00) 0%, #F3F7FC 70.79%);
    backdrop-filter: blur(1px);
    position: absolute;
    right: 0;
    top: 84%;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none;
}

.locked_section_wrapper .locked_reviews .review_item {
    background: #FFFFFF;
    border-radius: 3px;
    padding: 20px;
    border-left: 3px solid #00B4E5;
    margin-bottom: 26px;
    position: relative;
}

.locked_section_wrapper .locked_reviews .review_item:first-child::before {
    content: "";
    background-color: red;
    width: 10px;
    height: 10px;
    display: block;
}

.locked_reviews_gradient {
    position: relative;
}

.locked_reviews_gradient .locked_reviews_gradient_top {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 141px;
    background: linear-gradient(0deg, rgb(243 247 252 / 0%) 9%, #f3f7fccf 70.79%);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.locked_reviews_gradient .locked_reviews_gradient_bottom {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 141px;
    background: linear-gradient(180deg, rgb(243 247 252 / 0%) 9%, #f3f7fccf 70.79%);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);

}

.locked_span {
    border-radius: 3px;
    border: 1px solid #CED4DA;
    background: #EFF2F7;
    color: #000;
    font-family: 'Poppins-Regular';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-left: 9px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 3px 13px;
}

.locked_section_wrapper .locked_reviews .review_item .top_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 22px;
}

.locked_section_wrapper .locked_reviews .review_item .top_head .locked_left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.locked_section_wrapper .locked_reviews .review_item .top_head .locked_left h3 {
    font-family: 'Poppins-Bold';
    font-size: 15px;
    line-height: 22.5px;
    color: #333;
    margin: 0;
}
.locked_left > img{
    width: 25px;
    height: 25px;
    object-fit: contain;
}
.disabled_location{
    opacity: 0.5;
    pointer-events: none;
}
.locked_section_wrapper .locked_reviews .review_item .top_head .locked_left h6 {
    font-family: 'Poppins-Light';
    font-size: 11px;
    line-height: 15px;
    color: #333;
    margin: 0;
}

.locked_section_wrapper .locked_reviews .review_item .top_head .right .stars {
    display: flex;
    gap: 4px;
}

.locked_section_wrapper .locked_reviews .review_item .review_content p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 21px;
    color: #707070;
    margin: 0;
}

/* LOCKED PAGE */

@media (max-width: 999px) {
    .video_tutorial_section.scrolled-class {
        bottom: 122px;
        /* right: 36px; */
    }

    .video_tutorial_section {
        bottom: 119px;
        right: 36px;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons {
        flex-wrap: wrap;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons a {
        min-width: 177px;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 577px) {
    .video_tutorial_section {
        bottom: 84px;
        right: 24px;
    }
}

@media (max-width: 572px) {
    .video_tutorial_section.scrolled-class {
        bottom: 80px;
        right: 22px;
    }
}

.video_tutorial__wrapper {
    background: #FFBE1D;
    padding: 6px 20px 6px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
    /* border-radius: 30px 0px 30px 30px; */
    border-radius: 1000px 1000px 0px 1000px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    transform: translateX(calc(100% - 45px));
}

.video_tutorial__wrapper .text_wrapper {
    opacity: 0;
}

.video_tutorial__wrapper:hover .text_wrapper {
    opacity: 1;
}

.video_tutorial__wrapper:hover {
    transform: translateX(0);
    border-radius: 19px 19px 0 19px;
}

/* .video_tutorial_section:hover:has(.video_tutorial__wrapper:hover) {
    border-radius: 19px 19px 0 19px;
} */


.video_tutorial__wrapper svg {
    color: #fff;
    font-size: 26px;
}

.video_tutorial__wrapper h5 {
    font-family: 'Poppins-Bold';
    font-size: 11px;
    color: #fff;
    text-transform: capitalize;
    margin: 0;
}

.video_tutorial__wrapper h6 {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #fff;
    margin: 0;
}

/* Scroll to top */
.scroll__to_top_btn {
    position: fixed;
    bottom: 102px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: #18aee3;
    color: #fff;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s;
    z-index: 999;
}

.scroll__to_top_btn svg {
    font-size: 22px;
}

/* Scroll to top */


/* Subscription redesign */
.subscription__edit_card_wrapper {
    gap: 11px;
}

.subscription__edit_card_wrapper button {
    border: none;
    background: #00B2E314;
    border-radius: 3px;
    padding: 5px;
    font-family: 'Poppins-Light';
    font-size: 12px;
    line-height: 15px;
    color: #00B2E3;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.subscription__edit_card_wrapper button.active {
    background: #00B2E3;
    color: #fff;
}

.subs-details-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.subscription_details_wrapper {
    flex-wrap: nowrap;
    align-items: flex-start;
}

.subscription-edit-card {
    width: 500px;
}

.subscription_user__credit_card {
    background-color: #fff;
    border-radius: 3px;
    padding: 3.5px 6px;
    cursor: pointer;
    flex-shrink: 0;
}

.subs-details-wrapper .subscription_user__credit_card {
    padding: 3.5px 6px;
    border-radius: 3px;
    margin-left: auto;
}

label.subscription_user__credit_card {
    border: 0.6px solid #E4E4E4;
}

.subscription-edit-card .collapsing-wrapper>h6 {
    margin: 20px 0 16px 0;
}

.subscription-edit-card .card_details_wrapper>h6 {
    font-family: 'Poppins-Medium';
    color: #062536;
    font-weight: 500;
}

.subscription_user__credit_card {
    padding: 9px 6px;
}

.subscription_user__credit_card>div {
    display: flex;
    align-items: center;
    gap: 14px;
}

.subscription_user__credit_card>div h5 {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 13px;
    font-weight: 400;
    color: #535353;
    margin: 0;
}

.subscription-edit-card .cart-form-inputs>label {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    color: #737E84;
}

.subscription_user__credit_card>div .edit_card_span {
    font-family: 'Poppins-Regular';
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: #00B2E3;
}

.subscription_user__credit_card>div h5 span {
    font-family: 'Poppins-Semibold';
    font-weight: 600;
    display: inline-block;
    margin-right: 4px;
}

.card-details-footer .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.card-details-footer .buttons-wrapper .remove-btn {
    background: #CE383A0D;
    padding: 12px 4px;
    border-radius: 5px;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #CE383A;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 125px;
    cursor: pointer;
    min-height: 44px;
    text-transform: capitalize;
}

 .card-details-footer .buttons-wrapper .remove-btn svg {
    color: #CE383A;
}

.card-details-footer .buttons-wrapper .save-btn {
    display: flex;
    height: 44px;
    border-radius: 5px;
    color: #fff;
    font-family: "Poppins-Medium";
    align-items: center;
    font-size: 12px;
    width: 100%;
    border: 0px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    text-transform: capitalize;
    background-color: #BDBEBF;
    gap: 10px;
}

.card-details-footer .buttons-wrapper .save-btn.blue {
    background-color: #00B2E3;
}

.subscription-edit-card .slide_top_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px 0 20px;
    gap: 10px;
}

.subscription-edit-card .slide_top_wrapper>* {
    flex-shrink: 0;
}

.subscription-edit-card .slide_top_wrapper .slider-cards {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
    overflow-x: auto;
    flex-shrink: 1;
}

.subscription-edit-card .custom-scrollbar-vertical {
    scrollbar-color: unset;
}

.subscription-edit-card .slide_top_wrapper .slider-cards::-webkit-scrollbar {
    display: none;
}

.subscription-edit-card .slide_top_wrapper .right-arrow {
    /* position: absolute;
    right: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.24px solid #E4E4E4;
    border-radius: 100px;
}

.subscription-edit-card .slide_top_wrapper .right-arrow svg {
    color: #E4E4E4;
    cursor: pointer;
}

.subscription-edit-card .slide_top_wrapper .left-arrow svg {
    transform: rotate(180deg);
}

.subscription-edit-card .slide_top_wrapper .add-card {
    background: #AFD9E440;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #AFD9E440;
    padding: 9px 13px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #00B2E3;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.subscription_user__credit_card input {
    opacity: 0;
    margin: 0 0 0 4px;
}

.subscription_user__credit_card .checkmark {
    position: absolute;
    top: 3px;
    right: 4px;
    height: 12px;
    width: 12px;
    background: #E4E4E4;
    border-radius: 50%;
}

.subscription_user__credit_card .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.subscription_user__credit_card input:checked~.checkmark:after {
    display: block;
}

.subscription_user__credit_card .checkmark:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    border: 3px solid #00B2E3;
    box-sizing: border-box;
}

.subscription_user__credit_card:has(input:checked) {
    border: 0.6px solid #059BBF;
}

.form_span_error {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-weight: 400;
    color: #ff1414;
    display: block;
    margin-top: 6px;
}

.close_subscription_modal .cart-header-wrapper {
    justify-content: space-between;
}

.close_subscription_modal .content-modal-close {
    padding: 20px;
    border-bottom: 0.6px solid #E7E7E7;
}

.close_subscription_modal .content-modal-close h3 {
    font-family: 'Poppins-Semibold';
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #062536;
    margin: 0;
}

.close_subscription_modal .content-modal-close p {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: #06253699;
    margin: 0;
}

.close_subscription_modal .card-details-footer {
    display: flex;
    gap: 12px;
}

.close_subscription_modal .card-details-footer .cancel-btn {
    border: 1px solid #BDBEBF;
    background-color: #fff;
    color: #BDBEBF;
}

.success_modal_subscription .cart-header-wrapper {
    justify-content: center;
}

.success_modal_subscription .content-modal-close {
    text-align: center;
}

.success_modal_subscription .content-modal-close img {
    width: 30px;
}

/* Subscription redesign */

.dashboard_reviews_loading_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard_reviews_loading_wrapper .welcome_section_wrap {
    box-shadow: none !important;
    background-color: transparent;
    padding-top: 0;
    text-align: center;
    margin-bottom: 20px;
}

/* custom checkbox coupons */
.checkbox-wrapper-4 {
    text-align: left;
}

.checkbox-wrapper-4 * {
    box-sizing: border-box;
}

.checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 6px 0;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: inline-block;
}

.checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #E0E0E0;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 2px;
    left: 1px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    line-height: 18px;
}

.checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #0EC1F2;
}

.checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child {
    background: #0EC1F2;
    border-color: #0EC1F2;
    animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

@media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
        width: 100%;
        display: inline-block;
    }
}

@-moz-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@-webkit-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@-o-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

/* custom checkbox coupons */

@media (max-width: 1310px) {
    .dashboard_reviews_loading_wrapper .welcome_section_wrap {
        margin-top: 20px;
    }
}

.dashboard_reviews_loading_wrapper .setupSuggestons_loading_wrapper .wrapper_grid {
    justify-content: center;
}

.chat-iframe {
    z-index: 999 !important;
}

/* Gallery Css */

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    padding: 10px 5px 0px 10px;
    box-sizing: border-box;
}

.slider-item {
    transition: width 0.5s ease;
    height: 100%;
    position: relative;
}

.slider-item.main-image {
    width: 80%;
    margin-right: 6px;
}

.slider-item.side-image {
    width: 10%;
    margin-right: 6px;
}


.slider-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    position: absolute;
    cursor: pointer;
}

.gallery-container-wrapper {
    width: 100%;
    height: 100%;
    background: #000000f0;
}

.gallery-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80%;
}

.gallery-container>img {
    max-width: 80%;
    max-height: 80%;
}

.prev-arrow,
.forward-arrow {
    cursor: pointer;
    padding: 20px;
    opacity: 0.7;
    background-color: #73686857;
    border-radius: 100%;
    display: flex;
}

.prev-arrow:hover,
.forward-arrow:hover {
    opacity: 1;
    background-color: #73686873;
}

.prev-arrow {
    margin-left: 20px;
}

.forward-arrow {
    margin-right: 20px;
}

.slider-images {
    text-align: center;
    width: 85%;
    overflow-x: hidden;
    white-space: nowrap;
    margin: 20px auto;
}

.thumbnail-img {
    width: 80px;
    height: 60px;
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
    display: inline-block;
    vertical-align: middle;
}

.thumbnail-img:hover {
    opacity: 1;
}

.active-thumbnail {
    width: 100px;
    height: 80px;
    opacity: 1;
    border: 1px solid #18aee3;
}

.custom-chips img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 50%;
}

.custom-chips>div {
    display: flex;
    align-items: center;
}

.custom-chips>div:last-child {
    margin-right: 20px;
}

.custom-chips>div:first-child {
    background: #f3f3f3;
    padding: 2px;
    border-radius: 5px;
    flex-wrap: wrap;
}

.custom-chips {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    border-bottom: 0.2px solid #0000001c;
}

.custom-chips .no_data_span {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #000;
}

.custom-chips .set_target_btn,
.set_target_modal input {
    font-family: 'Poppins-Regular';
    width: 100%;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    background-color: #fff;
    font-size: 12px;
    padding: 5px 0.75rem;
    min-width: 66px;
    text-align: center;
    cursor: pointer;
}

.custom-chips .set_target_btn {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    min-width: 85px;
}

.platform_statistics_title {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.wrapper-review-collection .target_error {
    font-family: 'Poppins-Regular';
    color: red;
    font-size: 12px;
}

.set_target_modal input {
    text-align: left;
}

.set_target_modal .title {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    text-align: center;
    width: 100%;
}

.custom-chips button {
    background-color: transparent;
    border: 0px;
    padding: 5px 15px;
    font-family: "Poppins-Medium";
    font-size: 12px;
    color: #0c2640;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out, background-position 0.1s ease-in-out;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #fff 50%);
    background-position: 0 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    filter: grayscale(1);
}

.custom-chips button.active {
    background-position: -100% 0;
    color: #0c2640;
    filter: grayscale(0);
}

.platform_statistics_wrapper {
    display: flex;
    align-items: stretch;
    /* flex-wrap: wrap; */
    box-sizing: border-box;
}

.platform_statistics_wrapper-left {
    flex: 0 0 30%;
    box-sizing: border-box;
    text-transform: none!important;
}

.platform_statistics_wrapper-right  {
    box-sizing: border-box;
    flex: 1;
}

.platform_statistics_wrapper-left p,
.platform_statistics_wrapper-right p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins-Light";
    color: #707070;
    margin-top: 0;
    margin-bottom: 17px;
}

.platform_statistics_wrapper-right p>b {
    color: #000;
}

.platform_statistics_wrapper-left>div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
}

.platform_statistics_wrapper-left>div>div {
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    flex: 1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 15px;
}

.platform_statistics_box h6 {
    color: #707070;
    font-size: 12px;
    font-weight: 300;
    font-family: "Poppins-Light";
    margin: 0;
}

.platform_statistics_box span {
    color: #464E5F;
    font-size: 28px;
    font-family: "Poppins-SemiBold";
    font-weight: 700;
}

.span-line {
    width: 30px;
    height: 5px;
    background: #8080802e;
    margin-left: auto;
}

.platform_statistics_wrapper-right h6 {
    font-size: 20px;
    font-family: "Poppins-Medium";
    font-weight: 600;
    color: #333;
    margin-top: 0px;
    margin-bottom: 0px;
}
.average-rating-box {
    padding: 10px;
    border-radius: 3px;
    text-align: center;
}
.average-rating-box    p{
    margin-bottom: 10px;
    font-weight: 500;
    font-family: 'Poppins-Medium';
    color: #707070;
    font-size: 12px;
}
.average-rating-box   span{
    font-family: 'Poppins-Medium';
    font-size: 30px;
    color: #333;
    font-weight: 500;
}
    
.platform_statistics_wrapper>div {
    border-right: 0.2px solid #0000001c;
}

.platform_statistics_wrapper>div:last-child {
    border: 0px;
}

.platform_statistics_wrapper-left {
    padding: 20px 33px 20px 15px;
}

.platform_statistics_wrapper-right {
    padding: 20px 15px 20px 33px;
    text-transform: none!important;
}

.progress-bar-header {
    display: flex;
    justify-content: space-between;
    color: #707070;
    font-weight: 400;
    font-family: "Poppins-Light";
    font-size: 12px;
    margin-bottom: 3px;
}

.option-wrapper label {
    display: flex;
    align-items: center;
}

.option-wrapper label>span {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #E5E5E5;
    cursor: pointer;
    display: block;
    margin-right: 8px;
}

.option-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.option-wrapper label>p {
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    font-family: "Poppins-Light";
    margin-bottom: 0px;
    cursor: default;
}

@media (max-width: 1245px) and (min-width: 1200px) {
    .manage-review-new .date-range-container>div:first-child {
        flex-wrap: wrap;
    }
}

@media(max-width: 612px) {
    .rating-settings-background {
        margin: 10px;
    }

    .locked_section_wrapper .locked_content_wrapper {
        padding: 0 28px;
    }

    .locked_section_wrapper .top__header {
        padding: 23px 28px;
    }

    .locked_section_wrapper .locked_content_wrapper .left h3 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (min-width: 1200px) and (max-width:1390px) {
    .suggestion_wrapper {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }

    .connection_cards_wrapper {
        margin-left: 0;
    }
}

@media (max-width: 1800px) {
    .subscription-edit-card .cart_details_body {
        /* height: 70vh; */
        max-height: calc(100vh - 350px);
        overflow-y: auto;
    }

    /* .platforms-dropdown-holder {
        height: calc(100vh - 205px);
        overflow-y: auto;
    } */
}

/* @media (max-width: 1100px) {
    .platforms-dropdown-holder {
        max-height: calc(100vh - 250px);
        overflow-y: auto;
    }
} */

@media screen and (min-width: 1024px) and (max-width:1200px) {
    .responsive-star {
        width: 170px;
        height: 170px;
    }

    .star-progress-filler>div span:first-child {
        font-size: 31px;
    }

}

@media screen and (min-width: 1501px) and (max-width:1600px) {
    /* .responsive-star{
        width: 200px;
        height: 200px;
    } */
}

@media(max-width: 1024px) {
    .review_collection_star_wrapper_dsh {
        flex-wrap: wrap;
    }

    .info-progress-collection {
        display: flex;
        width: 100%;
        justify-content: space-between;
        --webkit-justify-content: space-between;
    }
}

@media (max-width: 900px) {
    .manage-review-new .date-range-container>div:first-child {
        justify-content: flex-start;
    }

    .manage-review-new .oldest-to-newst-btn {
        text-align: left;
        padding: 10px 0 12px 10px;
    }

    .manage-review-new .grid_reviews_sort_wrapper.has__border {
        border-bottom: 1px solid #F4F4F5;
    }

    .video_tutorial__wrapper {
        top: 33%;
    }
}

@media(max-width: 765px) {
    .wrapper-loc-unsubscribe {
        width: 90%;
    }

    .locked_section_wrapper .locked_content_wrapper .left {
        padding-top: 40px;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons {
        margin-bottom: 38px;
    }

    .locked_section_wrapper .locked_content_wrapper .left .remove_from_account {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 1510px) {
    .suggestion_wrapper {
        flex-wrap: wrap;
    }

    .suggestion_wrapper .chart_wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media(max-width: 900px) {
    .facebook-recommendations {
        position: relative;
    }

    .cart-details-body {
        height: 70vh;
        overflow: auto;
    }

    .unsubscribe-btn {
        position: relative;
        bottom: unset;
    }

    .a-primary {
        position: relative;
        bottom: unset;
        right: unset;
        left: unset;
        display: block;
        width: 95%;
        margin: auto;
    }
}

@media(min-width: 999px) and (max-width: 1650px) {
    .search-input-holder.open {
        width: 150px;
    }

    .Location-add {
        font-size: 0.65em;
    }
}

@media(min-width: 999px) and (max-width: 1050px) {
    .select-search-loc {
        max-width: 15vw;
    }
}

@media(min-width: 1050px) and (max-width: 1250px) {
    .select-search-loc {
        max-width: 18vw;
    }
}

@media(min-width: 1250px) and (max-width: 1350px) {
    .select-search-loc {
        max-width: 22vw;
    }

    .Location-add {
        font-size: 0.65em;
    }
}

@media(max-width: 1420px) {
    .video-holder-div h5 {
        display: none;
    }
}

@media(max-width: 999px) {
    .toggle-nav {
        display: block;
    }

    .settings-div-wrapper {
        width: 100%;
        margin-right: 0px !important;
    }

    .search-user-container>div:first-child {
        flex-wrap: wrap;
    }

    .chat-iframe {
        right: 0 !important;
        left: unset !important;
        bottom: 13px !important;
    }

    .connections-wrapper {
        flex-wrap: wrap;
    }

    .connections-wrapper>div {
        width: 100%;
    }

    .alert-inside-page {
        left: 0;
        top: 61px;
    }

    .search-input-holder.open {
        width: 150px;
    }

    .right-side-dashboard-elements {
        flex-wrap: wrap;
    }

    .review-history .reply-history {
        width: 100%;
    }

    .review-history {
        width: 100%;
    }

    .review-item-container-head {
        flex-wrap: wrap;
    }

    .review-item-container-actions {
        margin-left: auto;
    }

    .review-info-holder {
        flex-wrap: wrap;
    }

    .loader-ireview {
        top: 61px;
        left: 0;
    }

    .review-history>div>div {
        max-width: 100%;
    }

    .new-sys-sidebar {
        width: 100%;
        top: 0;
        z-index: 999;
    }

    .new-sidebar-top {
        padding-left: 35px;
        padding-right: 35px;
        justify-content: space-between;
    }

    .toggle-sidebar-icon {
        display: block;
    }

    .sidebar-main-elements {
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: transform 0.5s ease-out;
        transform-origin: top left;
        min-height: 0px;
    }

    .sidebar-main-elements.active {
        height: calc(100vh - 61px);
        opacity: 1;
        visibility: visible;
        transition: all 0.25s linear;
    }

    /* .parent-ul-container {
        height: calc(100vh - 420px);
    } */

    .search-user-container {
        flex-wrap: wrap;
    }

    .navbar-right-side {
        margin-top: 10px;
    }

    .search-user-holder {
        margin-left: 0px;
        left: 0px;
        right: 0px;
        top: 61px;
        transition: 0.3s all ease-in-out;
        visibility: hidden;
        opacity: 0;
        min-height: 0px;
        height: 0px;
        margin-left: unset;
        z-index: 1024;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .search-user-holder.active {
        visibility: visible;
        opacity: 1;
        min-height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: fit-content;
        border-bottom: 1px solid rgb(128 128 128 / 15%);
    }

    .main-container {
        margin-left: unset;
        margin-top: 61px;
        min-height: calc(100vh - 141px);
    }

    .scroll__to_top_btn {
        bottom: 124px;
    }
}

@media(min-width:1415px) and (max-width: 1539px) {
    .location-holder-box .w-100 .MuiSelect-outlined {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* width: 100px; */
    }
}

@media(min-width:1540px) and (max-width: 2000px) {
    .location-holder-box .w-100 .MuiSelect-outlined {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* width: 125px; */
    }
}

@media(min-width:1536px) and (max-width: 1700px) {
    .link-visited-wrapper-phr {
        display: block;
    }
}

@media(max-width:1365px) {
    .setup_connection_card {
        flex: 1 1 33.3333% !important;
    }
}

@media(max-width: 1200px) {
    .datepicker-container .rdrDateRangePickerWrapper {
        flex-direction: column;
    }
    .platform_statistics_wrapper{
        display: block;
    }
    .replies-avg-self-third>div,
    .replies-avg-ai-third>div {
        margin-top: 25px;
        align-items: flex-start;
    }

    .datepicker-container .rdrDefinedRangesWrapper {
        width: 100%;
        order: 2;
    }

    .user-name-holder {
        display: none;
    }

    .user-image-holder {
        margin-right: 5px;
    }

    .replies-vs-ai-fourth {
        justify-content: flex-start;
        --webkit-justify-content: flex-start;
    }

    .replies-vs-ai-first,
    .replies-vs-ai-second,
    .replies-vs-ai-third {
        margin-bottom: 20px;
    }

    .modal-wallet-header-left h3 {
        font-size: 25px;
    }

    .modal-wallet-header-right>h4 {
        font-size: 15px;
    }

    .wallet-body-box-custom>div>input,
    .wallet-body-box span,
    .wallet-body-box-custom span {
        font-size: 35px;
    }

    .modal-wallet-body,
    .modal-wallet-header,
    .modal-wallet-summary {
        padding: 20px;
    }
}

@media(max-width: 1415px) {
    .locations-flex-wrap {
        flex-wrap: wrap;
    }

    .location-box {
        margin-bottom: 20px;
    }
}

@media(max-width: 1024px) {
    .iframe-holder {
        width: 80%;
    }
}

@media (max-width: 991px) {
    .reputation__table td {
        min-width: 220px;
    }



    /* .switch-div-container {
        top: 233px !important;
    } */
}

@media (max-width: 960px) {
    .select-search-loc {
        max-width: 100%;
        margin-bottom: 10px !important;
    }

    .Location-add {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 900px) {
    .manage-review-new .grid_reviews_sort_wrapper.has__border {
        border-bottom: 1px solid #F4F4F5;
    }
}

@media (max-width: 768px) {
    .suggestion_wrapper .chart_wrap {
        padding-left: 0;
    }

    .accreditated-company-list-item>div {
        flex-wrap: wrap;
    }

    .chart-info-wrap {
        padding-left: 0;
        padding-top: 30px;
    }

    .location-holder-box {
        flex-wrap: wrap;
    }

    .chart-info-title {
        padding-top: 130px;
        position: relative;
        top: unset;
        padding-left: 20px;
        padding-right: 20px;
        left: unset;
    }

    .shrink-0 {
        margin-bottom: 15px;
    }

    .location-holder-box .w-100 {
        margin-bottom: 15px;
    }

    .full-width .connection_cards_wrapper {
        display: block;
    }

}

@media (max-width: 672px) {
    .platforms-dropdown-holder {
        width: 300px;
        left: -146px !important;
    }

    .locked_section_wrapper {
        overflow-x: hidden;
    }
}

@media(min-width:1200px) and (max-width: 1450px) {
    .shrink-items .chart-info-wrap {
        padding-left: 0;
        padding-top: 100px;
    }

    .shrink-items .chart-info-title {
        flex-direction: column;
        top: -55px;
    }
}

@media (max-width: 620px) {
    .suggestion_wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }

    .connection_cards_wrapper {
        margin-left: 0;
    }

    .suggestion_wrapper .hide_btn {
        margin-bottom: 1.3rem;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons {
        margin-bottom: 50px;
    }
}

@media (max-width: 578px) {
    .location-holder-box {
        flex-wrap: wrap;
    }

    /* .switch-div-container {
        top: 180px !important;
    } */

    .filter-reply-types .location-list {
        margin-left: unset;
        margin-top: 20px;
    }

    .shrink-0 {
        margin-bottom: 15px;
    }

    .location-holder-box .w-100 {
        margin-bottom: 15px;
    }

    .modal-wallet-container {
        width: 90vw;
    }

    .wallet-box-summary {
        flex-direction: column;
        align-items: flex-start;
        --webkit-align-items: flex-start;
    }

    .wallet-box-summary h6 {
        margin-top: 10px;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons {
        justify-content: center;
    }

    /* .locked_section_wrapper .top__header .select-search-header {
        width: 295px;
    } */
}

@media (max-width: 575px) {
    .scroll__to_top_btn {
        bottom: 85px;
        right: 15px;
    }
}

@media (max-width: 560px) {
    .platforms-dropdown-holder {
        left: -120px !important;
    }
}

@media (max-width: 520px) {
    .navbar-right-side {
        flex-wrap: wrap;
        gap: 10px;
    }

    /* .platforms-dropdown-holder.show {
        height: calc(100vh - 411px);
        overflow: auto;
    } */

    .platforms-dropdown-holder::after {
        display: none;
    }

    .platforms-dropdown-holder {
        left: -227px !important;
    }

    .platforms-dropdown-holder .item {
        flex-wrap: wrap;
    }

    .platforms-dropdown-holder a {
        width: 100%;
    }

    .subscription-edit-card {
        width: 90%;
    }

    /* .platforms-dropdown-holder.show .scrollable-div-container {
        height: calc(100vh - 411px);
        overflow: auto;
    } */
}


@media (max-width: 480px) {
    .platforms-dropdown-holder {
        left: -250px !important;
    }

    .json_animation_wrapper_dashboard {
        width: 100% !important;
        height: 100% !important;
    }

    .locked_section_wrapper .locked_content_wrapper .left .buttons a {
        min-width: 174px;
    }

    .platform_statistics_wrapper-left>div {
        flex-wrap: wrap;
    }
}

/* @media (min-width: 416px) and (max-width: 462px) {
    .platforms-dropdown-holder {
        left: 17px !important;
    }
} */

@media (min-width: 463px) and (max-width: 479px) {
    .platforms-dropdown-holder {
        left: -230px !important;
    }
}

/* @media (max-width: 400px) {
    .platforms-dropdown-holder {
        left: -280px !important;
    }
} */

@media (max-width: 400px) {
    .video_tutorial__wrapper {
        top: 39%;
    }

    .platforms-dropdown-holder {
        left: 17px !important;
    }
}

@media (min-width: 393px) and (max-width: 420px) {
    .platforms-dropdown-holder {
        left: 0px !important;
    }
}



@media (max-width: 375px) {
    .setup_connection_card .connection_link {
        font-size: 0.75em;
    }
}

@media (max-width: 360px) {
    .platforms-dropdown-holder {
        width: 240px;
        left: 10px !important;
    }

    .cart-header-wrapper {
        justify-content: flex-start;
    }

    .locked_section_wrapper .top__header .select-search-header {
        width: 295px;
    }
}

@media (max-width: 325px) {
    .locked_section_wrapper .top__header .select-search-header {
        width: 268px;
    }
}

@media (min-width: 346px) and (max-width: 370px) {
    .platforms-dropdown-holder {
        left: -150px !important;
    }
}


@media (max-width: 346px) {
    .platforms-dropdown-holder {
        width: 240px;
        left: -180px !important;
    }

    .locked_section_wrapper .locked_content_wrapper .left h3 {
        font-size: 25px;
    }
}

@media (max-width: 1920px) {
    .locked_reviews_gradient {
        padding-left: 111px;
    }
}

@media (max-width: 1400px) {
    .locked_reviews_gradient {
        padding-left: 45px;
    }
}

@media (max-width:1350px) and (min-width: 1200px) {
    .locked_section_wrapper .locked_content_wrapper .left h3 {
        font-size: 44px;
    }
}

@media (max-width: 1300px) {
    .locked_reviews_gradient {
        padding-left: 45px;
    }

}

@media (max-width: 1999px) {
    .locked_reviews_gradient {
        padding-left: 0;
    }
}


@media (max-width: 1360px) and (min-width: 940px) {
    .locked_section_wrapper .select-search-header {
        width: 312px;
        overflow: visible;
    }

    .locked_section_wrapper .select-search-header::after {
        left: 295px;
    }
}

/* new styles */
.snapshot-btn {
    background: #00B2E3 !important;
    border: 1px solid #00B2E3 !important;
    padding: 8px 17px;
}

.snapshot-btn svg {
    margin-right: 11px !important;
}

/* new styles */