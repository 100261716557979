@font-face {
  font-family: 'icomoon';
  src:  url('../../public/fonts/fonts/icomoon.eot?5y9juz');
  src:  url('../../public/fonts/fonts/icomoon.eot?5y9juz#iefix') format('embedded-opentype'),
    url('../../public/fonts/fonts/icomoon.ttf?5y9juz') format('truetype'),
    url('../../public/fonts/fonts/icomoon.woff?5y9juz') format('woff'),
    url('../../public/fonts/fonts/icomoon.svg?5y9juz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-new-dots:before {
  content: "\e900";
  color: #c4c4c4;
}
.icon-rounded-print:before {
  content: "\e901";
  color: #18527d;
}
.icon-rounded-trash:before {
  content: "\e902";
  color: #8ba8be;
}
.icon-shaped-delete:before {
  content: "\e903";
  color: #c4c4c4;
}
.icon-mydevicon-edit-bordered:before {
  content: "\e904";
  color: #8ba8be;
}
