.accreditation-section{
    background-color: #0086E1;
    height: 455px;
    position: relative;
    padding-top: 89px;
    text-align: center;
}
.accreditation-section > div > img{
    max-width: 100%;
    padding-top: 89px;
}

.accreditation-section h5{
    font-family: "Poppins-Regular";
    font-size: 24px;
    color: #fff;
    margin-top: 0px;
    z-index: 1;
    position: relative;
    margin-bottom: 0px;
    padding-top: 68px;
}
.accreditation-section h1{
    font-family: "Poppins-Bold";
    font-size: 42px;
    color: #fff;
    margin-top: 0px;
    z-index: 1;
    position: relative;
    margin-bottom: 0px;
}
.accreditation-section > div{
    position: absolute;
    top: 0;
    left: 0;
}
.accreditation-footer{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.accreditation-footer > img{
    max-width: 100%;
}
.accreditation-section > div > img:last-child{
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 86px;
}
.accreditation-section > img{
    position: absolute;
    right: 10%;
    top: 30%;
}
.accreditation-form-wrapper{
    width: 70%;
    margin: auto;
    position: relative;
    margin-top: -230px;
    box-sizing: border-box;
    filter: drop-shadow(0px 7px 29px rgba(100, 100, 111, 0.20));
}
.accreditation-form-left{
    background: #1791E4;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    padding: 65px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    --webkit-justify-content: center;
}
.accreditation-form-left h6{
    font-size: 32px;
    font-family: "Poppins-Bold";
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
}
.accreditation-form-left h6:last-child{
     margin-bottom: 15px;
}
.accreditation-form-left p{
    font-family: "Poppins-Regular";
    font-size: 13px;
    color: #fff;
    margin-bottom: 0px;
}
.accreditation-form-right{
    background-color: #fff; 
    border-radius: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 130px 31px;
    position: relative;
    margin-left: -20px;
    box-sizing: border-box;
    --webkit-justify-content: center;
}
.accreditation-form-right h4{
    font-size: 38px;
    color: #181C25;
    font-family: "Poppins-Bold";
    margin-bottom: 24px;
    margin-top: 0px;
}
.accreditation-form input{
    border-radius: 13px;
    border: 0.807px solid rgba(137, 146, 169, 0.20);
    background: #FDFDFD;
    padding: 11px 20px;
    width: 100%;
    box-sizing: border-box;
    font-family: "Poppins-Regular";
    font-size: 13px;
}
.accreditation-form input::placeholder{
    color: #7A7A7A;
}
.accreditation-form-btn button{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    font-family: "Poppins-Light";
    color: #fff;
    font-size: 12px;
    background-color: #00B2E3;
    cursor: pointer;
    box-sizing: border-box;
    border: 0px;
    outline: 0px;
}
.accreditation-form-btn button:disabled{
    opacity: 0.5;
    cursor: initial;
}
.accreditation-form-btn{
    margin-top: 25px;
}
.accreditation-form-btn span{
    font-family: 'Poppins-Light';
    color: #8992A9;
    font-size: 12px;
    margin-top: 15px;
    display: block;
    text-align: center;
}
.accreditation-form-btn span a {
    color: #0086E1;
}
.search-results-wrapper{
    max-height: 163px;
    overflow: auto;
    padding: 0 5px;
    margin-top: 10px;
}
.search-results-wrapper > h5{
    color: #505359;
    font-size: 13px;
    font-family: "Poppins-Regular";
    border: 0.807px solid #FFF;
    background: #F7F7F7;
    border-radius: 13px;
    padding: 12px 19px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 0px;
}
.search-results-wrapper> h5:hover{
    background: #ebeaea;
}
.extra-information-wrapper{
    background: #fff;
    display: block;
    margin: auto;
    border-radius: 15px;
    padding: 37px;
    max-width: 100%;
    width: 50%;
    position: relative;
    box-sizing: border-box;
    margin-top: -230px;
    filter: drop-shadow(0px 7px 29px rgba(100, 100, 111, 0.20));
}
.extra-information-wrapper  input{
    border-radius: 13px;
    border: 0.807px solid rgba(137, 146, 169, 0.20);
    background: #FDFDFD;
    padding: 11px 20px;
    width: 100%;
    box-sizing: border-box;
    font-family: "Poppins-Regular";
    font-size: 13px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.input-wrapper-acc{
    margin-bottom: 20px;
}
.input-wrapper-acc > .react-tel-input > input{
    width: 100%;
    padding: 20px 14px 20px 58px;
    border-radius: 13px;
    border: 1px solid rgba(137, 146, 169, 0.20);
}
.input-wrapper-acc > .react-tel-input > input:hover{
    border-color: rgba(137, 146, 169, 0.20);
}
.input-wrapper-acc > .react-tel-input > input:focus, 
.extra-information-wrapper  input:focus{
    outline: 0px;
    box-shadow: unset;
    border-color: #00B2E3;
}
.extra-information-wrapper h6{
    font-family: "Poppins-bold";
    font-size: 21px;
    margin-top: 0px;
    margin-bottom: 35px;
}
.extra-information-wrapper > div > div > svg{
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: 0.54;
    padding: 10px;
}
.accreditated-company-wrapper{
    filter: drop-shadow(0px 7px 29px rgba(100, 100, 111, 0.20));
    width: 60%;
    margin: auto;
    position: relative;
    border-radius: 15px;
    padding: 37px;
    margin-top: -230px;
    background: #fff;
}
.accreditated-company-wrapper-dashboard{
    box-shadow: 0px 0px 0px 1px #0000000D;
    margin: auto;
    position: relative;
    border-radius: 15px;
    padding: 0px;
    background: #fff; 
}
.accreditated-company-header{
    padding: 7px 14px;
    border-bottom: 0.2px solid #0000000f;
}
.accreditated-company-header h6{
    color: #3D3D3D;
    font-family: "Poppins-Light";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 0px;
    padding-right: 7px;
    margin-bottom: 0px;
}
.accreditated-company-header h2{
    color: #3D3D3D;
    font-family: "Poppins-Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 0px;
    padding-left: 7px;
    margin-bottom: 0px;
}
.accreditated-company-list-item > div > h6, .double-div-parent{
    color: #4A5356;
    font-family: "Poppins-Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px;
    margin-right: 12px;
    margin-bottom: 0px;
    white-space: nowrap;
}
.accreditated-company-list-item {
    position: relative;
    padding: 6px 16px;
    flex: 1 1 30%;
}
.acc-status-holder{
    font-size: 13px;
    font-family: 'Poppins-Regular';
    padding: 0;
    position: relative;
    z-index: 1;
}
.acc-status-holder:hover{
    cursor: pointer;
}
.accreditated-company-list-item > div {
    position: relative;
}
.acc-border-bottom{
    border-bottom: 0.6px solid #B0B7C336
}
.acc-border-right-left::after{
    content: "";
    display: block;
    width: 1px;
    height: 80%;
    position: absolute;
    right: -8px;
    background-color:  #B0B7C336;
    top: 0;
    margin: auto;
    bottom: 0;
}
.acc-border-right-left::before{
    content: "";
    display: block;
    width: 1px;
    height: 80%;
    position: absolute;
    left: -8px;
    background-color:  #B0B7C336;
    top: 0;
    margin: auto;
    bottom: 0;
}
.acc-disclaimer{
    color: #4d4d4d;
    font-family: "Poppins-Light";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 0px;
}
.accreditated-company-list-item > div > span{
    color: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0.712px solid rgba(0, 178, 227, 0.15);
    background: rgba(0, 178, 227, 0.05);
    border-radius: 3px;
    padding: 3px 8px;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-bottom: 2px;
    height: fit-content;
    white-space: nowrap;
}
.accreditated-company-list{
    padding: 7px 23px; 
    display: flex;
    flex-wrap: wrap;
}
.accreditated-company-progress{
    border-radius: 4px;
    padding: 17px 23px 22px 23px; 
}
.accreditated-company-progress > div > span{
    color: #3D3D3D;
    display: flex;
    font-family: "Poppins-Light";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    margin-left: auto;
    line-height: normal;
    justify-content: flex-end;
    margin-bottom: 5px;
    position: absolute;
    right: 17px;
    top: 0;
    bottom: 0;
    align-items: center;
    margin: auto;
}
.custom-linear-progress-outer{
    background-color: #EFF2F7;
    width: 100%;
    border-radius: 25px;
    height: 22px;
}
.custom-linear-progress-inner{
    border-top-left-radius:  25px;
    border-bottom-left-radius:  25px;
    position: relative;
    height: 100%;
    width: 0;
}
.custom-linear-progress-inner span{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-family: "Poppins-Light";
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    font-size: 14px;
    --webkit-justify-content: center;
}
.custom-linear-progress-inner div{
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    color: #3D3D3D;
    font-family: "Poppins-Light";
    display: flex;
    align-items: center;
    justify-content: center;
    --webkit-align-items: center;
    font-size: 9px;
    --webkit-justify-content: center;
    background: #fff;
    z-index: 10;
    width: fit-content;
    margin: auto;
    padding: 10px;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.1s linear,opacity 0.1s linear;
}
@keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
}
.animate-progress{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}
.custom-linear-progress-inner:hover div{
    visibility: visible;
    opacity: 1;
}
.custom-linear-progress-inner div::after{
    content: '';
    position: absolute;
    top: -7px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
    border-left: 7px solid transparent;
}

@media(max-width: 899px){
    .accreditation-form-right{
        margin-left: unset;
    }
    .accreditation-form-left{
        padding: 20px;
    }
    .accreditation-form-left{
        border-radius: 12px;
    }
    .accreditation-form-left h6{
        font-size: 16px;
    }
    .accreditation-form-wrapper, .extra-information-wrapper{
        margin-top: -200px;
    }
    .accreditation-section > div > img:last-child, .accreditation-section > img{
        display: none;
    }
}

@media(max-width: 992px){
    .accreditation-section{
        padding-top: 84px;
    }
    .extra-information-wrapper{
        width: 90%;
    }
}
@media(max-width: 1200px){
    .accreditated-company-list-item{
        justify-content: flex-start!important;
        --webkit-justify-content: flex-start!important;
    }
}
@media(max-width: 1564px){
    .acc-border-right-left::before, .acc-border-right-left::after{
        display: none;
    }
    .accreditated-company-list-item{
        border-bottom: 0.6px solid #B0B7C336;
    }
}
