.progress_box .text_danger {
    color: #ed476e;
}

.progress_box .text_success {
    color: #06d49d;
}

.progress_box .text_warning {
    color: #ffc107;
}

.view_btn_title {
    display: block;
    text-decoration: none;
    color: #25313C;
    width: fit-content;
}

.listing_page .listing_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.listing_page .listing_header .title,
.source_score_page .source_header {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-size: 1.2em;
    color: #0c2640;
}

.listing_page .export_excel_btn {
    margin-left: 0;
}

.listing_page .analysis_box {
    height: 100%;
    overflow: hidden;
    box-shadow: none !important;
    border: 1px solid #f4f4f4;
}

.listing_page .analysis_box .star_icon {
    position: absolute;
    bottom: -4px;
    right: 0;
}

.listing_score_box2 .listing_score_box2 {
    padding: 23px 14px;
}

.listing_score_box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 27px);
    padding: 0 14px;
}

.listing_page .listing_score_box .title {
    color: #4C4646;
    font-family: 'Poppins-Medium';
    font-size: 35px;
    font-style: normal;
    line-height: 150%;
    margin: 0;
}

.listing_page .listing_score_box h6 {
    margin: 0px;
    color: rgba(76, 70, 70, 0.50);
    font-family: 'Poppins-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.listing_score_box2 .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    margin: 12px 14px 22px 14px;
}

.listing_score_box2 .wrapper h6 {
    margin-bottom: 0px;
    margin-top: 10px;
    border-radius: 6.04px;
}

.listing_score_box2 .wrapper h6.left,
.listing_score_box2 .wrapper h6.right {
    border: 1px solid #0F6C9F;
    color: #4C4646;
    text-align: center;
    font-family: 'Poppins-Semibold';
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    padding: 2px 12px;
    margin-bottom: 8px;
}

.listing_score_box2 .wrapper h6.right {
    border: 1px solid #F0F2F5;
}

.listing_score_box2 .wrapper h6 span {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
}

.listing_score_box2 .wrapper h6.left span,
.listing_score_box2 .wrapper h6.right span {
    color: #4C4646;
    font-family: 'Poppins-Regular';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 3px;
}

.listing_score_box2 .wrapper .small_text {
    color: rgba(76, 70, 70, 0.50);
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
    display: block;
    text-align: center;
}

.listing_score_box2 .wrapper .small_text.accurate_text {
    text-align: left;
}

.progress_box .css-5xe99f-MuiLinearProgress-bar1 {
    background: #06d49d;
}

.progress_box .css-eglki6-MuiLinearProgress-root {
    background: rgba(15, 119, 151, 0.1);
    height: 20px;
}

.source_score_page .edit_btn {
    cursor: pointer;
}

.bar {
    height: 22px;
}

.bar-three-values {
    /* width: 100%; */
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin: 24px 14px 0 14px;
}

.bar-colorPrimary {
    background-color: #0F6C9F;
    z-index: 2;
    position: relative;
    border-radius: 38px;
}

.bar-colorSecondary {
    background-color: #F0F2F5;
    position: absolute;
    top: 0;
    z-index: 1;
    border-radius: 38px;
}

.bar-colorThird {
    background-color: #F0F2F5;
    border-radius: 38px;
}

/* modal */
.source_location_modal .wrapper {
    padding: 20px;
}


.source_location_modal span {
    margin: 0px;
    font-family: "Poppins-Light";
    font-style: normal;
    color: #0c2640;
}

.source_location_modal span {
    display: block;
    font-size: 0.8em;
    margin-top: 10px;
}

.listing_header_wrapper .flex-header-container-title h1,
.reputation_header .flex-header-container-title h1,
.revu_share_header .flex-header-container-title h1 {
    color: #25313C;
    font-family: 'Poppins-Medium';
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.listing_page .analysis_box {
    padding: 0;
}

.listing_page .analysis_box h2 {
    color: #464E5F;
    font-family: 'Poppins-Semibold';
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    border-bottom: 1px solid #F4F4F4;
    margin: 0;
    padding: 7px 14px;
}
.listing_header_wrapper .flex-header-container-title h6,
.reputation_header .flex-header-container-title h6,
.revu_share_header .flex-header-container-title h6 {
    color: rgba(37, 49, 60, 0.50);
    font-family: 'Poppins-Regular';
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    margin: 0;
}
/* modal */

.listing_datatable {
    margin-top: 10px;
}

.listing_datatable .sources-container {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.listing_datatable th button.MuiButtonBase-root {
    padding: 5px;
    text-transform: capitalize;
    justify-content: left;
}

.listing_datatable .sources-container img {
    width: 25px;
    border-radius: 50%;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
}

.listing_datatable .view_btn {
    padding: 8px 10px;
    position: relative;
}

.listing_page .foot p,
.source_score_page .foot p {
    font-family: 'Poppins-Light';
    font-style: normal;
    color: #0c2640;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    margin-top: 55px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 900px) {
    .listing_score_box {
        padding: 24px 14px;
    }

    .listing_page .analysis_box .star_icon {
        bottom: -10px;
    }

    .listing_score_box {
        padding: 32px 14px;
        height: auto;
    }
}

@media only screen and (max-width: 400px) {
    .listing_page .analysis_box .star_icon {
        right: -24px;
    }
}

@media only screen and (max-width: 345px) {
    .listing_page .analysis_box .star_icon {
        display: none;
    }
}