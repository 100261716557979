.mt-100 {
    margin-top: 100px !important;
}

/* .MuiModal-root{
    z-index: 2001!important;
} */
.MuiModal-root {
    z-index: 99999 !important;
}

input:focus-visible,
button:focus-visible {
    outline: 0px;
}

#banner {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5002;
}

#menu-zindex-dropdown {
    z-index: 99999 !important;
}

.bg-white {
    background: #fff;
}

.fixed-alert-right {
    position: fixed;
    align-items: center;
    /* right: 50px;
    bottom: 50px; */
    width: 100%;
    top: 50px;
    left: 230px;
    background-color: #EBF8F1 !important;
    z-index: 99999;
}
.warning-alert-p{
    color: #25313C;
    margin-top: 0px;
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 5px;
}
.App.banner .fixed-alert-right{
    top: 87px;
}
.alert-file-limit-exceeds {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 99999;
}

.required-select fieldset {
    border-color: #d32f2f !important;
}

.required-select-filled fieldset {
    border-color: #198754;
}

.country-select-wrap .MuiInputLabel-root {
    padding: 0 5px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-auto {
    margin-left: auto;
}

.mb-50 {
    margin-bottom: 50px;
}

.main-padding-top {
    padding-top: 84px;
}

.custom-horizontal-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 4px;
    background: rgb(235, 233, 233);
}

.custom-horizontal-scrollbar::-webkit-scrollbar-thumb:horizontal {
    background: rgb(167, 167, 167);
    border-radius: 10px;
}

.custom-scrollbar-vertical, .custom-scrollbar-vertical-mui textarea,
.tss-1cdcmys-MUIDataTable-responsiveBase {
    scrollbar-color: #e5e5e5 transparent;
    scrollbar-width: thin;
}

.custom-scrollbar-vertical::-webkit-scrollbar, .custom-scrollbar-vertical-mui textarea::-webkit-scrollbar,
.tss-1cdcmys-MUIDataTable-responsiveBase::-webkit-scrollbar {
    width: 7px;
    background-color: #e9e6e6;
    height: 7px;
    border-radius: 12px;
}

.custom-scrollbar-vertical::-webkit-scrollbar-track, .custom-scrollbar-vertical-mui textarea::-webkit-scrollbar-track,
.tss-1cdcmys-MUIDataTable-responsiveBase::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
}

.custom-scrollbar-vertical::-webkit-scrollbar-corner, .custom-scrollbar-vertical-mui textarea::-webkit-scrollbar-corner,
.tss-1cdcmys-MUIDataTable-responsiveBase::-webkit-scrollbar-corner {
    background: #D4D8DF;
    border-radius: 12px;
}

.custom-scrollbar-vertical::-webkit-scrollbar-thumb:hover,.custom-scrollbar-vertical-mui textarea::-webkit-scrollbar-thumb:hover,
.tss-1cdcmys-MUIDataTable-responsiveBase::-webkit-scrollbar-thumb:hover {
    background: rgba(183, 185, 187, 0.5);
    border-radius: 100px;
}

.custom-scrollbar-vertical::-webkit-scrollbar-thumb, .custom-scrollbar-vertical-mui textarea::-webkit-scrollbar-thumb,
.tss-1cdcmys-MUIDataTable-responsiveBase::-webkit-scrollbar-thumb {
    background: rgba(186, 187, 189, 0.5);
    border-radius: 100px;
}
.custom-scrollbar-vertical::-webkit-scrollbar-button {
    display: none;
}

.blue-button {
    padding: 10px;
    background-color: #00B4E5;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 16px;
    border: 0px;
    border-radius: 5px;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
}

.grey-button {
    padding: 10px 20px;
    background-color: #bdbdbd;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 16px;
    border: 0px;
    border-radius: 5px;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
    --webkit-align-items: center;
}

.align-items-start {
    align-items: flex-start;
    --webkit-align-items: flex-start;
}

.justify-content-center {
    justify-content: center;
    --webkit-justify-conten: center;
}

.justify-content-between {
    justify-content: space-between;
}
.rating-settings-background{
    margin-left: auto;
}
.blue-hyperlink {
    font-family: "Poppins-Regular";
    color: #00c1f7;
    margin-top: 7px;
    display: block;
    font-size: 14px;
}

.bg-ilustration-1 {
    background-image: url("../../public/admin-images/ilustrimi.svg");
    background-size: cover;
}

.grey-background-box {
    padding: 30px;
    background: #e9e9e9ad;
    border-radius: 5px;
}

.white-background-box {
    border-radius: 5px;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
}

.page-not-found {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-not-found>h1 {
    color: #1D3954;
    font-family: "Poppins-Medium";
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.page-not-found>h6 {
    margin: 0px;
    font-size: 20px;
    font-family: "Poppins-Regular";
    color: #173151;
    opacity: 0.8;
}

.page-not-found>h6+p {
    color: #ff7d7d;
    font-style: italic;
}

.page-not-found>* {
    font-family: "Poppins-Regular";
}

.title-parent>h4 {
    font-family: "Poppins-Regular";
    margin: 0px 0px 10px 0px;
    color: #173151;
    font-size: 18px;
}

.title-parent>span {
    font-size: 14px;
    font-family: "Poppins-Regular";
    color: #00b4e5;
    margin: 0px;
    cursor: pointer;
}

.control-label-poppins>span {
    font-family: "Poppins-Regular" !important;
}

.mui-radio-custom {
    color: #dbdbdb !important;
}

.mui-radio-custom.Mui-checked {
    color: #01a4d1 !important;
}

.blue-hyperlink:hover {
    color: #01a4d1;
}

.breadcrumb-padding {
    padding: 25px 0px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pt-25 {
    padding-top: 25px !important;
}

.no-text-decoration {
    text-decoration: none;
    color: inherit;
}

.dark-blue-cl {
    color: #173151;
}

.full-width-div {
    margin: 0 20px;
}

.breadcrumb-link {
    font-family: "Poppins-Light";
    font-size: 16px;
    color: #898989;
}

.breadcrumb-active-el {
    font-family: "Poppins-Light";
    color: #05c9ff;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0px;
}

.masonry-grid {
    -webkit-column-count: 4;
    -webkit-column-width: 280px;
    columns: 280px 4;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
    column-gap: 16px;
}

.masonry-grid>div {
    margin-bottom: 16px;
    display: inline-block;
    width: 100%;
    overflow: visible;
}

.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1024;
    padding: 20px 0;
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 3%);
}

.navbar-container.blue-navbar-wrapper {
    background-color: #0086E1;
    box-shadow: unset;
}

/* .blured{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000008f;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(4px);
    height: 94.7vh;
    font-family: "Poppins-Medium";
}
.blured > svg{
    font-size: 50px;
}
.blured > h5{
    font-size: 16px;
    color: #ffffffcc;
    width: 50%;
    text-align: center;
}
.blured > h5 > a{
    text-decoration: none;
    color: #00B2E3;
} */
.navbar-container.blue-navbar-wrapper .navbar-elements>ul>li>a {
    color: #fff;
    display: block;
    margin-bottom: 10px;
}

.navbar-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.navbar-elements-mobile>svg {
    font-size: 20px;
    color: #173151;
    cursor: pointer;
}

.navbar-elements>ul {
    margin: 0px;
}

.navbar-elements>ul {
    list-style: none;
    display: flex;
}

.navbar-elements>ul>li {
    font-family: 'Poppins-Medium';
    margin-left: 20px;
}

.navbar-elements>ul>li>a {
    text-decoration: none;
    color: #00B4E5;
    font-size: 16px;
}

.navbar-elements-irevu>ul>li a {
    font-family: "Poppins-Light";
}

.li-as-btn>a {
    background-color: #00B4E5;
    padding: 7px 20px;
    border-radius: 5px;
    color: #fff !important;
    white-space: nowrap;
}

.navbar-elements-irevu-last .li-as-btn>a {
    background-color: rgb(24, 82, 125) !important;
    font-family: "Poppins-Light" !important;
}

.navbar-elements-irevu-last .li-outline-as-btn>a {
    font-family: "Poppins-Light" !important;
    background-color: #f5f5f5 !important;
    color: #103254 !important;
    border: 0px !important;
}

.li-outline-as-btn>a {
    border: 1px solid #00B4E5;
    color: #00B4E5 !important;
    background: #fff !important;
    text-decoration: none;
    white-space: nowrap;
    padding: 7px 20px;
    border-radius: 5px;
}


.main-fullscreen-blue {
    background-color: rgba(24, 174, 227, 0.08);
}

.main-fullscreen-padding {
    padding: 200px 0px;
}

.header-container>h1 {
    color: #173151;
    font-family: 'Poppins-Bold';
    font-size: 40px;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.search-component {
    position: relative;
}

.search-component>button {
    background: transparent;
    cursor: pointer;
    border: 0px;
}

.search-component input {
    width: 100%;
    height: 60px;
    border: 0px;
    border-radius: 5px;
    padding: 10px;
}

.search-component input::placeholder {
    font-family: 'Poppins-Regular';
}

.search-component input:focus {
    border: 1px solid #00B4E5;
    outline: 0px;
}

.header-container-acc {
    position: relative;
    height: 100%;
}

.header-container-acc>div {
    background: #173151;
    padding: 50px;
    border-radius: 5px;
    width: 83%;
    margin-left: auto;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    bottom: 0;
    top: 0;
    box-sizing: border-box;
}

.header-container-acc>div h2 {
    font-family: "Poppins-Regular";
    font-size: 30px;
}

.header-container-acc>div p {
    font-family: "Poppins-Light";
    padding-bottom: 150px;
}

.header-container-acc>div button {
    background-color: #fff;
    border: 0px;
    border-radius: 50px;
    min-width: fit-content;
    width: 150px;
    padding: 15px 0;
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #00b4e5;
    cursor: pointer;
}

.header-container-acc>div button:hover {
    color: #00c1f7;
}

.home-categry-shortcut {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 25px 20px;
    border-radius: 5px;
    justify-content: left;
}

.home-categry-shortcut:hover {
    transform: scale(1.03);
}

.home-categry-shortcut>img {
    margin-right: 10px;
}

.home-categry-shortcut>h6 {
    font-family: "Poppins-Medium";
    font-size: 14px;
    margin: 0px;
}

.review-item {
    border-radius: 5px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
    background: #fff;
}

.review-item:hover {
    transform: scale(1.02);
}

.review-item-header {
    padding: 25px 25px 0 25px;
}

.review-item-body {
    padding: 0 25px 25px 25px;
}

.review-item-header>div {
    display: flex;
    align-items: center;
}

.custom-margin-avatar {
    margin-left: 20px;
}

.review-item-header h4 {
    font-family: "Poppins-Light";
    font-size: 14px;
    color: #6f86a3;
    margin-top: 10px
}

.review-item-header h4 span {
    font-family: "Poppins-Bold";
    color: #173151;
}

.review-item-body p {
    font-family: "Poppins-Regular";
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 28px;
}
.acordion-ai-icons > span{
    padding: 5px;
}
.mb-100 {
    margin-bottom: 100px;
}

.header-container>p {
    color: #173151;
    font-family: 'Poppins-Regular';
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 40px;
}

.app-footer {
    background: #173151;
    padding: 50px 0px;
}

.app-footer-item>h4 {
    font-family: 'Poppins-Bold';
    color: #ffff;
    font-size: 20px;
    margin-bottom: 8px;
}

.app-footer-item>ul {
    list-style: none;
    padding-left: 0px;
}

.social-media-holder>li {
    font-size: 30px !important;
}

.social-media-holder {
    padding-left: 20px;
}

.social-media-holder li a {
    margin-bottom: 10px;
}

.app-footer-item ul li {
    font-family: "Poppins-Medium";
    font-size: 16px;
    margin-bottom: 0;
    color: #fff;
}

.app-footer-item ul li>a {
    font-family: 'Poppins-Medium';
    color: #fff;
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
}

.app-footer-item ul li>a>svg {
    margin-right: 10px;
}

.search-results-here {
    position: absolute;
    top: 66px;
    background: #fff;
    z-index: 1;
    right: 0;
    left: 0;
    padding: 20px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 8%), 0px 0px 1px rgb(15 34 67 / 8%);
    border-radius: 5px;
    border: 1px solid #8080802e;
    max-height: 350px;
    overflow: auto;
}

.search-results-here>div {
    margin-bottom: 40px;
}

.search-results-here h6 {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    opacity: 0.8;
    margin-top: 10px;
    margin-bottom: 15px;
}

.search-results-companies h5,
.search-results-categories h5 {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #173151;
}

.search-results-companies span,
.search-results-categories span {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #173151;
    opacity: 0.7;
}

.search-results-companies>a,
.search-results-categories>a {
    text-decoration: none;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    display: block;
}

.search-results-companies>a:hover,
.search-results-categories>a:hover {
    background-color: rgba(24, 174, 227, 0.08);
}

.custom-navigation {
    background-color: #fff;
    padding: 10px;
    border-radius: 100%;
    color: #00c1f7;
    cursor: pointer;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
}

.category-item-header h1 {
    font-family: "Poppins-Regular";
    font-size: 24px;
    margin: 0px;
    text-transform: capitalize;
    color: #00B4E5;
}

.category-item-header p {
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #173151;
    margin: 10px 0px;
}

.code-container>h6 {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Poppins-regular";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    margin: 0 0 10px 0;
}

.code-container input {
    height: 20px;
    border: 0px;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid #fff;
    padding: 10px;
    font-family: "Poppins-Regular";
    font-size: 14px;
    color: #173151;
    height: 40px
}

.code-container input:focus {
    outline: none;
    border: 1px solid #00B4E5;
}

.code-container>div {
    display: flex;
    align-items: center;
}

.code-container button {
    height: 40px;
    border: 0px;
    padding: 0 15px;
    color: #fff;
    background: #00B4E5;
    font-family: 'Poppins-Regular';
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.code-container button:hover {
    background: #15ccfff8;
}

.categories-list-nav {
    font-family: "Poppins-Regular";
}

.categories-list-nav>a {
    display: flex;
    align-items: center;
    color: #00b4e5;
    text-decoration: none;
}

.categories-list-nav ul {
    list-style: none;
    padding-left: 10px;
}

.categories-list-nav ul li {
    color: #173151;
    margin-bottom: 10px;
    padding-left: 35px;
    cursor: pointer;
}

.categories-list-nav ul li a {
    text-decoration: none;
    color: #173151;
}

.categories-list-nav ul li.header {
    font-weight: 700;
    pointer-events: none;
    padding-left: 20px !important;
}

.categories-list-nav ul li.active>a {
    color: #00b4e5;
}

.categories-list-nav ul li:not(.active):hover {
    text-decoration: underline;
    text-decoration-color: #00b4e5;
}

.companies-list-holder {
    font-family: "Poppins-Regular";
}

.companies-list-holder>h5 {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #173151;
}

.companies-list-holder>p {
    opacity: 0.8;
    font-size: 14px;
}

.companies-list-holder {
    margin-bottom: 30px;
}

.companies-list-box {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
}

.compaines-name-box {
    font-size: 18px;
    color: rgb(23, 49, 81);
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
}

.compaines-name-box:hover {
    color: rgb(29, 55, 87);
}

.companies-list-box-inner {
    display: flex;
    align-items: flex-start;
    color: black;
    font-family: "Poppins-Regular";
}

.companies-list-box-inner>img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 20px;
}

.companies-list-box-inner>div>h5 {
    font-size: 18px;
    color: #173151;
    margin-top: 0px;
    margin-bottom: 5px;
}

.MuiRating-readyOnly.custom-margin-avatar {
    margin-left: unset !important;
}

.companies-list-box-inner>div>div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.companies-list-box-inner>div>div>span {
    margin-left: 25px;
}

.companies-info-box-inner>h6 {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    margin-bottom: 10px;
    margin-right: 30px;
    align-items: center;
}

.comma {
    font-weight: 500;
    padding-left: 5px;
    display: block;
}

.remove-last-comma>a:last-child span {
    display: none;
}

.companies-info-box-inner>a>h6 {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    margin-bottom: 10px;
    margin-right: 30px;
    align-items: center;
}

.companies-info-box-inner a>h6 {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
    align-items: center;
}

.companies-info-box-inner>h6>svg {
    margin-right: 7px;
}

.companies-info-box-inner>a {
    margin-right: 30px;
}

.companies-info-box-inner {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.companies-info-box-inner a {
    color: #221f1f;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    word-break: break-all;
    align-items: center;
}

.companies-info-box-inner>a:first-child {
    margin-bottom: 10px;
}

.companies-info-box-inner>a>svg {
    margin-right: 7px;
}

.company-latest-reviews>div {
    display: flex;
    align-items: center;
    margin-top: 20px;
    overflow-x: auto;
    flex-wrap: nowrap;
    visibility: hidden;
    height: 0px;
    padding: 0px;
    opacity: 0;
    transition: all .3s ease-in-out;
}

.company-latest-reviews>div.active {
    visibility: visible;
    opacity: 1;
    height: fit-content;
    padding-bottom: 25px;
}

.no-data-to-show h6 {
    font-family: "Poppins-Light";
    font-size: 16px;
    color: #898989;
}

.no-data-to-show {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.no-data-to-show img {
    margin-top: 75px;
}

.company-latest-review {
    width: 300px;
    min-width: 280px;
    margin: 0 5px;
    border: 1px solid #dbdbdb;
    padding: 12px;
    border-radius: 5px;
}

.company-latest-review>div {
    display: flex;
    align-items: center;
}

.company-latest-reviews>div>a {
    text-decoration: none;
    font-family: "Poppins-Regular";
}

.company-latest-review>span {
    color: black;
    opacity: 0.8;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.company-latest-review>p {
    color: black;
    opacity: 0.8;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
}

.company-latest-reviews>button {
    background: transparent;
    border: 0px;
    font-family: "Poppins-Regular";
    color: #00B4E5;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
}

.navbar-container svg {
    display: none;
}

.c-pointer {
    cursor: pointer;
    user-select: none;
}

.custom-text-area {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    font-family: "Poppins-Regular";
    resize: none;
    line-height: 25px;
}

.custom-text-area:focus {
    outline: none;
}

.create-company-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 900px;
    min-height: -moz-fit-content;
    min-height: fit-content;
    background-color: #fff;
    border-radius: 2px;
    max-width: 30%;
}

.company-modal-header {
    padding: 20px;
    box-shadow: 0px 6px 15px rgb(37 49 60 / 10%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.company-modal-header>button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
}

.company-modal-header>button>svg {
    font-size: 20px;
    color: #173151;
}

.company-modal-header>h5 {
    font-family: "Poppins-Regular";
    font-size: 16px;
    margin: 0px;
    color: #173151;
}

.company-modal-body>div>h4 {
    font-family: "Poppins-Regular";
    font-size: 14px;
    margin: 0px;
    color: #173151;
}

.autocomplete-input>input {
    width: 100%;
    background: #fff;
    height: 45px;
    box-sizing: border-box;
    padding-left: 10px;
    box-shadow: 0px 8px 64px rgb(15 34 67 / 5%), 0px 0px 1px rgb(15 34 67 / 8%);
    border: 1px solid #8080801f;
    border-radius: 5px;
}

.autocomplete-input>input:focus {
    outline: 0px;
    border-color: #00b4e5
}

.autocomplete-input {
    position: relative;
}

.autocomplete-results-item {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-results-item:hover {
    background-color: rgba(24, 174, 227, 0.08);
    border-radius: 5px;
}

.autocomplete-results-item>h4 {
    color: #173151;
    font-family: "Poppins-Light";
    font-size: 14px;
    margin: 0;
}

.autocomplete-input>div {
    width: 100%;
    overflow-y: auto;
    max-height: 220px;
    background: #ffff;
    border: 1px solid #8080801f;
    position: absolute;
    top: 70px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.company-modal-body {
    padding: 20px;
}

.custom-input-file {
    display: flex;
    align-items: center;
}

.custom-input-file>input {
    visibility: hidden;
    opacity: 0;
    width: 0px;
    height: 0px;
}

.custom-input-file>label {
    background-color: #00B4E5;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 14px;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.custom-input-file>img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-right: 50px;
}

@media(max-width: 992px) {
    .navbar-container-mobile .navbar-elements {
        visibility: hidden;
        opacity: 0;
        max-height: 0px;
        transition: all .3s ease-in-out;
    }

    .navbar-container-mobile.active .navbar-elements {
        visibility: visible;
        opacity: 1;
        max-height: fit-content;
    }

    .navbar-container-mobile .navbar-main {
        flex-direction: column;
        align-items: unset;
    }

    .navbar-container-mobile .navbar-main ul {
        display: block;
        padding-left: 0px;
        margin-top: 40px;
    }

    .navbar-container-mobile .navbar-main ul li {
        margin-bottom: 25px;
        display: block;
    }

    .navbar-container-mobile .navbar-main svg {
        display: block;
        position: absolute;
        top: 35px;
        right: 35px;
        font-size: 25px;
        cursor: pointer;
    }

    .header-container-acc>div {
        width: 100%;
        margin-top: 30px;
        padding: 20px;
    }

    .companies-list-holder>h5 {
        margin-top: 30px;
    }

    .create-company-modal {
        max-width: 100%;
        min-width: unset;
        width: 90%;
    }
}


.search-options-business {
    min-height: 700px;
    background-size: contain;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Regular";
    background-color: rgb(255, 255, 255);
}

.search-options-business .bg-image {
    position: absolute;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}

/* if is larger than 2000px width */
@media (min-width: 2000px) {
    .search-options-business {
        min-height: 900px;
    }
}


/* if less than 1000px width remove width max-width */
@media (max-width: 1000px) {
    .search-options-business .bg-image {
        max-width: unset;
        width: unset;
    }
}

.search-options-business h1,
.search-options-business h2,
/* .search-options-business p, */
.search-options-business .ontop {
    z-index: 1;
    margin: 0px;
    padding: 0px;
}

.search-options-business p {
    z-index: 1;
    margin-top: 0px;
}

.search-options-business .header-text-holder {
    font-family: "Poppins-Regular";
    font-size: 63px;
    font-weight: 700;
    text-align: center;
}

@media (max-width: 992px) {
    .search-options-business .header-text-holder {
        font-size: 30px;
    }
}

@media (max-width: 768px) {
    .search-options-business .header-text-holder {
        font-size: 25px;
    }
}


.search-options-business .header-text-footer {
    font-family: "Poppins-Regular";
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

/* if mobile    */
@media (max-width: 992px) {
    .search-options-business .header-text-footer {
        font-size: 25px;
    }
}

.container-multiple-search {
    border: 1px solid #80D8F1;
    filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.08));
    border-radius: 6px;
    background: white;
    padding: 5px 15px 8px 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.container-multiple-search input {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    outline: none;
    font-size: 16px;
    padding: 5px 25px;
    width: 300px;
}

.container-multiple-search input:first-of-type {
    border-right: inherit;
    padding-left: 2px;
    border-right: 1px solid #80D8F1;
}


.list-new-bussiness-small-text {
    color: #00B2E3;
    font-size: 12px;
    padding-left: 15px !important;
    padding-top: 5px !important;
    cursor: pointer;
}

.serach-btn-icon-serach {
    cursor: pointer;
    display: inline-flex;
    transform: translateY(5px);
}

@media (max-width: 720px) {
    .container-multiple-search input:first-of-type {
        border-right: none;
    }

    .container-multiple-search input {
        width: 80%;
        padding: 5px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .serach-btn-icon-serach {
        float: right;
    }
}

.all-categories-seciton {
    min-height: 600px;
    background: url('../../public/admin-images/start_1.png'), url('../../public/admin-images/start_2.png'), #EBF9FD;
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    font-family: "Poppins-Regular";
    padding-bottom: 30px;
}

.one-column-category-s {
    cursor: pointer;
    font-family: "Poppins-SemiBold";
    display: flex;
    background: white;
    padding: 17px;
    border: 2px solid rgba(0, 178, 227, 0);
    /* box-shadow: 0px 4px 15px 7px rgba(0, 178, 227, 0.1); */
    border-radius: 6px;
    color: #15537E;
}

.one-column-category-s:hover {
    border: 2px solid rgba(0, 178, 227, 0.38);
    box-shadow: 0px 4px 15px 7px rgba(0, 178, 227, 0.1);
}


.one-card-list-s {
    background: #FFFFFF;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 20px 25px 0px 25px;
    min-height: 170px;
    display: flex;
    flex-direction: column;
}

.section-font-family {
    font-family: "Poppins-Regular";
}

.card-limits-image {
    object-fit: cover;
    height: 115px;
    max-width: 100%;
    background: #e3e3e3;
}

.card-limits-image-profile {
    border-radius: 2px;
    object-fit: cover;
    height: 46px;
    width: 46px;
}


.clamp-3-line-rows {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.footer-cards-s {
    text-align: center;
    color: #00B2E3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Medium";
    cursor: pointer;
}


.become-bg-image {
    background: url('../../public/admin-images/person-section-become-ireview.png'), #DEF5FB;
    background-repeat: no-repeat;
    background-position: right bottom;
    min-height: 300px;
    border-radius: 40px;
    padding: 70px 90px;
    margin-top: 40px
}

/* small screen  */
@media (max-width: 792px) {
    .become-bg-image {
        background: #DEF5FB;
        border-radius: 30px;
        padding: 20px;
    }
}


.come-header-text {
    font-family: 'Poppins-Regular';
    font-size: 52px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -2px;
    text-align: left;
    width: 80%;
}

.become-btn-get {
    background: #00B2E3;
    border-radius: 50px;
    color: white;
    padding: 9px 35px;
    display: inline-block;
    cursor: pointer;
}
.acordion-ai-icons{
    margin-left: auto;
}

.icon-mydevicon-edit-bordered:hover::before {
    color: rgb(24, 174, 227);
}

.icon-rounded-trash:hover::before {
    color: #f14a26;
}

.card-name-author {
    font-weight: 700;
    margin-bottom: -8px;
    white-space: nowrap;
    width: 13ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

.name-of-bussines-card {
    font-weight: 500;
    color: #00B2E3;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



ul.landing-included-list {
    list-style: none;
    font-size: 12px;
    position: absolute;
    background: white;
    min-width: 270px;
    color: #A1A0A0;
    border-radius: 0.4rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;
    padding: 10px 0px;
    font-family: 'Poppins-Regular';
}

@media (max-width: 792px) {
    ul.landing-included-list {
        min-width: 95%;
        margin-top: 0px !important;
    }
}


a.no-wrap-link {
    margin-bottom: 5px;
    word-break: keep-all;
    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease;
}

a.no-wrap-link:hover {
    color: #00B2E3;
    text-decoration: underline;
}

ul.landing-included-list li {
    padding: 17px 3px 10px 38px;
    cursor: pointer;
}

ul.landing-included-list li:hover {
    background: #F5F5F5;
}

ul.landing-included-list a {
    width: 100%;
    display: block;
}

.li-no-margin-b {
    margin-bottom: 0px !important;
}

@media (max-width: 792px) {
    .hide-on-mobile {
        display: none;
    }
}



.header-menu-dropdown-suite {
    padding-left: 20px;
    padding-right: 20px;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-pack: justify; */
    /* -webkit-justify-content: space-between; */
    /* justify-content: space-between; */
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.row-in-suite-mydev {
    cursor: pointer;
}

.row-in-suite-mydev>a {
    margin-bottom: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
}

.row-in-suite-mydev:hover {
    background: #F2F2F2;
    border-radius: 5px;
}



:root {
    --primary-color-loading: #95d1cc;
    /* #95D1CC */
    --secondary-color-loading: #d0eae8;
    /* #D0EAE8 */
    --dot-size: 15px;
    /* 15px */
}

.dot {
    background-color: var(--primary-color-loading);
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: 50%;
}

.load8 {
    height: 100px;
    width: 100px;
    position: relative;
    font-family: "Poppins";
    display: flex;
    justify-content: center;
    align-items: center;
}

.load8 .dot {
    position: absolute;
}

.load8 .dot:nth-child(1) {
    top: 0;
    left: 0;
}

.load8 .dot:nth-child(2) {
    top: 0;
    right: 0;
}

.load8 .dot:nth-child(3) {
    bottom: 0;
    right: 0;
}

.load8 .dot:nth-child(4) {
    bottom: 0;
    left: 0;
}

.load8 .line-container {
    inset: 6.5px;
    position: absolute;
}

.load8 .line-container .line-horz:nth-child(1) {
    background-color: var(--primary-color-loading);
    position: inherit;
    height: 2px;
    animation: load8-snakeline-horz 2s infinite linear;
}

.load8 .line-container .line-vert:nth-child(3) {
    background-color: var(--primary-color-loading);
    position: inherit;
    width: 2px;
    animation: load8-snakeline-vert 2s infinite linear;
}

.load8 .line-container .line-horz:nth-child(2) {
    background-color: var(--primary-color-loading);
    position: inherit;
    height: 2px;
    bottom: 0;
    animation: load8-snakeline-horz 2s infinite linear reverse;
}

.load8 .line-container .line-vert:nth-child(4) {
    background-color: var(--primary-color-loading);
    position: inherit;
    right: 0;
    width: 2px;
    animation: load8-snakeline-vert 2s infinite linear reverse;
}

.load8 .load-pulse {
    opacity: 0.5;
    animation: load8-pulse 2s infinite linear;
}

@keyframes load8-pulse {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 0.5;
        transform: scale(1);
    }
}



@keyframes load8-snakeline-horz {
    0% {
        right: 100%;
        left: 0;
        opacity: 1;
    }

    30% {
        right: 0;
        left: 0;
        opacity: 0.5;
    }

    60% {
        right: 0;
        left: 0;
        opacity: 0.5;
    }

    100% {
        right: 0;
        left: 100%;
        opacity: 1;
    }
}

@keyframes load8-snakeline-vert {
    0% {
        top: 100%;
        bottom: 0;
        opacity: 1;
    }

    30% {
        top: 0;
        bottom: 0;
        opacity: 0.5;
    }

    60% {
        top: 0;
        bottom: 0;
        opacity: 0.5;
    }

    100% {
        top: 0;
        bottom: 100%;
        opacity: 1;
    }
}

.load8 .dot {
    z-index: 1;
    animation: load8-scaleDowUp 2s infinite linear;
}

@keyframes load8-scaleDowUp {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(0.85);
        opacity: 0.9;
    }

    60% {
        transform: scale(0.85);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
    }
}

.clickeable-text {
    color: #7692f9;
    cursor: pointer;
    user-select: none;
}

/* Survey Form Thank You Modal */
.survey_thanks_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.survey_thanks_wrapper .wrapper {
    background: #FCFCFD;
    border-radius: 15px;
    max-width: 544px;
    width: 100%;
    text-align: center;
    padding: 44px 31px 32px 31px;
    margin: 0 30px;
}

.survey_thanks_wrapper .content .title {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #00B2E3;
    margin-top: 36px;
}

.survey_thanks_wrapper .content p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #87898E;
    margin-top: 6px;
    margin-bottom: 0;
    word-wrap: break-word;
}

.survey_thanks_wrapper .content .close_btn {
    display: block;
    width: 100%;
    background: #00B2E3;
    border-radius: 3px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FCFCFD;
    margin-top: 40px;
    padding: 11px 0;
    border: 0;
    cursor: pointer;
}

/* Survey Form Thank You Modal */

.terms_wrapper .section1 {
    background: #00B2E31C;
    padding: 25px 0;
}
.terms_wrapper .section1 h3 {
    font-family: 'Poppins-Semibold';
    font-weight: 600;
    font-size: 42px;
    line-height: 63px;
    color: #00B2E3;
    margin: 0;
    text-align: center;
}

.terms_wrapper p , .terms_wrapper .footer h5 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000;
}

.terms_wrapper .footer {
    text-align: center;
}

.terms_wrapper p span, .terms_wrapper .footer h5 span {
    color: #FBBC04;
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .terms_wrapper .section1 {
        padding: 10px 0;
    }
    .terms_wrapper .section1 h3 {
        font-size: 30px;
    }
    .terms_wrapper p, .terms_wrapper .footer h5 {
        font-size: 16px;
        line-height: 25px;
    }
}

@media screen and (max-width: 400px) {
    .survey_thanks_wrapper .content .title {
        font-size: 18px;
    }
}

@media screen and (max-width: 375px) {
    .survey_thanks_wrapper .wrapper {
        margin: 0 15px;
    }
}