.ml-0 {
    margin-left: 0 !important;
}

.bg-white {
    background-color: #fff;
}

.about-section {
    font-family: "Poppins-Light";
    background-color: #fbfafa;
}

.align-items-end {
    align-items: flex-end;
    --webkit-align-items: flex-end;
}

.about-section:focus-within {
    outline: none;
}

.about-section h1 {
    margin-top: 0px;
}

#write-review .about-section {
    border: 0px;
    outline: none;
    background: #FFFFFF;
    box-shadow: 0px 47.2778px 126.074px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 567px;
    box-sizing: border-box;
    max-width: 80%;
    max-height: calc(100vh - 80px);
    overflow: hidden auto;
}

.select-label-up {
    font-family: 'Poppins-Regular';
    color: #9AA6AC;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
}

#write-review .about-section h1 {
    color: #344054;
    font-size: 26px;
    font-family: "Poppins-Medium";
    font-weight: 600;
}

#write-review .about-section .MuiBox-root label {
    color: #261E27;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}

.submit-review {
    background: #00B2E3;
    border-radius: 6px;
    width: 100%;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    color: #fff;
    border: 0px;
    height: 45px;
    cursor: pointer;
}

.submit-review:disabled {
    pointer-events: none;
}

#write-review .about-section input {
    width: 100%;
    padding: 10px;
    border: 0px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.09);
    ;
}

#write-review .about-section textarea {
    background: #F6F6F6;
    border: 1.5px solid #F6F6F6;
    border-radius: 20px;
    padding: 18px 20px;
    width: 100%;
    resize: none;
    font-family: "Poppins-Regular";
    box-sizing: border-box;
}

#write-review .about-section textarea:focus {
    outline: unset;
}

#write-review .about-section textarea::placeholder {
    color: #908C91;
    font-family: "Poppins-Regular";
    font-size: 16px;
}

.ribben {
    background: #FFFFFF;
    box-shadow: 0px 6.80656px 16.6383px rgb(30 30 30 / 8%);
    padding: 40px 36px;
    border-radius: 10px;
    width: 85%;
    margin: auto;
    position: relative;
    top: -40px;
    box-sizing: border-box;
}


@media only screen and (max-width: 600px) {
    .ribben {
        padding: 20px 20px;
        width: 100%;
    }
}

.total-reviews-holder {
    position: relative;
    width: fit-content;
    margin-right: 18px;
}

.add-rev-btn {
    width: 100%;
    background: #18AEE3;
    height: 42px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Poppins-Regular';
    border: 0px;
    font-size: 12px;
    outline: unset;
    margin-top: 23px;
    cursor: pointer;
}

.total-reviews-holder>div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    --webkit-align-items: center;
    --webkit-justify-content: center;
    justify-content: center;
}

.total-reviews-holder>div>span:first-child {
    font-size: 2em;
    color: #fff;
    font-family: "Poppins-Regular";
    line-height: 1;
}

.total-reviews-holder>div>span:last-child {
    font-size: 8px;
    line-height: 1;
    color: #fff;
}

.ribben-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.custom-rating-ic {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.subtitle-rev {
    text-align: left;
    color: #261E27;
    font-size: 1em;
}

.custom-rating-box {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    background: #FFFFFF;
    box-shadow: 0px 3.42428px 8.37046px rgb(30 30 30 / 8%);
    padding: 12px;
}

.custom-rating-ic-big {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    background: #FFFFFF;
    padding: 12px;
}

.custom-rating-sm-ic {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.holder-text h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-family: "Poppins-Regular";
    color: #0C2640;
    font-size: 35px;
}

.holder-text p {
    margin-top: 0;
    margin-bottom: 5px;
    color: #0C2640;
    font-family: "Poppins-Regular";
    font-size: 14px;
}

.holder-text .stars-stats {
    display: flex;
    align-items: center;
}

.claimPage {
    color: #00B4E5;
    text-decoration: none;
    font-family: "Poppins-Regular";
    background-color: #00b4e521;
    border-radius: 5px;
    padding: 5px 10px;
}

.ireview-accredited-container {
    flex-grow: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.ireview-accredited-container span.accredited-page {
    background-color: rgba(0, 178, 227, 0.15);
    border-radius: 3px;
    display: block;
    font-family: "Poppins-Regular";
    padding: 3px 18px;
    color: #00B2E3;
    font-size: 18px;
}

.ireview-accredited-container span.accredited-page[disabled] {
    color: #cccccc;
    background: #80808014;
}

.ireview-accredited-container span.claimed-page {
    padding: 3px 18px;
    font-size: 18px;
    display: block;
    font-family: "Poppins-Regular";
    color: #fff;
    background: #14CC74;
    ;
    border-radius: 3px;
}

.ireview-accredited-container a.claimed-page {
    padding: 3px 18px;
    font-size: 18px;
    display: block;
    font-family: "Poppins-Regular";
    color: #14CC74;
    background: rgba(20, 204, 116, 0.1);
    border-radius: 3px;
    text-decoration: none;
}

.rating-text {
    font-size: 14px;
    line-height: 20px;
    color: #0C2640;
    font-family: 'Poppins-Medium';
    font-weight: 400;
}

.intro-section {
    position: relative;
    width: 100%;
    height: fit-content;
}

/* .intro-section .slider-wrapper {
    max-height: 356px;
} */

.intro-section .slider-wrapper .item {
    height: 400px;
    /* Set a fixed height for the container */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* Hide overflow to ensure images don't expand the container */
}

.intro-section .slider-wrapper .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .intro-section .slider-wrapper .item img {
    width: 100%;
    min-height: 400px;
    object-fit: cover;
} */


.intro-section .slider-wrapper {
    position: relative;
}

.intro-section .slider-wrapper::after {
    background: linear-gradient(180deg, #0000 31.42%, #000);
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}

.intro-section .slider-wrapper {
    position: relative;
}

.intro-section .slider-wrapper .alice-carousel__next-btn,
.intro-section .slider-wrapper .alice-carousel__prev-btn {
    width: 31px;
    height: 31px;
    background: #FEFEFEF2;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 9;
}

.intro-section .slider-wrapper .alice-carousel__next-btn {
    right: 25px;
}

.intro-section .slider-wrapper .alice-carousel__prev-btn {
    left: 25px;
}

.intro-section .slider-wrapper .details-company {
    /* transform: translateY(-100%); */
    width: 85%;
    margin: auto;
    padding: 0 0 35px 0;
    position: absolute;
    bottom: -7px;
    left: 9%;
    z-index: 9;
}

.intro-section .slider-wrapper .details-company h2 {
    font-family: 'Poppins-Bold';
    font-size: 48px;
    /* line-height: 72px; */
    margin-bottom: 10px;
    color: #fff;
    font-size: clamp(16px, calc(20px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 48px);
}

.intro-section .slider-wrapper .details-company .flex_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.reviews .stars img {
    margin-right: 15px;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.reviews span {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 24px;
    margin-left: 9px;
    color: #fff;
    font-size: clamp(13px, calc(20px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 16px);
}

.intro-section .slider-wrapper .details-company .flex_wrapper.claimed h5 {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #00B2E3;
    margin: 0 0 0 6px;
    font-size: clamp(13px, calc(20px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 16px);
}

.intro-section .slider-wrapper .details-company .flex_wrapper.claimed h5 span {
    color: #fff;
    margin-left: 8px;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.closed_det {
    justify-content: space-between;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.closed_det .all_photos_btn button {
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    background-color: transparent;
    padding: 12px;
    cursor: pointer;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.closed_det h4 {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #fff;
    font-size: clamp(13px, calc(20px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 16px);
    word-break: break-all;
    max-width: 70%;
}

.intro-section .slider-wrapper .details-company .flex_wrapper.closed_det h4 span {
    color: #CE383A;
    margin-right: 6px;
}

/* .intro-section>img {
    width: 100%;
    object-fit: cover;
} */

.uploaded-banner {
    max-height: 419px;
}

.section-review {
    padding: 36px 0px;
    width: 85%;
    margin: auto;
}

.section-review-star-states {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    border: 0.6px solid #B0B7C336;
    margin-bottom: 40px;
}

.section-review-star-states.blue {
    border-left: 3px solid rgb(24, 174, 227);
}

.section-review-star-states.red {
    border-left: 3px solid red;
}

.section-review-star-states .logo_about_icon {
    width: 36px;
    border-radius: 200px;
    padding: 5px;
    background: #ffff;
    box-shadow: 0px 14px 14px -16px rgba(0, 0, 0, 26.75);
}

.reviews-text-date {
    color: #898989;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}

.section-review-star-map {
    background: #FFFFFF;
    border: 0.6px solid #B0B7C336;
    border-radius: 5px;
    padding: 28px 24px 13px 24px;
}

.bigNumber-review {
    margin: 0;
    font-size: 70px;
    font-weight: 300;
    color: #0C2640;
    line-height: 70px;
}

.isu-progress {
    flex-grow: 1;
    background-color: var(--progress-color-bg, #D9D9D9);
    height: 10px;
    position: relative;
    border-radius: 20px;
    margin-left: 10px;
}

.star-alt-num {
    color: #8A8989;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    margin-right: 5px;
}

.review-alt-num {
    color: #8A8989;
    font-size: 12px;
    font-family: 'Poppins-Regular';
}

.company-reviews-container {
    display: flex;
    align-items: center;
}

.company-reviews-container>div:nth-child(2) {
    width: 100%;
}

.isu-progress::before {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: var(--progress-percent, 44%);
    background-color: var(--progress-color, #FBBC04);
    border-radius: 20px;
}

.title-decor {
    color: #252C32;
    font-size: 24px;
    font-weight: bold;
    font-family: "Poppins-Medium";
    position: relative;
    font-weight: 400;
}

.title-decor:hover::before {
    width: 45px;

}

.section-review-star-states a {
    text-decoration: none;
}

.close-button-modal {
    position: absolute;
    right: 30px;
    top: 38px;
    color: rgba(0, 0, 0, 0.4);
}

.reviews-text-head {
    color: #000000;
    font-weight: 500;
    font-family: "Poppins-Medium";
    font-size: 18px;
    word-break: break-all;
}

.section-review-star-states .stars_wrapper .details_wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.section-review-star-states .right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 3px;
}

.section-review-star-states .right-wrapper .buttons {
    display: flex;
    gap: 3.5px;
}

.section-review-star-states .right-wrapper .buttons button {
    border: 0.74px solid #F4F4F5;
    background-color: #E4E4E70D;
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 15.52px;
    color: #25313C;
    padding: 11px 13px;
    cursor: pointer;
}

.review_date_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.review_date_wrapper svg {
    color: #BBBFC2;
    margin-right: 10px;
}

.review_date_wrapper span {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #0C2640;
    position: relative;
    display: block;
    width: 100%;
}

.review_date_wrapper span::after {
    content: '';
    display: block;
    width: -webkit-fill-available;
    height: 0.4px;
    background-color: #B0B7C336;
    position: absolute;
    top: 50%;
    left: 70px;
    margin-left: 10px;
    transform: translateY(-50%);
}

.reviews-text-paragraph {
    font-size: 12px;
    line-height: 18.75px;
    color: #25313C;
    font-family: 'Poppins-Regular';
    white-space: pre-line;
    margin-bottom: 0px;
    margin-top: 10px;
    word-break: break-all;
}

.read-moreLess {
    color: #25313C80;
    cursor: pointer;
    word-break: keep-all;
    text-decoration: none !important;
}

.read-moreLess:hover {
    text-decoration: underline;
}

.limit-text-3lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.a-nodecorate a,
.a-nodecorate {
    text-decoration: none;
}

.a-footer {
    color: #0C2640;
    font-size: 14px;
}

.a-footer:not(:is(:first-of-type, :last-of-type)) {
    border-left: 1px solid #0C2640;
    border-right: 1px solid #0C2640;
    padding: 0 10px;
    margin: 0 10px;
}

.head-text-info {
    color: #261E27;
    margin-bottom: 5px;
    font-family: "Poppins-Medium";
    font-weight: 600;
    font-size: 0.8em;
    margin-top: 15px;
}

.visit-web {
    text-decoration: none;
    background-color: #00B2E3;
    color: #fff;
    display: block;
    width: 100%;
    height: 44px;
    text-align: center;
    font-family: "Poppins-Regular";
    font-size: 16px;
    line-height: 22.4px;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.section-review-star-map .title {
    font-family: 'Poppins-Medium';
    font-size: 16px;
    line-height: 29.86px;
    color: #8A8989;
    margin: 0;
}

.address-icon-bef .wrapper,
.hours-icon-bef .wrapper,
.categories-icon-bef .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.body-text-info {
    color: #261E27;
    margin-bottom: 5px;
    font-family: "Poppins-Regular";
    font-size: 13px;
    line-height: 19.5px;
}

.logo-container-about {
    height: 125px;
    width: 125px;
    object-fit: contain;
    margin-right: 20px;
}

.list-business-button {
    display: flex;
    justify-content: center;
}

.list-business-button button {
    font-size: 18px !important;
}

.about-page-header {
    width: 85%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --webkit-align-items: center;
    --webkit-justify-content: space-between;
    padding: 4px 0;
    flex-wrap: wrap;
}

.about-page-header .right {
    display: flex;
    align-items: center;
    gap: 13px;
}

.about-page-header .right span,
.about-page-header .right button.write_review_btn {
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 21px;
    color: #00B2E3;
}

.about-page-header .right button.write_review_btn {
    cursor: pointer;
}

.about-page-header .right .buttons {
    display: flex;
    align-items: center;
    gap: 13px;
    /* margin-left: 17px; */
}

.about-page-header .right .buttons .sign-up-btn,
.about-page-header .right .buttons .login-btn {
    border-radius: 5px;
    padding: 3px 21px;
    cursor: pointer;
}

.about-page-header .right .buttons .login-btn {
    border: 0.78px solid #00B2E3;
    color: #00B2E3;
}

.about-page-header .right .buttons .sign-up-btn {
    background: #00B2E3;
    color: #fff;
}

.left_top_header_section_review .buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 14px;
}

.left_top_header_section_review .buttons a,
.left_top_header_section_review .buttons button {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 23.06px;
    color: #00B2E3;
    border: 1px solid #00B2E3;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 15px;
    cursor: pointer;
}

.left_top_header_section_review .buttons .write_review_btn {
    background: #18AEE3;
    color: #fff;
}

.left_top_header_section_review .rating-review-detail .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
}

.left_top_header_section_review .vertical_line,
.section-review-star-states .vertical_line {
    width: 1px;
    height: 38px;
    background: #54545436;
}

.section-review-star-states .vertical_line {
    height: 19px;
    margin-right: 4px;
}

.left_top_header_section_review .rating-review-detail .wrapper .stars-images {
    display: flex;
    align-items: center;
    gap: 15px;
}

.left_top_header_section_review .rating-review-detail .wrapper h5 {
    font-family: 'Poppins-Medium';
    font-size: 55px;
    line-height: 83px;
    color: #25313C;
    margin: 0;
}

.left_top_header_section_review .rating-review-detail .wrapper .emoji-icon img {
    margin-top: 10px;
}

.left_top_header_section_review .rating-review-detail p {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #25313C80;
    margin: 0;
}

.about-page-header>div {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    font-family: "Poppins-Regular";
}

.about-page-header>div a {
    text-decoration: none;
    color: #000000;
}

.section-review-star-states .grid-wrapper {
    flex-wrap: wrap;
    gap: 12px;
}

/* company images modal */
.add_photos_section,
.all_photos_section {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.company_all_photos,
.add_photos_modal {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 18px;
    background-color: #fff;
    margin: 90px 12px;
    position: relative;
    border-radius: 5px;
    max-height: calc(100vh - 140px);
    overflow: auto;
}

@media (min-width: 1400px) {
    .add_photos_modal {
        max-height: calc(100vh - 1px);
    }

    .add_photos_modal .add_photos_content {
        padding: 40px 28px !important;
    }

    .intro-section .slider-wrapper .item {
        height: 345px;
    }
}

.company_all_photos .top_head,
.add_photos_modal .top_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 32px;
    flex-wrap: wrap;
    gap: 10px;
}

.company_all_photos .top_head h3,
.add_photos_modal .top_head h3 {
    font-family: 'Poppins-Semibold';
    font-size: 28px;
    color: #261E27;
    margin: 0;
}

.add_photos_modal .top_head h3 span {
    color: #00B2E3;
    font-weight: 600;
}

.company_all_photos .top_head button,
.add_photos_modal .top_head button {
    border: 1px solid #261E2726;
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #261E27;
    background-color: #fff;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.company_all_photos .grid_images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding: 0 32px 32px 32px;
    /* max-height: calc(100vh - 200px); */
    overflow: hidden auto;
}

.company_all_photos .grid_images .item {
    width: 100%;
}

.company_all_photos .grid_images .item img {
    width: 100%;
    /* height: 100%; */
    height: 300px;
    object-fit: cover;
}

/* .add_photos_section {
    max-width: 1800px;
    margin: 0 auto;
} */

.all_photos_section .close_modal_btn,
.add_photos_section .close_modal_btn {
    position: absolute;
    top: -55px;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.add_photos_modal .add_photos_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 112px 28px;
    /* overflow: auto;
    max-height: calc(100vh - 215px); */
}

.add_photos_modal .add_photos_content .uploaded-images {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 20px;
}

.add_photos_modal .add_photos_content .uploaded-images .box {
    position: relative;
    border: 1px solid #B0B7C336;
    border-radius: 4px;
}

.add_photos_modal .add_photos_content .uploaded-images .box img.uploaded_img {
    width: 150px;
    height: 100%;
    border-radius: 4px;
}

.add_photos_modal .add_photos_content .uploaded-images .delete_img_btn {
    border-radius: 3px;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
    padding: 3px 6px 1px 4px;
    background: #B0B7C336;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    color: rgba(224, 7, 7, 1);
    position: absolute;
    right: 0;
    min-width: auto !important;
}

.add_photos_modal .add_photos_content .wrapper {
    border: 0.48px dashed #9F9F9F;
    padding: 112px 40px 90px 40px;
    text-align: center;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add_photos_modal .add_photos_content .wrapper h4 {
    font-family: 'Poppins-Medium';
    font-size: 28px;
    line-height: 42px;
    color: #261E27;
    margin-bottom: 10px;
}

.add_photos_modal .add_photos_content .wrapper button {
    background-color: #00B2E3;
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    position: relative;
    cursor: pointer;
    min-width: 115px;
    padding: 8px;
}

.add_photos_modal .upload_btn {
    margin-top: 20px;
}

.add_photos_modal input[type=file],
.add_photos_modal input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}

.add_photos_modal .add_photos_content .wrapper button input[type=file] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* company images modal */

/* Share Modal */
.share_modal_wrapper {
    max-width: 500px;
    margin: 90px auto;
    background: #fff;
    border-radius: 5px;
    padding: 40px;
    max-height: calc(100vh - 95px);
    overflow: auto;
}

.share_modal_wrapper .top__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.share_modal_wrapper .top__header h4 {
    font-family: 'Poppins-Medium';
    font-size: 20px;
    color: rgb(45, 46, 47);
    margin: 0;
}

.share_modal_wrapper .top__header .btn {
    cursor: pointer;
    padding: 0;
}

.share_modal_wrapper .address_wrapper {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #c8c9ca;
    padding: 4px 16px;
}

.share_modal_wrapper .address_wrapper .icon_svg {
    fill: rgb(200, 201, 202);
}

.share_modal_wrapper .address_input {
    font-family: 'Poppins-Regular';
    background-color: #fff;
    border: 0;
}


.share_modal_wrapper .hr_line_wrapper fieldset {
    border: none;
    border-bottom: 1px solid #0000;
    border-top: 1px solid #e3e3e3;
    margin: 16px 0;
    text-align: center;
}

.share_modal_wrapper .hr_line_wrapper span {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: rgba(110, 112, 114, 1);
    margin: 0 auto;
    padding: 0 20px;
}

.share_modal_wrapper form input,
.share_modal_wrapper form textarea {
    border: 1px solid #c8c9ca;
    border-radius: 4px;
    width: 100%;
    padding: 13px 16px;
    font-family: 'Poppins-Regular';
    width: -webkit-fill-available;
    height: 46px;
    font-size: 14px;
}

.share_modal_wrapper form input:hover,
.share_modal_wrapper form textarea:hover,
.share_modal_wrapper form input:focus,
.share_modal_wrapper form textarea:focus,
.share_modal_wrapper form input:focus-visible,
.share_modal_wrapper form textarea:focus-visible {
    border-color: #00B2E3;
    outline: none;
}

.share_modal_wrapper .button_wrapper {
    width: 100%;
    text-align: center;
}

.share_modal_wrapper .button_wrapper button {
    background-color: #00B2E3;
    color: #fff;
    min-width: 210px;
    cursor: pointer;
}

.share_modal_wrapper .button_wrapper button:disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.share_modal_wrapper .error_msg {
    display: block;
    margin-top: 8px;
    font-family: 'Poppins-Regular';
    font-size: 13px;
    color: rgba(224, 7, 7, 1);
}

.social_media_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 22px;
}

.social_media_wrapper .facebook_btn,
.social_media_wrapper .twitter_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    color: #fff;
    padding: 6px 30px;
    min-width: 221px;
    cursor: pointer;
    text-decoration: none;
}

.social_media_wrapper .facebook_btn {
    background-color: rgb(24, 119, 242);
}

.social_media_wrapper .twitter_btn {
    background-color: #000;
}

.social_media_wrapper .facebook_btn .fb_svg {
    fill: #fff;
}

.notification__link {
    background: #18aee3;
    padding: 8px 10px;
    margin-top: 10px;
    display: inline-block;
    font-family: 'Poppins-Regular';
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
}

.share_modal_wrapper .error-message {
    font-family: 'Poppins-Regular';
    font-size: 13px;
    color: red;
    margin-bottom: 12px;
}

/* Share Modal */

.write_review_local_preview_alert.fixed-alert-right {
    left: 0;
    top: 0;
}

@media (max-width: 820px) {
    .company_all_photos .grid_images {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media(max-width: 768px) {
    .ireview-accredited-container {
        justify-content: flex-start;
        margin-top: 10px;
    }

    .about-page-header .right .buttons {
        margin-left: 0;
    }

    .about-page-header .right {
        flex-wrap: wrap;
        gap: 0;
    }

    .intro-section .slider-wrapper .alice-carousel__next-btn,
    .intro-section .slider-wrapper .alice-carousel__prev-btn {
        top: 40%;
    }

    .company_all_photos .top_head h3 {
        font-size: 22px;
    }

    .add_photos_modal .add_photos_content .uploaded-images {
        justify-content: center;
    }

    .add_photos_modal .add_photos_content {
        padding: 25px 28px;
    }

    .add_photos_modal .add_photos_content .wrapper {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .company_all_photos .grid_images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

/* @media (max-width: 1200px) {
    .intro-section .slider-wrapper .details-company h2 {
        font-size: 30px;
        line-height: 30px;
    }
} */

@media(max-width: 1024px) {
    .custom-rating-box {
        padding: 4px;
        width: 25px;
    }

    .holder-text h1 {
        font-size: 20px;
    }

    .ireview-accredited-container span.accredited-page,
    .ireview-accredited-container span.claimed-page {
        font-size: 14px;
    }
}

@media(max-width: 900px) {
    .change-order-mob>div:first-child {
        order: 1;
    }

    .share_modal_wrapper {
        margin: 45px auto;
    }
}

/* @media (max-width: 992px) {
    .intro-section .slider-wrapper .details-company {
        position: static;
        background-color: #00B2E3;
    }
} */

@media (max-width: 650px) {

    .intro-section .slider-wrapper .alice-carousel__next-btn,
    .intro-section .slider-wrapper .alice-carousel__prev-btn {
        top: 20%;
    }
}

@media (max-width: 600px) {
    .add_photos_modal .add_photos_content .uploaded-images .box img.uploaded_img {
        width: 120px;
    }

    .share_modal_wrapper {
        margin: 40px;
    }

    .social_media_wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }

    .social_media_wrapper .facebook_btn,
    .social_media_wrapper .twitter_btn {
        padding: 6px 10px;
        min-width: 208px;
        justify-content: center;
    }

    .intro-section .slider-wrapper .details-company .flex_wrapper {
        gap: 10px;
    }

    .intro-section .slider-wrapper .details-company .flex_wrapper.closed_det h4 {
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .left_top_header_section_review .vertical_line {
        display: none;
    }

    .left_top_header_section_review .rating-review-detail .wrapper h5 {
        font-size: 38px;
    }

    .left_top_header_section_review .rating-review-detail .wrapper .stars-images {
        gap: 3px;
    }

    .company_all_photos .grid_images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .add_photos_modal .add_photos_content .wrapper h4 {
        font-size: 20px;
        line-height: 35px;
    }

    .company_all_photos .top_head h3,
    .add_photos_modal .top_head h3 {
        font-size: 22px;
    }
}

@media(max-width: 428px) {
    .ireview-accredited-container span.accredited-page {
        margin-bottom: 5px;
        text-align: center;
    }

    .left_top_header_section_review .buttons {
        justify-content: center;
    }

    .left_top_header_section_review .buttons a {
        min-width: 177px;
        justify-content: center;
    }

    .intro-section .slider-wrapper .details-company .flex_wrapper.reviews .stars img {
        margin-right: 2px;
        width: 24px;
    }

    .intro-section .slider-wrapper .details-company .flex_wrapper.closed_det {
        flex-wrap: wrap;
        gap: 4px;
    }

    .company_all_photos .grid_images {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .share_modal_wrapper {
        padding: 28px;
    }

    .share_modal_wrapper .button_wrapper button {
        min-width: 100%;
    }

    .intro-section .slider-wrapper .details-company .flex_wrapper.closed_det .all_photos_btn button {
        padding: 6px;
    }

    .share_modal_wrapper {
        margin: 40px 20px;
    }

}

@media (max-width: 350px) {

    /* .intro-section .slider-wrapper .alice-carousel__next-btn,
    .intro-section .slider-wrapper .alice-carousel__prev-btn {
        top: 35%;
    } */

    .intro-section .slider-wrapper .details-company .flex_wrapper.claimed h5 span {
        display: none;
    }
}

@media (max-width: 345px) {
    .left_top_header_section_review .rating-review-detail .wrapper .emoji-icon img {
        margin-top: -40px;
    }
}

@media(min-width: 1700px) {
    .company-reviews-container>div:nth-child(2) {
        width: 50%;
    }
}