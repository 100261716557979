
.analysis_page .react-calendar {
    position: absolute;
    top: 100%;
    z-index: 9999;
}

.analysis_page .react-calendar.hide {
    display: none;
}

.analysis_page .analysis_page_header {
    margin-bottom: 50px;
}

/* General box */
.analysis_box {
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 0px 1px #0000000D;
    background-color: #fff;
    border-radius: 9px;
    padding: 10px;
    box-sizing: border-box;
}

.mt-40 {
    margin-top: 40px;
}

.mt-20 {
    margin-top: 20px;
}

.analysis_box h2 {
    margin: 0px;
    padding: 0 0 10px 0;
    font-family: "Poppins-Light";
    font-size: 1em;
    color: #0c2640;
}

/* General box */

.analysis_page .analysis_page_header .title {
    margin: 0px;
    font-family: "Poppins-Regular";
    font-size: 1.2em;
    color: #0c2640;
}

.keywords_stats_modal .left {
    position: sticky;
    top: 0;
}

.analysis_page .add_new_keyword_btn {
    height: 41px;
    border: 0px;
    background: #00B2E3;
    font-family: "Poppins-Regular";
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    min-width: 179px;
}

.analysis_page .add_new_keyword_btn img {
    margin-right: 11px;
}

.analysis_box tbody tr {
    cursor: pointer;
}

.analysis_page .analysis_page_header label {
    position: relative;
    margin-bottom: 0;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
}

.analysis_page .analysis_page_header label .keyword_img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.analysis_page .analysis_page_header label input {
    border-radius: 6px;
    background-color: #fff;
    border: 0;
    padding: 14px;
    padding-left: 50px;
    width: 100%;
}

.analysis_page .red_dot,
.analysis_page .success_dot {
    display: flex;
    width: 12px;
    height: 12px;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 50%;
}

.analysis_page .success_dot {
    background-color: #06D49D;
}

.analysis_page .red_dot {
    background-color: #ED476E;
}

.analysis_page table {
    margin-top: 0;
}

.analysis_page .top_stats_box,
.analysis_page .keywords_box {
    position: relative;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 5px;
    margin-top: 40px;
}

.analysis_page .top_stats_box h2,
.analysis_page .keywords_box h2 {
    margin: 0px;
    padding: 10px 15px;
    font-family: "Poppins-Light";
    font-size: 1em;
    color: #0c2640;
}

.star_title_wrap {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.star_title_wrap>svg {
    color: #F7C409;
    margin-left: 5px;
    font-size: 17px;
}

/* Add keyword modal */
.search_keyword {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(37 49 60 / 8%);
    border-radius: 3px;
    margin: 11px 8px 10px 13px;
    border: 1px solid #EDEDED;
}

.search_keyword .search_keyword_input {
    border: none;
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #0c2640;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.search_keyword .search_keyword_input:focus,
.add_keyword_modal .right .input-wrap .add_title-inp:focus {
    outline: none;
    box-shadow: unset;
}

.search_keyword .search_icon_wrap {
    background: rgba(37, 49, 60, 0.05);
    border-radius: 3px;
    padding: 8px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_keyword .search_icon_wrap>svg {
    color: #80808082
}

.add_keyword_modal .boxes {
    padding-left: 13px;
    padding-right: 13px;
    max-height: 68vh;
    overflow: auto;
}

/*  */
.add_keyword_modal .boxes,
.add_keyword_modal .right .input-wrap {
    scrollbar-width: 5px;
    scrollbar-color: #00B2E3;
}

.add_keyword_modal .boxes::-webkit-scrollbar,
.add_keyword_modal .right .input-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    scrollbar-width: 5px;
}

.add_keyword_modal .boxes::-webkit-scrollbar-thumb,
.add_keyword_modal .right .input-wrap::-webkit-scrollbar-thumb {
    background: #00B2E3;
    border-radius: 29.6388px;
    scrollbar-color: #00B2E3;
    scrollbar-width: 5px;
}

.add_keyword_modal .boxes::-webkit-scrollbar-track,
.add_keyword_modal .right .input-wrap::-webkit-scrollbar-track {
    background: #F6F8FA;
}

/*  */

.add_keyword_modal .left .sort_select {
    margin: 8px;
}

.add_keyword_modal .left .sort_select .css-q8hpuo-MuiFormControl-root {
    max-width: 45% !important;
}

.add_keyword_modal .left .sort_select .css-1uwzc1h-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
    display: flex;
    align-items: center;
}

.add_keyword_modal .left .sort_select .css-s4djnz-MuiInputBase-root .MuiInputBase-input {
    padding-left: 15px !important;
}

.add_keyword_modal .left .sort_select .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background: #00B2E3;
    color: #fff;
}

.add_keyword_modal .box_item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(37 49 60 / 8%);
    border-radius: 5px;
    padding: 6px 9px 6px 14px;
    display: flex;
    align-items: center;
    border: 1px solid #EDEDED;
    cursor: pointer;
}

.add_keyword_modal .box_item h5 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0c2640;
    margin: 0;
}

.boxes>div {
    margin-bottom: 14px;
}

.add_keyword_modal .delete_item {
    cursor: pointer;
    background-color: transparent;
    border: 0px;
    min-width: 41px;
    justify-content: flex-end;
}

.add_keyword_modal .delete_item .MuiLoadingButton-loadingIndicator {
    padding-left: 12px;
}

.add_keyword_modal .delete_item:hover>svg {
    color: red;
}

.add_keyword_modal .delete_item:hover {
    background-color: transparent;
}

.add_keyword_modal .delete_item>svg {
    color: #ced4da;
    font-size: 16px;
}

.add_keyword_modal .left_foot {
    border-top: 1px solid #F3F3F3;
    margin-top: 46px;
    padding: 0 25px;
}

.track_keywords_btn {
    background: #18AEE3;
    float: right;
    border-radius: 5px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.add_keyword_modal .right {
    padding: 11px 18px 15px 9px;
    position: relative;
}

.grid_wrap {
    height: 100%;
}

.submit-loading-btn>button>.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}

.blue-loading-btn>.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
    left: 1.2em;
    color: #fff;
}

.add_keyword_modal .right .add_btn {
    background: #00B2E3;
    border-radius: 3px;
    padding: 8px 15px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.add_keyword_modal .right .add_btn img {
    margin-right: 11px;
}

.add_keyword_modal .right .input-wrap {
    max-height: 170px;
}

.add_keyword_modal .right .input-item {
    background: #F6F8FA;
    border-radius: 8px;
    padding: 0.375rem 0.75rem;
}

.add_keyword_modal .right .input-wrap .add_title-inp {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    background: #F6F8FA;
    color: #0c2640;
    border: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
}

/* Add keyword modal */

/* keywords_stats_modal */

.keyword_stats_dialog.custom-modal-dialog {
    background: #f3f3f3 !important;
    width: 1300px;
}

.keywords_stats_modal .scrollable-loc-container {
    height: calc(100vh - 153px);
    padding: 0 16px;
}

.keywords_stats_modal .left .box {
    margin-top: 10px;
}

.box_general__table {
    max-height: 35vh;
    overflow-y: auto;
}

.analysis_page .table_wrapper {
    max-height: 240px;
    overflow-y: auto;
}

.keywords_stats_modal .left .box_filter,
.keywords_stats_modal .left .box,
.keywords_stats_modal .right .box {
    width: 100%;
    position: relative;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 5px;
    padding: 0.8rem;
    box-sizing: border-box;
}

.keywords_stats_modal .right .box {
    margin-top: 58px;
}

.keywords_stats_modal .left .box_filter {
    margin: 10px 0 35px 0;
}

.keywords_stats_modal .left .box_filter.show {
    display: block;
}

.keywords_stats_modal .general__table {
    margin-top: 10px;
}

.keywords_stats_modal .general__table th {
    font-weight: 600;
}

.keywords_stats_modal .filter_btn {
    font-family: 'Poppins-Regular';
    color: #00b4e5;
    cursor: pointer;
    padding: 0.7rem 0 0 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.keywords_stats_modal .box_filter h5 {
    font-family: 'Poppins-Light';
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #0c2640;
}

.keywords_stats_modal .left .keyword_search {
    border-radius: 6px;
    padding: 8px;
    border: 1px solid #dedede;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-size: 14px;
}

.keywords_stats_modal .left .keyword_search:focus {
    outline: none;
}

.keywords_stats_modal .apply_btn {
    height: 41px;
    border: 0px;
    background-color: #00B4E5;
    font-family: "Poppins-Regular";
    font-size: 14px;
    min-width: 100px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.keywords_stats_modal .left .box .title {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 14px;
    color: #0c2640;
}

.keywords_stats_modal .left .box .title>span {
    font-family: "Poppins-Bold";
}

.keywords_stats_modal .right .box .left_wrapper .subtitle {
    font-size: 18px;
    font-family: 'Poppins-Light';
    font-style: normal;
    color: #0c2640;
    margin-bottom: 0;
}

.keywords_stats_modal .right .box .left_wrapper .title {
    font-size: 22px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    color: #0c2640;
}

.keywords_stats_modal .right .left_wrapper .percentage_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.keywords_stats_modal .right .left_wrapper .percentage_wrapper span {
    font-family: 'Poppins-Light';
    font-size: 12px;
    font-style: normal;
}

.keywords_stats_modal .text_danger {
    color: #ed476e;
}

.keywords_stats_modal .text_success {
    color: #06d49d;
}

.keywords_stats_modal .text_warning {
    color: #ffc107;
}

.keywords_stats_modal .mr-2 {
    margin-right: 0.2rem;
}

.keywords_stats_modal .mr-5 {
    margin-right: 5px;
}

.keywords_stats_modal .right .vl:not(:first-child) {
    margin: 0 15px;
}

.keywords_stats_modal .right .vl.border_right {
    border-right: 1px solid #dee2e6;
    padding-right: 15px;
}

.keywords_stats_modal .right .vl .title {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 18px;
    color: #212529;
}

.keywords_stats_modal .right .vl h3 {
    font-family: 'Poppins-Light';
    font-style: normal;
}

/* keywords_stats_modal */

/* reviews box */
.keywords_stats_modal .reviews_box {
    box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 5px;
    padding: 18px;
    margin-top: 25px;
}

.keywords_stats_modal .manage_reviews_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.keywords_stats_modal .manage_reviews_source {
    display: flex;
    align-items: flex-start;
    --webkit-align-items: "flex-start";
    flex-wrap: wrap;
    gap: 8px;
}

.keywords_stats_modal .manage_reviews_source .logo {
    border-radius: 50%;
    width: 35px;
}

.keywords_stats_modal .manage_reviews_source .manage_reviews_name h5 {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1;
    color: #0c2640;
    font-family: 'Poppins-Light';
    font-style: normal;
    margin-top: 0px;
}

.keywords_stats_modal .manage_reviews_source .manage_reviews_name h5 span {
    color: #0087e2;
    margin-left: 5px;
}

.keywords_stats_modal .manage_reviews_source .manage_reviews_name img {
    width: 16px;
    margin-top: 7px;
}

.keywords_stats_modal .manage_reviews_date {
    margin-bottom: 0;
    font-size: 14px;
    color: #0c2640;
    font-family: 'Poppins-Light';
    font-style: normal;
}

.keywords_stats_modal .manage_reviews_content {
    margin-top: 30px;
}

.custom-modal-keyword {
    width: 850px;
    max-width: 90%;
}

.keywords_stats_modal .manage_reviews_content p {
    font-family: 'Poppins-Light';
    font-style: normal;
    color: #0c2640;
    font-size: 16px;
}

/* reviews box */
.analysis-suggestion-report{
    margin-top: 126px;
    background-color: #196f9a;
    border-radius: 20px;
    position: relative;
}
.analysis-suggestion-report > img{
    position: absolute;
    right: 31px;
    top: -113px;
    object-fit: cover;
    height: 199px;
}

.analysis-suggestion-report  h2{
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 19px;
    margin: 0;
    color: #fff;
}
.analysis-charts-actions{
    border-bottom: 0.2px solid #0000001c;
}
.analysis-charts-actions p{
    font-family: "Poppins-Regular";
    margin: 3px 14px 10px 14px;
    font-size: 12px;
    color: #464E5F;
}
.analysis-charts-actions > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.analysis-charts-actions h2{
    margin: 0px;
    padding: 7px 14px;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #464E5F;
}
.analysis-filter-wrapper h5{
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #000000;
}
.analysis-suggestion-report  p{
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    color: #fff;
}
.analysis-suggestion-report > div > p{
    max-width: calc(100% - 150px);
}
.trending_charts_wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.analysis-filter-wrapper > div:last-child{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.analysis-filter-wrapper > div:last-child{
    margin-left: 13px;
    /* background: #f3f3f3; */
    padding: 2px;
    border-radius: 5px;
}
.analysis-filter-wrapper{
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
}
.analysis-filter-wrapper button {
    background-color: transparent;
    border: 0px;
    padding: 5px 15px;
    font-family: "Poppins-Medium";
    font-size: 12px;
    color: #0c2640;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out, background-position 0.1s ease-in-out;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #fff 50%);
    background-position: 0 0;
    border-radius: 5px;
}

.analysis-filter-wrapper button.active {
    background-position: -100% 0; 
    color: #0c2640;
}
.analysis-filter-wrapper button:last-child{
    margin-right: 0;
}
.loader-of-charts{
    padding: 14px 50px 14px 50px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-image: url("../../public/admin-images/lines.svg");
}
.not-enough-reviews{
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    background-image: url("../../public/admin-images/lines.svg");
}
.not-enough-reviews p{
    font-family: "Poppins-Medium";
    font-size: 16px;
    color: #0c2640;
}
.analysis-tabs-buttons{
    width: 40%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    height: fit-content;
}
.analysis-tabs-buttons > div > h2{
    color: #333333;
    font-size: 20px;
    font-family: "Poppins-Medium";
    margin: 0px;
    margin-bottom: 5px;
}
.analysis-tabs-buttons > div > p {
    color: #707070;
    font-size: 14px;
    font-family: "Poppins-Light";
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: white-space 0.3s ease, overflow 0.3s ease;
}

.analysis-tabs-buttons > div.active > p {
    white-space: pre-wrap;      
    overflow: visible;      
    text-overflow: ellipsis;
    transition:  white-space 0.3s ease, overflow 0.3s ease;           
    margin-bottom: 15px;    
}
.analysis-tabs-buttons > div.active{
    transition: opacity 0.3s 
}
.analysis-tabs-buttons > div:not(.active){
    opacity: 0.5;
    transition: opacity 0.3s ease; 
}
.analysis-tabs-buttons > div:not(.active) svg{
    color: #707070!important;
}
.analysis-tabs-buttons > div {
    margin-bottom: 33px;
    position: relative;
    cursor: pointer;
    padding-bottom: 10px;
}
.analysis-tabs-buttons > div.active::before{
    content: "";
    width: 4px;
    height: 100%;
    background-color: #00B4E5;
    border-radius: 20px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    opacity: 1;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.analysis-tabs-buttons > div:not(.active)::before {
    opacity: 0; 
    transform: translateX(0); 
}
.analysis-tabs-buttons > div:last-child{
    margin-bottom: 0px;
}
.analysis-tabs-buttons::before{
    content: "";
    width: 4px;
    height: 100%;
    background-color: #E5E5E5;
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}
.analysis-tabs-buttons > div > button{
    background-color: #E5F6F9;
    color: #00B4E5;
    border: 0;
    font-size: 14px;
    display: block;
    width: 100%;
    height: 38px;
    border-radius: 3px;
    cursor: pointer;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.analysis-tabs-buttons > div.active > button{
    display: flex;
}
.analysis-tabs-buttons > div > button > svg{
    margin-right: 10px;
    font-size: 18px;
}
.top-5-items{
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 13px;
    margin: 0;
}
.top-5-items > li{
    white-space: nowrap;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-weight: 400;
    padding: 5px 20px;
    border-radius: 3px;
    margin: 0 8px 8px 0px;
}
.suggestion-list-wrapper{
    margin: 0;
    padding-left: 15px;
    list-style: none;
}
.suggestion-list-wrapper li {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 14px;
    color: #707070;
    padding: 12px 20px;
    /* max-height: 2em; */
    text-transform: none;
    /* transition: max-height 0.5s ease; */
    position: relative;
}
.suggestion-list-wrapper li span{
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 4px;
    top: 16px;
}
.suggestion-legend{
    display: flex;
    margin-left: auto;
}
.not-enough-data{
    margin: 0;
    font-family: 'Poppins-Regular';
    color: #464E5F;
    padding: 20px;
    text-align: center; 
}
.suggestion-legend > p{
    color: #333333;
    font-size: 12px;
    font-family: "Poppins-Light";
    font-weight: 300;
    margin-right: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
}
.suggestion-legend > p > span{
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 6px;
    display: inline-block;
    color: #333333;
}
/* .suggestion-list-wrapper li.collapsed {
    white-space: pre-wrap;
    overflow: visible;
    max-height: 500px; 
} */
.suggestion-list-wrapper li:last-child{
    border-bottom: 0;
}
.top-5-best > li{
    background-color: #00B4E5;
}
.top-5-worst > li{
    background-color: #F64E60;
}
@media only screen and (max-width: 992px) {
    .general__table .hide_mobile {
        display: none;
    }
}

@media only screen and (max-width: 999px) {
    .analysis_page .analysis_page_header .right_side {
        flex-wrap: wrap;
        margin-top: 10px;
    }
    .trending_charts_wrapper{
        display: block;
    }
    .trending_charts_wrapper > div:first-child, .analysis-tabs-buttons{
        width: 100%!important;
    }
    .analysis_page .export_excel_btn,
    .analysis_page .add_new_keyword_btn {
        margin-left: 0;
    }
}

@media only screen and (max-width: 800px) {
    .general__table th {
        font-size: 12px;
        line-height: 16px;
    }
}

@media only screen and (max-width: 480px) {

    .analysis_page .analysis_page_header label,
    .analysis_page .export_excel_btn,
    .analysis_page .add_new_keyword_btn {
        width: 100%;
        justify-content: center;
    }
    .analysis-suggestion-report > img {
        right: 11px;
        top: -108px;
        height: 152px;
    }
}