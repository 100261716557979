.location_settings_page .flex-header-container {
    margin-bottom: 20px;
}

.location_settings_page .edit-btn {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #18527D;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0px;
    text-decoration: none;
}

.location_settings_card input::placeholder {
    opacity: 0.7;
}

.location_settings_page .edit-btn span {
    padding-right: 4px;
    color: #22272F;
}

.custom-select-large-data {
    position: relative;
}

.custom-select-large-data>div:first-child {
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(166, 178, 205, .25);
    font-size: 14px;
    padding: 6px 26px 6px 12px;
    color: #0c2640;
    box-sizing: border-box;
    cursor: pointer;
    font-family: "Poppins-Regular";
    display: flex;
    flex-wrap: wrap;
    max-height: 87px;
    overflow-y: auto;
    min-height: 44px;
    line-height: 30px;
}

.custom-select-large-data>div.active {
    border: 1px solid #00b2e3;
}

.image-uploaded-multiple.item {
    display: flex;
    flex-wrap: wrap;
    border: 1px dashed #18AEE3;
    width: fit-content;
    margin-right: 10px;
    border-radius: 5px;
}

.image-uploaded-multiple>div {
    background-color: rgba(166, 178, 205, .25);
    border-radius: 3px;
    margin: 10px;
    padding: 10px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.show-uploaded-text>span {
    margin-top: 3px;
    font-family: "Poppins-Regular";
    color: #0c2640;
}

.image-uploaded-multiple>div>svg {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #e30015;
    cursor: pointer;
}

.image-uploaded-multiple>div>img {
    max-width: 90px;
    max-height: 90px;
    object-fit: contain;
}

.business-photos-label {
    height: 90px;
    display: flex !important;
    align-items: center;
    color: #00b4e5 !important;
    background: #E7F8FE;
    border: 1px dashed #18AEE3;
    padding: 13px;
    box-sizing: border-box;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
}

.business_photos svg {
    font-size: 20px;
}

.custom-select-large-data>div:first-child>span {
    padding: 0 4px;
    background-color: rgba(166, 178, 205, .25);
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.info-page-foot {
    margin-top: 20px;
}

.location_settings_edit .info-page-foot {
    text-align: end;
}

.info-page-foot button:hover {
    background: #18AEE3;
}

.info-page-foot button {
    background: #18AEE3;
    border-radius: 3px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF !important;
    cursor: pointer;
    padding: 8px 15px;
}

.info-page-foot button .MuiLoadingButton-loadingIndicator {
    left: 1em;
    color: #fff;
}

.info-page-foot button .MuiLoadingButton-loadingIndicator {
    color: #fff !important;
}

.custom-menu-large-data {
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 0px 29.9688px 79.9167px 20px rgb(0 0 0 / 8%);
    background: #ffff;
    border-radius: 5px;
    padding: 10px 0px;
    z-index: 2;
}

.custom-menu-large-data>input {
    margin: 0 10px;
    width: calc(100% - 20px) !important;
    box-sizing: border-box;
}

.custom-menu-large-data>div ul {
    padding: 15px;
    margin-top: 0px;
}

.custom-menu-large-data>div ul li {
    list-style: none;
    font-family: "Poppins-Regular";
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.custom-select-large-data>div:first-child>span svg {
    color: #00b2e3;
    padding: 4px;
}

.custom-menu-large-data>div>ul li.selected {
    background: #00b4e51f;
    border-radius: 4px;
}

.custom-menu-large-data>div ul li:hover {
    background-color: rgba(166, 178, 205, .25);
    border-radius: 4px;
}

.custom-menu-large-data>div:last-child {
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 99999;
}

.location_settings_page .desc {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #18527D;
    text-align: center;
    margin-top: 30px;
    display: block;
}

.add-new-item>svg {
    color: #18AEE3;
    font-size: 18px;
}

.add-new-item {
    float: right;
    padding: 0px !important;
}

.remove-new-item {
    color: rgba(255, 0, 0, 0.767);
    margin-right: 10px;
    display: block;
    cursor: pointer;
}

.location_settings_card .remove-new-item {
    color: #999999;
}

.misc .add_new_span {
    color: #00B2E3;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    margin-right: 5px;
}

.misc .add-new-item:hover {
    background: transparent !important;
}

.location_settings_card,
.location_social_card,
.keywords-card {
    background-color: #fff;
    /* padding: 10px 15px; */
    border-radius: 9px;
    /* box-shadow: 0 0 7px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 15%); */
    margin-top: 10px;
}

.location_settings_card .form-group {
    margin-bottom: 0;
}

.location_settings_card .top_head {
    border-bottom: 1px solid #f4f4f4;
}

.business_location_wrapper,
.location_settings_card .top_head h5,
.location_grid_wrapper {
    padding: 4px 14px;
}

.hours_of_operation_card .location_grid_wrapper {
    overflow: auto;
}

/* .business_location_wrapper {
    padding-bottom: 11px;
} */

.location_settings_card .title,
.location_social_card .title {
    color: #464E5F;
    font-family: 'Poppins-Semibold';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.location_settings_card .title span {
    font-family: 'Poppins-Regular';
    font-weight: 400 !important;
}

.keywords-card input {
    border: none !important;
    height: 4px !important;
}

.location_settings_card label:not(.MuiFormLabel-root, .MuiFormControlLabel-root),
.location_social_card label {
    display: block;
    width: 100%;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-style: normal;
    font-size: 0.845rem;
    line-height: 1.5rem;
    color: #B0B7C3;
    margin-bottom: 5px;
}

.hours-small-label .MuiTypography-root {
    font-size: 14px;
    font-family: "Poppins-Regular";
    margin-left: 10px;
    width: 55px;
    color: #4C4646;
}

.location_settings_card .css-1uwzc1h-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
    border: 1px solid #e5e5e5;
    /* height: 40px;    */
}

.location_settings_card input:not(.MuiSelect-nativeInput) {
    width: 100%;
    height: 41px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    font-size: 16px;
    padding: 6px 26px 6px 10px;
    color: #979797;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
}

.business-information input:not(.MuiSelect-nativeInput) {
    height: 48px;
}

.location_social_card .social__input {
    border: none !important;
    padding-left: 0 !important;
}

.keywords-card .location_settings_card input:not(.MuiSelect-nativeInput) {
    height: auto;
}

.business-location .react-tel-input>.form-control:focus,
.business-location .react-tel-input>.form-control:hover {
    border-color: rgba(166, 178, 205, .25);
    box-shadow: unset;
}

/* .location_settings_card .react-tel-input>.form-control {
    padding: 18.5px 14px 18.5px 58px !important;
} */

.location_settings_card .react-tel-input .flag-dropdown {
    border: 1px solid #e5e5e5;
    border-right: none;
    background: none;
}

.location_settings_card input[type="number"]::-webkit-outer-spin-button,
.location_settings_card input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.location_settings_card .image-uploaded {
    border: 1px dashed #18AEE3;
    /* background: #E7F8FE; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /* width: 413px; */
    width: 60%;
    /* height: 95px; */
    height: 131px;
    margin-right: 25px;
}

.location_settings_card .business_photos_image {
    background: #E7F8FE;
}

.location_settings_card .image-uploaded img {
    width: 180px;
    height: 60px;
    object-fit: contain;
}

.location_settings_card input[type='file'] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.location-social-holder {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(166, 178, 205, .25);
    font-size: 16px;
    padding: 0px 0px 0px 12px;
    color: #0c2640;
    display: flex;
    align-items: center;
    --webkit-align-items: center;
    box-sizing: border-box;
}

.location-social-holder input {
    height: 100%;
    margin-left: 3px;
    border: 0px;
    font-family: "Poppins-Regular";
    width: 100%;
}

.location-social-holder>div>span {
    font-family: "Poppins-Regular";
    font-size: 0.7em;
    color: #979797;
}

.location-social-holder>div {
    display: flex;
    align-items: center;
    --webkit-align-items: center;
}

.location-social-holder>div img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.location-social-holder>div input {
    width: 100%;
}

.location_settings_card .upload-btn {
    background: #FFFFFF;
    border: 1px solid #CCF2FF;
    border-radius: 5px;
    font-family: 'Poppins-Light';
    font-style: normal;
    font-size: 12px;
    line-height: 1.125rem;
    color: #18AEE3;
    margin-right: 12px;
}

.location_settings_edit .save_btn {
    background: #18AEE3;
    border-radius: 4.27374px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    padding: 8px 16px;
    margin-top: 22px;
}

.hours-of-operation-wrapper {
    /* background-color: #cecece2b; */
    padding: 10px 0 0 0;
    border-radius: 4px;
}

.hours-of-operation-wrapper>div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    min-height: 45px;
}

.working-hours-table {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
}

.working-hours-table tbody tr:not(:last-child) {
    border-bottom: 1px solid #EEE;
}

.working-hours-table tbody td {
    text-wrap: nowrap;
    padding: 15px 10px;
}

.hours-of-operation-wrapper>div h6,
.working-hours-table tbody td h6 {
    margin: 0;
    color: #4C4646;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    width: 85px;
    white-space: nowrap;
}

.hours-of-operation-wrapper>div .MuiFormControl-root {
    margin-left: 20px;
}

@media (min-width: 1550px) {
    .image-uploaded-multiple.item {
        margin-right: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .image-uploaded-multiple.item {
        margin-top: 14px;
    }

    .location_settings_card .image-uploaded {
        width: 100%;
    }
}